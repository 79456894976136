import { useLocalStorage } from "hooks/useLocalStorage";
import { createContext, useContext, useState } from "react";

export type UserSearch = {
  name: string;
  parameters: any;
  date: string;
};

interface IUserPreferencesContext {
  colorMode: {
    mode: string;
    toggle?: () => void;
    setMode?: (mode: string) => void;
  };
  notifications: {
    limit: number;
    setLimit?: (limit: number) => void;
  };
  timezone: {
    format: string;
    setFormat?: (format: string) => void;
  };
  search: {
    searches: UserSearch[];
    recentSearches: UserSearch[];
    recentSearchesLimit: number;
    addSearch?: (search: UserSearch, target: string) => void;
    deleteSavedSearch?: (name: string, date: string) => void;
    deleteRecentSearch?: (numberToDelete: number) => void;
    setRecentSearchesLimit?: (limit: number) => void;
  };
  currentSearch: {
    search: UserSearch;
    setSearch?: (search: UserSearch) => void;
  };
  language: {
    language: string;
    toggle?: () => void;
    setLanguage?: (language: string) => void;
  };
}

export const defaultContext = {
  colorMode: {
    mode: "light",
  },
  notifications: {
    limit: 5,
  },
  timezone: {
    format: "edt",
  },
  search: {
    searches: [] as UserSearch[],
    recentSearches: [] as UserSearch[],
    recentSearchesLimit: 5,
  },
  currentSearch: {
    search: {} as UserSearch,
  },
  language: {
    language: navigator.language.split(/[-_]/)[0], // Getting browser language without the region code
  },
};

export const UserPreferencesContext =
  createContext<IUserPreferencesContext>(defaultContext);

export const useUserPreferences = () => useContext(UserPreferencesContext);

type UserPreferencesProps = {
  children: React.ReactNode;
};

function UserPreferences(props: UserPreferencesProps) {
  const { children } = props;

  // Context LocalStorage
  const [colorMode, setColorMode] = useLocalStorage(
    "colorMode",
    defaultContext.colorMode.mode
  );

  const [notificationsLimit, setNotificationsLimit] = useLocalStorage(
    "notificationsLimit",
    defaultContext.notifications.limit
  );

  const [timezoneFormat, setTimezoneFormat] = useLocalStorage(
    "timezoneFormat",
    defaultContext.timezone.format
  );

  const [searchSearches, setSearchSearches] = useLocalStorage(
    "searches",
    defaultContext.search.searches
  );

  const [searchRecentSearches, setSearchRecentSearches] = useLocalStorage(
    "recentSearches",
    defaultContext.search.recentSearches
  );

  const [searchRecentSearchesLimit, setSearchRecentSearchesLimit] =
    useLocalStorage(
      "recentSearchesLimit",
      defaultContext.search.recentSearchesLimit
    );

  const [language, setLanguage] = useLocalStorage(
    "language",
    defaultContext.language.language
  );

  // Context state
  const [currentSearch, setCurrentSearch] = useState(
    defaultContext.currentSearch.search
  );

  // Modules specific logic
  const setSearchRecentSearchesRotation = (search: UserSearch) => {
    let existingSearchRecentSearches = searchRecentSearches;
    if (searchRecentSearches.length >= searchRecentSearchesLimit) {
      existingSearchRecentSearches = searchRecentSearches.slice(1);
    }
    setSearchRecentSearches([...existingSearchRecentSearches, search]);
  };

  const userPreferences = {
    colorMode: {
      mode: colorMode,
      toggle: () => {
        setColorMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      setMode: (mode: string) => setColorMode(mode),
    },
    notifications: {
      limit: notificationsLimit,
      setLimit: (limit: number) => setNotificationsLimit(limit),
    },
    timezone: {
      format: timezoneFormat,
      setFormat: (format: string) => setTimezoneFormat(format),
    },
    search: {
      searches: searchSearches,
      recentSearches: searchRecentSearches,
      recentSearchesLimit: searchRecentSearchesLimit,
      addSearch: (search: UserSearch, target: string) =>
        target === "savedSearches"
          ? setSearchSearches((prevState) => [...prevState, search])
          : setSearchRecentSearchesRotation(search),
      deleteSavedSearch: (name: string, date: string) =>
        setSearchSearches((current) =>
          current.filter(
            (search) => search.name !== name || search.date !== date
          )
        ),
      deleteRecentSearch: (numberToDelete: number) =>
        setSearchRecentSearches(() =>
          searchRecentSearches.slice(numberToDelete)
        ),
      setRecentSearchesLimit: (limit: number) =>
        setSearchRecentSearchesLimit(limit),
    },
    currentSearch: {
      search: currentSearch,
      setSearch: (search: UserSearch) => setCurrentSearch(search),
    },
    language: {
      language: language,
      toggle: () => {
        setLanguage((prevMode) => (prevMode === "en" ? "fr" : "en"));
      },
      setLanguage: (language: string) => setLanguage(language),
    },
  };

  return (
    <UserPreferencesContext.Provider value={userPreferences}>
      {children}
    </UserPreferencesContext.Provider>
  );
}

export default UserPreferences;

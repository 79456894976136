import { FormattedMessage } from "react-intl";

import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} color="inherit" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="inherit"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

interface ProgressToastProps {
  notification: any;
}

function ProgressToast(props: ProgressToastProps) {
  const { notification } = props;

  const progress =
    notification.details?.current_line / notification.details?.total_lines;

  const progressPercent = (progress || 0) * 100;

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item sx={{ width: "280px", overflow: "auto" }}>
        <FormattedMessage
          id={notification.message}
          defaultMessage="Progressing"
          description="Message for progressing status received from back-end"
        />
      </Grid>
      {progressPercent && (
        <Grid textAlign="center" item sx={{ width: "50px" }}>
          <CircularProgressWithLabel value={progressPercent} size={30} />
        </Grid>
      )}
    </Grid>
  );
}
export default ProgressToast;

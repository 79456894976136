import BLAKE2s from "blake2s-js";

function decodeUTF8(s: string) {
  const d = decodeURIComponent(s);
  const b = new Uint8Array(d.length);
  for (let i = 0; i < d.length; i++) {
    b[i] = d.charCodeAt(i);
  }
  return b;
}

function getHash(key: string, strToHash: string) {
  const keyDecoded = decodeUTF8(key);
  const strToHashDecoded = decodeUTF8(strToHash);

  const h = new BLAKE2s(32, keyDecoded);
  h.update(strToHashDecoded);

  return h.hexDigest();
}

export default getHash;

import { FormattedMessage } from "react-intl";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@mui/material";

interface RestrictionsModalConfirmationProps {
  open: boolean;
  onClose: Function;
  onValidateSelection: Function;
  onValidatePageSelection: Function;
  checked: boolean;
  promotionCode: string;
  exclusionCode: string;
  selectionNumber: number;
  totalNumber: number;
}

function RestrictionsModalConfirmation(
  props: RestrictionsModalConfirmationProps
) {
  const {
    open,
    onClose,
    onValidateSelection,
    onValidatePageSelection,
    checked,
    promotionCode,
    exclusionCode,
    selectionNumber,
    totalNumber,
  } = props;

  const handleYesClick = () => {
    if (exclusionCode === "all_exclusions") {
      onValidatePageSelection(checked);
    } else {
      onValidateSelection(checked, promotionCode);
    }
    onClose();
  };

  const handleNoClick = () => {
    onClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        {checked ? (
          <FormattedMessage
            id="restrictions.listing.modal_apply_title"
            defaultMessage="{exclusion_code} applied to all products?"
            description="Modal title when applying all restrictions"
            values={{
              exclusion_code: exclusionCode,
            }}
          />
        ) : (
          <FormattedMessage
            id="restrictions.listing.modal_remove_title"
            defaultMessage="{exclusion_code} removed from all products?"
            description="Modal title when removing all restrictions"
            values={{
              exclusion_code: exclusionCode,
            }}
          />
        )}
        <IconButton
          aria-label="close"
          onClick={handleNoClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {checked ? (
            <FormattedMessage
              id="restrictions.listing.modal_apply_content"
              defaultMessage="This will apply {exclusion_code} for {selection_number} our of {total_number} products.{br}Alternatively you can download CSV and apply {exclusion_code} for all {total_number} products."
              description="Modal content when applying all restrictions"
              values={{
                exclusion_code: exclusionCode,
                selection_number: selectionNumber,
                total_number: totalNumber,
                br: <br />,
              }}
            />
          ) : (
            <FormattedMessage
              id="restrictions.listing.modal_remove_content"
              defaultMessage="This will remove {exclusionCode} for {selection_number} our of {total_number} products.{br}Alternatively you can download CSV and remove {exclusion_code} for all {total_number} products."
              description="Modal content when removing all restrictions"
              values={{
                exclusion_code: exclusionCode,
                selection_number: selectionNumber,
                total_number: totalNumber,
                br: <br />,
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNoClick}>
          <FormattedMessage
            id="restrictions.listing.modal_no"
            defaultMessage="No"
            description="Modal confirmation no"
          />
        </Button>
        <Button onClick={handleYesClick}>
          <FormattedMessage
            id="restrictions.listing.modal_yes"
            defaultMessage="Yes"
            description="Modal confirmation yes"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RestrictionsModalConfirmation;

import { useUserPreferences } from "context/preferences/UserPreferences";
import { FormattedMessage } from "react-intl";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";

function Notifications() {
  const userPreferences = useUserPreferences();

  const handleMaxNotificationsChange = (event: SelectChangeEvent) => {
    userPreferences.notifications.setLimit?.(parseInt(event.target.value));
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="notifications-limit-label">
        <FormattedMessage
          id="restrictions.preferences.notification_max_number_input"
          defaultMessage="Number of notifications"
          description="Notifications number input"
        />
      </InputLabel>
      <Select
        labelId="notifications-limit-label"
        id="notifications-max-number"
        value={String(userPreferences.notifications.limit)}
        label="Number of Notifications"
        onChange={handleMaxNotificationsChange}
      >
        {[1, 2, 3, 4, 5].map((number) => (
          <MenuItem key={number} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        <FormattedMessage
          id="restrictions.preferences.notification_max_number_input_legend"
          defaultMessage="Limiting number of notifications displaced on the screen"
          description="Notifications number input legend"
        />
      </FormHelperText>
    </FormControl>
  );
}

export default Notifications;

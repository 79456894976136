import { drawerWidth } from "components/layout/AppLayout";
import * as React from "react";
import { useIntl } from "react-intl";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";

import {
  ChevronLeft,
  Dashboard,
  Flag,
  Event,
  LocalOfferOutlined
} from "@mui/icons-material";
import {
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(
        function Link(itemProps, ref) {
          return (
            <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
          );
        }
      ),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

type AppNavigationProps = {
  open: boolean;
  setOpen: Function;
};

function AppNavigation(props: AppNavigationProps) {
  const isMarkdownUIEnabled = process.env.REACT_APP_IS_MARKDOWN_UI_ENABLED === 'true';

  const { open, setOpen } = props;

  const intl = useIntl();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItemLink to="/" primary="SSENSE Promotion" icon={<Dashboard />} />
        <ListItemLink
          to="/promotion-restrictions"
          primary={intl.formatMessage({
            id: "restrictions.navigation.menu",
            defaultMessage: "Product Restrictions",
            description: "Navigation entry of Restrictions Page",
          })}
          icon={<Flag />}
        />
        {isMarkdownUIEnabled && (
          <ListItemLink
            to="/sale-events"
            primary={intl.formatMessage({
              id: "sale_events.button.text",
              defaultMessage: "Sale Events",
              description: "Navigation entry of Sale Events Page",
            })}
            icon={<Event />}
          />
        )}
        {isMarkdownUIEnabled && (
          <ListItemLink
            to="/simulations"
            primary={intl.formatMessage({
              id: "simulation.button.text",
              defaultMessage: "Simulation",
              description: "Navigation entry of Simulations Page",
            })}
            icon={<LocalOfferOutlined />}
          />
        )}
      </List>
    </Drawer>
  );
}

export default AppNavigation;
export { DrawerHeader };

import { ProductSearchAttributes } from "api/ms-promotion/api";
import { FormattedMessage } from "react-intl";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import {
  StyledProductInfoContentCell,
  StyledProductInfoHeaderCell
} from "./RestrictionsStyled";

export const PLACE_HOLDER_IMG =
  "https://via.placeholder.com/300x300/000000/FFFFFF/?text=SSENSE";

export interface RestrictionListProps {
  getRestrictionsResponse: any;
  productSearchAttributesForDownloadButton: ProductSearchAttributes;
  selectedRestrictions: Set<string>;
  productsList: Set<string>;
}

export interface HeadCell {
  id: string;
  label: string;
  width: string;
}

export interface CurrencyRestriction {
  code: string;
  exclusion: boolean;
}
export interface Restriction {
  promotionCode: string;
  currenciesRestriction: CurrencyRestriction[];
}
export interface ProductRestriction {
  productCode: string;
  restrictions: Restriction[];
}

export const ProductTable = (props: any) => {
  const { row } = props;
  return (
    <Table>
      <TableBody>
        <TableRow key={row.productCode + "-product-name"}>
          <StyledProductInfoHeaderCell>
            <FormattedMessage
              id="restrictions.listing.product_name"
              defaultMessage="Name"
              description="Name attribute text"
            />
            :
          </StyledProductInfoHeaderCell>
          <StyledProductInfoContentCell>
            {row.product_name}
          </StyledProductInfoContentCell>
        </TableRow>
        <TableRow key={row.productCode + "-category"}>
          <StyledProductInfoHeaderCell>
            <FormattedMessage
              id="restrictions.listing.product_category"
              defaultMessage="Category"
              description="Category attribute text"
            />
            :
          </StyledProductInfoHeaderCell>
          <StyledProductInfoContentCell>
            {row.category_name}
          </StyledProductInfoContentCell>
        </TableRow>
        <TableRow key={row.productCode + "-brand"}>
          <StyledProductInfoHeaderCell>
            <FormattedMessage
              id="restrictions.listing.product_brand"
              defaultMessage="Brand"
              description="Brand attribute text"
            />
            :
          </StyledProductInfoHeaderCell>
          <StyledProductInfoContentCell>
            <span>{row.brand_name}</span>
          </StyledProductInfoContentCell>
        </TableRow>
        <TableRow key={row.productCode + "-supplier-code"}>
          <StyledProductInfoHeaderCell>
            <FormattedMessage
              id="restrictions.listing.product_supplier_code"
              defaultMessage="Supplier Code"
              description="Supplier code attribute text"
            />
            :
          </StyledProductInfoHeaderCell>
          <StyledProductInfoContentCell>
            {row.supplier_code}
          </StyledProductInfoContentCell>
        </TableRow>
        <TableRow key={row.productCode + "-supplier-color"}>
          <StyledProductInfoHeaderCell>
            <FormattedMessage
              id="restrictions.listing.product_supplier_color"
              defaultMessage="Supplier Color"
              description="Supplier color attribute text"
            />
            :
          </StyledProductInfoHeaderCell>
          <StyledProductInfoContentCell>
            {row.supplier_color}
          </StyledProductInfoContentCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const generateHeadCells = (
  allPromotionsCode: string[],
  promotionsExclusionsCodeMapper: any
) => {
  const headCells: HeadCell[] = [];
  headCells.push({
    id: "productCode",
    label: "PRODUCT CODE",
    width: "12%",
  } as HeadCell);
  headCells.push({ id: "image", label: "IMAGE", width: "12%" } as HeadCell);
  headCells.push({
    id: "productInfo",
    label: "PRODUCT INFO",
    width: "22%",
  } as HeadCell);
  if (allPromotionsCode.length > 0) {
    headCells.push({ id: "allFlags", label: "ALL FLAGS", width: "12%" });
  }
  allPromotionsCode.map((promotionCode) =>
    headCells.push({
      id: promotionCode,
      label: promotionsExclusionsCodeMapper[promotionCode],
    } as HeadCell)
  );
  headCells.push({
    id: "lastUpdates",
    label: "LAST UPDATED",
    width: "15%",
  } as HeadCell);
  return headCells;
};

import * as React from 'react';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Box, BoxProps, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSimulations } from "../../../utils/api/apiTools";


const SimulationContainer = styled('div')(({theme}) => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '95%',
  paddingTop: '20px',
}));

const HeaderSection = styled('div')(({ theme }) => ({
  height: '72px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

const Title = styled("h2")({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  marginTop: 0,
});

const StyledButton = styled(Button)({
  float: 'right',
  backgroundColor: '#1976D2',
  color: 'white',
  width: '175px',
  height: '36px',
});

interface PillProps extends BoxProps {
  status: string;
}

const Pill = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<PillProps>(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px 4px',
  borderRadius: '16px',
  width: status === 'Published' ? '76px' : status === 'Draft' ? '50px' : '76px',
  height: '18px',
  backgroundColor: status === 'Published' ? '#2E7D32' : status === 'Draft' ? '#EBEBEB' : '#EBEBEB',
  color: status === 'Published' ? '#fff' : status === 'Draft' ? '#000000' : '#000000',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0.16px',
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    color: '#757575',
    maxHeight: '32px',
    minHeight: '32px',
    height: '32px',
    lineHeight: '32px',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-menuIcon, & .MuiDataGrid-withBorderColor, & .MuiDataGrid-columnHeaderRow': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: '0px',
    maxHeight: '32px',
    minHeight: '32px',
    height: '32px',
    lineHeight: '32px',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    paddingLeft: '16px',
    paddingRight: '16px',

  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent': {
    paddingRight: '0px',
  },
  '& .MuiDataGrid-cell': {
    padding: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderBottom: 'none',
  },
  '& .MuiDataGrid-row': {
    '--rowBorderColor': 'none',
  },
}));

type Simulation = {
  id: string;
  name: string;
  sale_event: {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    type: string;
  };
  simulation_objective: {
    id: string;
    name: string;
  };
  audiences: { id: string; name: string }[];
  country_codes: string[];
}
export default function SimulationPage() {
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  useEffect(() => {
    const fetchSimulationData = async () => {
      const response = await getSimulations(pagination.page + 1, pagination.pageSize);
      setSimulations(response.results);
      setTotalRowCount(response.pagination.total_count);
    }
    fetchSimulationData();
  }, [pagination]);

  const rows = simulations.map((data, index) => ({
    id: data.id,
    simulation: data.name,
    event: data.sale_event.name,
    source: '', // TODO: clarify what field to map to.
    publicationDate: '', // TODO: clarify what field to map to.
    country: '', // leaving out for now
    audience: '', // leaving out for now
    objective: data.simulation_objective.name,
    status: '', // TODO: Missing
  }))

  const intl = useIntl();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'simulation',
      headerName: intl.formatMessage({id: "simulation.column_title.simulation"}),
      headerAlign: 'left',
      align: 'left',
      flex: 2
    },
    {
      field: 'event',
      headerName: intl.formatMessage({id: "simulation.column_title.event"}),
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'source',
      headerName: intl.formatMessage({id: "simulation.column_title.source"}),
      headerAlign: 'right',
      align: 'right',
      flex: 1
    },
    {
      field: 'publicationDate',
      headerName: intl.formatMessage({id: "simulation.column_title.publication_date"}),
      headerAlign: 'right',
      align: 'right',
      flex: 1
    },
    {
      field: 'country',
      headerName: intl.formatMessage({id: "simulation.column_title.country"}),
      headerAlign: 'right',
      align: 'right',
      flex: 0.5
    },
    {
      field: 'audience',
      headerName: intl.formatMessage({id: "simulation.column_title.audience"}),
      headerAlign: 'right',
      align: 'right',
      flex: 0.5
    },
    {
      field: 'objective',
      headerName: intl.formatMessage({id: "simulation.column_title.objective"}),
      headerAlign: 'right',
      align: 'right',
      flex: 0.5
    },
    {
      field: 'status',
      headerName: intl.formatMessage({id: "simulation.column_title.status"}),
      headerAlign: 'right',
      align: 'right',
      flex: 0.75,
      renderCell: (params: any) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Pill status={params.value}>
            {params.value}
          </Pill>
        </Box>
      )
    }]

  const createSimulation = () => {
    navigate('/simulations/create');
  };

  const handlePageChange = (model: GridPaginationModel) => {
    setPagination({
      ...pagination,
      page: model.page,
      pageSize: model.pageSize
    });
  };

  return (
    <SimulationContainer>
      <HeaderSection>
        <Title> Simulations </Title>
        <StyledButton
          onClick={createSimulation}> {intl.formatMessage({id: "simulation.create.title"})}</StyledButton>
      </HeaderSection>

      <StyledDataGrid
        rows={rows}
        columns={columns}
        autoHeight
        rowCount={totalRowCount}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.pageSize
        }}
        pageSizeOptions={[5, 10, 50]}
        onPaginationModelChange={handlePageChange}
        rowHeight={32}
      />
    </SimulationContainer>
  );
}

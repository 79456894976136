import { Waypoint } from "react-waypoint";

import { LinearProgress } from "@mui/material";

export interface ContinuationWaypointProps {
  response: {
    continuationToken?: string;
    continuationLoading: boolean;
    fetchNext: () => void;
  };
  continuationLoadingComponent?: React.ReactElement<any, any> | false;
}

/**
 * This is a Waypoint that can be inserted, to mark the place in the page
 * that will trigger the fetchNext() on a useContinuationApi() hooks.
 *
 * Basically, this mark the place in the layout where the next set of results
 * are going to be retrieved.
 */
function ContinuationWaypoint({
  response,
  continuationLoadingComponent,
  ...props
}: any): any {
  if (continuationLoadingComponent === undefined) {
    continuationLoadingComponent = <LinearProgress style={{ width: "100%" }} />;
  }

  if (props.scrollableAncestor === undefined) {
    props.scrollableAncestor = "window";
  }

  return (
    <>
      {response.continuationLoading &&
        continuationLoadingComponent !== false &&
        continuationLoadingComponent}
      <Waypoint
        key={response.continuationToken}
        onEnter={response.fetchNext}
        {...props}
      />
    </>
  );
}

export default ContinuationWaypoint;

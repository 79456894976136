import { useUserPreferences } from "context/preferences/UserPreferences";
import { FormattedMessage } from "react-intl";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";

function Searches() {
  const userPreferences = useUserPreferences();

  const handleMaxRecentSearchesChange = (event: SelectChangeEvent) => {
    const maxRecentSearches = parseInt(event.target.value);

    userPreferences.search.setRecentSearchesLimit?.(maxRecentSearches);

    if (userPreferences.search.recentSearches.length > maxRecentSearches) {
      const recentSearchesNumberToDelete =
        userPreferences.search.recentSearches.length - maxRecentSearches;

      userPreferences.search.deleteRecentSearch?.(recentSearchesNumberToDelete);
    }
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="recent-searches-limit-label">
        <FormattedMessage
          id="restrictions.preferences.recent_searches_number_input"
          defaultMessage="Number of recent searches"
          description="Recent searches number input"
        />
      </InputLabel>
      <Select
        labelId="recent-searches-limit-label"
        id="recent-searches-max-number"
        value={String(userPreferences.search.recentSearchesLimit)}
        label="Number of Recent Searches"
        onChange={handleMaxRecentSearchesChange}
      >
        {[0, 1, 2, 3, 4, 5].map((number) => (
          <MenuItem key={number} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        <FormattedMessage
          id="restrictions.preferences.recent_searches_number_input_legend"
          defaultMessage="Limiting number of recent searches displayed on the screen"
          description="Recent searches number input legend"
        />
      </FormHelperText>
    </FormControl>
  );
}

export default Searches;

import { useUserPreferences } from "context/preferences/UserPreferences";
import { FormattedMessage } from "react-intl";

import { InputLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";

function ColorMode() {
  const userPreferences = useUserPreferences();

  const handleColorModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newColorMode: string
  ) => {
    if (newColorMode !== null) {
      userPreferences.colorMode.setMode?.(newColorMode);
    }
  };

  return (
    <>
      <InputLabel sx={{ fontSize: 10 }}>
        <FormattedMessage
          id="restrictions.preferences.color_mode"
          defaultMessage="Background mode"
          description="Color mode preference"
        />
      </InputLabel>
      <ToggleButtonGroup
        value={userPreferences.colorMode.mode}
        exclusive
        size="small"
        onChange={handleColorModeChange}
        aria-label="color mode"
        color="primary"
      >
        <ToggleButton sx={{ textTransform: "none" }} value="light">
          <FormattedMessage
            id="restrictions.preferences.color_mode_light"
            defaultMessage="Light"
            description="Color mode light option"
          />
        </ToggleButton>
        <ToggleButton sx={{ textTransform: "none" }} value="system">
          <FormattedMessage
            id="restrictions.preferences.color_mode_system"
            defaultMessage="System"
            description="Color mode system option"
          />
        </ToggleButton>
        <ToggleButton sx={{ textTransform: "none" }} value="dark">
          <FormattedMessage
            id="restrictions.preferences.color_mode_dark"
            defaultMessage="Dark"
            description="Color mode dark option"
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}

export default ColorMode;

import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

export const StyledPaper = styled(Paper)({
  fontSize: "13px",
  width: "100%",
  marginTop: "20px",
});

export const StyledTableContainer = styled(TableContainer)({
  overflowX: "visible",
});
export const StyledVerticalTopTableRow = styled(TableRow)({
  verticalAlign: "top",
  height: "100%",
});

export const StyledHeaderTableRow = styled(TableRow)({
  "& .MuiTableCell-head": {
    fontWeight: 700,
    top: "64px",
  },
});

export const StyledProductInfoHeaderCell = styled(TableCell)({
  width: "40%",
  textAlign: "left",
  fontWeight: "bold",
  borderBottom: "none",
  padding: "5px",
});

export const StyledProductInfoContentCell = styled(TableCell)({
  borderBottom: "none",
  padding: "5px",
});

export const StyledPaddingTableCell = styled(TableCell)({
  paddingTop: "20px",
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  display: "block",
  textAlign: "left",
  textIndent: "-15px",
});

import { Alert, LinearProgress } from "@mui/material";

type ApiResponseProps = {
  response: {
    data?: any;
    loading: boolean;
    totalCount?: number;
    error?: any;
  };
  loading?: React.ReactElement<any, any> | false;
  error?: (error: any) => React.ReactElement<any, any>;
  children: React.ReactNode;
};

/**
 * The ApiResponse manages the display of results coming from
 * the useApi hooks, by showing loading indicator, error messages, and results.
 */
function ApiResponse(props: ApiResponseProps) {
  const { response, loading, error, children } = props;

  if (response.loading && loading !== false) {
    return loading || <LinearProgress />;
  }

  if (
    response.totalCount &&
    response.totalCount > 0 &&
    response.data.length === 0
  ) {
    return loading || <LinearProgress />;
  }

  if (response.error) {
    return error ? (
      error(response.error)
    ) : (
      <Alert severity="error">{`${response.error}`}</Alert>
    );
  }

  return !response.loading && !response.error && response.data ? (
    <>{children}</>
  ) : null;
}

export default ApiResponse;

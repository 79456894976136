import { Promotion } from "api/ms-promotion/api";
import { DrawerHeader } from "components/layout/navigation/AppNavigation";
import { useApiInfo } from "context/api/ApiInfo";
import { useUserPreferences } from "context/preferences/UserPreferences";
import { useApi } from "hooks/api";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import enMessages from "translations/en.json";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Autocomplete as Select,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { styled } from "@mui/material/styles";

import SaveSearchModal from "./SaveSearchModal";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const ClearAll = styled(Button)({
  position: "absolute",
  right: "10px",
  margin: "5px",
});

const Autocomplete = styled(Select)({
  width: "100%",
  padding: "8px 8px 0px 8px",
});

const selectFieldsDefaultValue = ["All"];

export interface FindProductsDrawerProps {
  drawerVisible: boolean;
  onSetNewSearch: Function;
  onClose: Function;
  onSetDrawerVisible: Function;
  onSetProductCode: Function;
  onSetBrand: Function;
  onSetGenders: Function;
  onSetSeasons: Function;
  onSetDepartments: Function;
  onSetCategories: Function;
  onSetSubcategories: Function;
  onSetRestrictionFilter: Function;
  onGetRestrictionResponse: Function;
  onSetRestrictionsListVisible: Function;
}
const CustomChip = (props: any) => {
  if (props.label === selectFieldsDefaultValue[0]) {
    return <Chip {...props} label={props.intllabel} onDelete={undefined} />;
  }
  return <Chip {...props} />;
};

export enum GeneralExclusionFlagFilter {
  ALL_FLAGS = "All",
  ANY_FLAGS = "Products with any restrictions",
  NO_SET = "Products with no restrictions",
  FLAGS_SET = "Select by Sale Type",
}

export enum ExclusionFlagDropdownValue {
  ALL = "All",
  YES = "Yes",
  NO = "No",
}

type ExclusionFilterValue = {
  promotionCode: string;
  value: boolean;
};

type RestrictionFilter = {
  restrictionType: string;
  restrictionValues?: ExclusionFilterValue[];
};

export function FindProductsDrawer(props: FindProductsDrawerProps) {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    drawerVisible,
    onSetNewSearch,
    onClose,
    onSetDrawerVisible,
    onSetProductCode,
    onSetBrand,
    onSetGenders,
    onSetSeasons,
    onSetDepartments,
    onSetCategories,
    onSetSubcategories,
    onSetRestrictionFilter,
    onGetRestrictionResponse,
    onSetRestrictionsListVisible,
  } = props;

  const userPreferences = useUserPreferences();
  const intl = useIntl();
  const apiInfo = useApiInfo();

  const [productCode, setProductCode] = useState("");
  const [brand, setBrand] = useState(null);
  const [seasons, setSeasons] = useState<any>(selectFieldsDefaultValue);
  const [genders, setGenders] = useState<any>(selectFieldsDefaultValue);
  const [departments, setDepartments] = useState<any>(selectFieldsDefaultValue);
  const [categories, setCategories] = useState<any>(selectFieldsDefaultValue);
  const [subcategories, setSubcategories] = useState<any>(
    selectFieldsDefaultValue
  );
  const [restrictionFilter, setRestrictionFilter] = useState<RestrictionFilter>(
    { restrictionType: GeneralExclusionFlagFilter.ALL_FLAGS }
  );

  const [showError, setShowError] = useState(false);

  const [saveSearchModalVisible, setSaveSearchModalVisible] = useState(false);

  useEffect(() => {
    if (Object.keys(userPreferences.currentSearch.search).length) {
      const params = userPreferences.currentSearch.search.parameters;

      if (params.productCode) {
        setProductCode(params.productCode);
        setBrand(null);
        setGenders(selectFieldsDefaultValue);
        setSeasons(selectFieldsDefaultValue);
        setDepartments(selectFieldsDefaultValue);
        setCategories(selectFieldsDefaultValue);
        setSubcategories(selectFieldsDefaultValue);
        setRestrictionFilter({
          restrictionType: GeneralExclusionFlagFilter.ALL_FLAGS,
        });
      } else {
        setProductCode("");
        setBrand(params.brand);
        setGenders(params.gender);
        setSeasons(params.season);
        setDepartments(params.department);
        setCategories(params.category);
        setSubcategories(params.subcategory);
        if (
          apiInfo.feature_flags
            ?.is_find_product_filter_by_exclusion_flags_enabled
        ) {
          setRestrictionFilter(
            params.restrictionFilter || {
              restrictionType: GeneralExclusionFlagFilter.ALL_FLAGS,
            }
          ); // To ensure backwards compatibility with existing recent searches without this field
        } else {
          setRestrictionFilter({
            restrictionType: GeneralExclusionFlagFilter.ALL_FLAGS,
          }); // To ensure FF OFF does not break application
        }
      }
    }
  }, [
    apiInfo.feature_flags?.is_find_product_filter_by_exclusion_flags_enabled,
    userPreferences.currentSearch.search,
  ]);

  const productSearchParams: any = useApi((api: any) =>
    api.getProductSearchParameters()
  );
  const handleDrawerClose = () => {
    onSetDrawerVisible(false);
  };

  const shouldClearAllButtonBeDisabled = () => {
    return (
      !productCode &&
      !brand &&
      genders === selectFieldsDefaultValue &&
      seasons === selectFieldsDefaultValue &&
      departments === selectFieldsDefaultValue &&
      categories === selectFieldsDefaultValue &&
      subcategories === selectFieldsDefaultValue &&
      restrictionFilter.restrictionType === GeneralExclusionFlagFilter.ALL_FLAGS
    );
  };

  const clearAll = () => {
    setProductCode("");
    setBrand(null);
    setSeasons(selectFieldsDefaultValue);
    setGenders(selectFieldsDefaultValue);
    setDepartments(selectFieldsDefaultValue);
    setCategories(selectFieldsDefaultValue);
    setSubcategories(selectFieldsDefaultValue);
    setRestrictionFilter({
      restrictionType: GeneralExclusionFlagFilter.ALL_FLAGS,
    });
  };

  const handleChange = (value: any, setValue: Function) => {
    //If all the options are deselected, we want "All" to be selected
    value.length === 0 ? setValue(selectFieldsDefaultValue) : setValue(value);

    //If "All" is selected, all other options should be deselected
    if (String(value.at(-1)) === selectFieldsDefaultValue[0]) {
      setValue(selectFieldsDefaultValue);
    }
    //If any option other than "All" is selected, "All" should be deselected
    if (value.includes(selectFieldsDefaultValue[0]) && value.length > 1) {
      value.splice(value.indexOf(selectFieldsDefaultValue[0]), 1);
    }
  };

  const handleClose = () => {
    onClose();
  };

  type FiltersSearch = {
    brand: string;
    gender: string[];
    season: string[];
    department: string[];
    category: string[];
    subcategory: string[];
    restrictionFilter: RestrictionFilter;
  };

  type ProductCodeSearch = {
    productCode: string;
  };

  const isSearchByProductCode = () => {
    return productCode !== "";
  };

  const buildRestrictionFilterForBackEnd = () => {
    let toSend = {} as any;
    if (
      restrictionFilter.restrictionType === GeneralExclusionFlagFilter.FLAGS_SET
    ) {
      restrictionFilter.restrictionValues?.forEach((flag: any) => {
        Object.assign(toSend, {
          [flag.promotionCode]: flag.value === ExclusionFlagDropdownValue.YES,
        });
      });
    } else if (
      restrictionFilter.restrictionType === GeneralExclusionFlagFilter.NO_SET
    ) {
      (productSearchParams.data?.promotions as Promotion[]).forEach(
        (promotion) => {
          Object.assign(toSend, {
            [promotion.code]: false,
          });
        }
      );
    } else if (
      restrictionFilter.restrictionType === GeneralExclusionFlagFilter.ANY_FLAGS
    ) {
      (productSearchParams.data?.promotions as Promotion[]).forEach(
        (promotion) => {
          Object.assign(toSend, {
            [promotion.code]: true,
          });
        }
      );
    }

    return {
      promotion_code_filters: toSend,
      is_meeting_any_of_filter_is_enough:
        restrictionFilter.restrictionType ===
        GeneralExclusionFlagFilter.ANY_FLAGS,
    };
  };

  const getSearchParameters = () => {
    let parameters = {};

    if (isSearchByProductCode()) {
      parameters = {
        productCode: productCode,
      };
    } else {
      parameters = {
        brand: brand,
        gender: genders,
        season: seasons,
        department: departments,
        category: categories,
        subcategory: subcategories,
        restrictionFilter: restrictionFilter,
      };
    }

    return parameters;
  };

  const getSearchName = (
    searchParameters: FiltersSearch | ProductCodeSearch
  ) => {
    if ("productCode" in searchParameters) {
      return searchParameters.productCode;
    } else {
      return searchParameters.brand;
    }
  };

  const handleSaveSearchModalOpen = () => {
    setSaveSearchModalVisible(true);
  };

  const handleSaveSearchModalClose = () => {
    setSaveSearchModalVisible(false);
  };

  const openRestrictionList = () => {
    onSetDrawerVisible(false);
    onSetProductCode(productCode);
    onSetBrand(brand);
    onSetGenders(genders);
    onSetSeasons(seasons);
    onSetDepartments(departments);
    onSetCategories(categories);
    onSetSubcategories(subcategories);
    onSetRestrictionFilter(buildRestrictionFilterForBackEnd());
    onSetRestrictionsListVisible(true);
  };
  const validateFieldsAndFetchData = () => {
    if (!productCode && !brand) {
      setShowError(true);
      return;
    }

    const searchParameters = getSearchParameters() as
      | FiltersSearch
      | ProductCodeSearch;

    const searchName = getSearchName(searchParameters);

    const searchToSave = {
      name: searchName,
      parameters: searchParameters,
      date: new Date().toUTCString(),
    };
    if (userPreferences.search.recentSearchesLimit > 0) {
      userPreferences.search.addSearch?.(searchToSave, "recentSearches");
    }

    onSetNewSearch(true);
    openRestrictionList();
    onGetRestrictionResponse();
  };

  interface ExclusionFlagDropdownProps {
    promotion: Promotion;
  }

  const ExclusionFlagDropdown = useMemo(
    () => (props: ExclusionFlagDropdownProps) => {
      const { promotion } = props;

      const isExclusionSelected = (promotionCode: string): boolean =>
        restrictionFilter.restrictionValues?.some(
          (restrictionValue: ExclusionFilterValue) =>
            restrictionValue.promotionCode === promotionCode
        ) || false;

      const getExclusionFilterValue = (promotionCode: string) =>
        restrictionFilter.restrictionValues?.find(
          (restrictionValue) => restrictionValue.promotionCode === promotionCode
        );

      const handleExclusionFilterChange = (_evt: object, value: any) => {
        // If ALL is selected, we simply remove that filter from the list
        if (value === ExclusionFlagDropdownValue.ALL) {
          const newState = restrictionFilter.restrictionValues?.filter(
            (exclusionFilterValue) => {
              return exclusionFilterValue.promotionCode !== promotion.code;
            }
          );
          setRestrictionFilter((prevState) => ({
            ...prevState,
            ...(newState !== undefined && {
              restrictionValues: newState,
            }),
          }));
        } // Else if the promotion code already in the list, we update the value
        else if (isExclusionSelected(promotion.code)) {
          const newState = restrictionFilter.restrictionValues?.map(
            (restrictionValue) => {
              if (restrictionValue.promotionCode === promotion.code) {
                return { ...restrictionValue, value: value };
              }
              return restrictionValue;
            }
          );
          setRestrictionFilter((prevState) => ({
            ...prevState,
            restrictionValues: newState,
          }));
        } // In this case, the promotion code is not in the list, so we add a new object for it
        else {
          const newEntry = {
            promotionCode: promotion.code,
            value: value,
          };
          setRestrictionFilter((prevState) => ({
            ...prevState,
            restrictionValues: [
              ...(prevState.restrictionValues || []),
              newEntry,
            ],
          }));
        }
      };

      return (
        <Grid item xs={12}>
          <Autocomplete
            id={promotion.exclusion_code}
            limitTags={1}
            getOptionLabel={(option) => getIntlLabel(option as string)}
            options={Object.values(ExclusionFlagDropdownValue)}
            value={
              getExclusionFilterValue(promotion.code)?.value ||
              ExclusionFlagDropdownValue.ALL
            }
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip
                  {...getTagProps({ index })}
                  label={option}
                  intllabel={getIntlLabel(option as string)}
                />
              ));
            }}
            onChange={(_evt: object, value: any) => {
              handleExclusionFilterChange(_evt, value);
            }}
            renderInput={(params: any) => (
              <TextField
                {...(params as any)}
                label={promotion.exclusion_code}
              />
            )}
          />
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restrictionFilter?.restrictionValues]
  );

  const getIntlLabel = (option: string) => {
    let id: keyof typeof enMessages | undefined;

    if (option === "All") {
      id = "restrictions.search.all";
    }
    if (option === GeneralExclusionFlagFilter.ANY_FLAGS) {
      id = "restrictions.search.any_restrictions";
    }
    if (option === GeneralExclusionFlagFilter.NO_SET) {
      id = "restrictions.search.no_restrictions_set";
    }
    if (option === GeneralExclusionFlagFilter.FLAGS_SET) {
      id = "restrictions.search.restrictions_set";
    }
    if (option === ExclusionFlagDropdownValue.YES) {
      id = "restrictions.search.yes";
    }
    if (option === ExclusionFlagDropdownValue.NO) {
      id = "restrictions.search.no";
    }

    const label = id
      ? intl.formatMessage({
          id: id,
          defaultMessage: option,
          description: "Dynamic intl message",
        })
      : option;

    return label;
  };

  return (
    <Drawer
      open={drawerVisible}
      onClose={handleClose}
      anchor="right"
      PaperProps={
        matchesSM
          ? {
              sx: { width: "100%" },
            }
          : { sx: { width: "30%" } }
      }
    >
      <DrawerHeader />

      <Grid
        container
        sx={{
          background: theme.palette.primary.main,
        }}
      >
        <Grid item>
          <Typography sx={{ verticalAlign: "middle", display: "inline-flex" }}>
            <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
            <InputLabel
              sx={{
                color: "white",
                fontSize: 16,
                padding: "10px",
              }}
            >
              <FormattedMessage
                id="restrictions.find.button"
                defaultMessage="Find product"
                description="Find product text button"
              />
            </InputLabel>
          </Typography>
        </Grid>
        <Grid item>
          <ClearAll
            role="clearAll"
            onClick={clearAll}
            disabled={shouldClearAllButtonBeDisabled()}
            color="neutral"
            variant="contained"
          >
            <FormattedMessage
              id="restrictions.clear.button"
              defaultMessage="Clear all"
              description="Clear all text button"
            />
          </ClearAll>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1}>
        <Grid
          role="error"
          item
          xs={12}
          id="error"
          sx={{
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;",
            color: "#000 80%",
            margin: "16px 16px 0px 16px",
            borderRadius: "4px",
            display: showError ? "block" : "none",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ verticalAlign: "middle", display: "inline-flex" }}
          >
            <ErrorOutlineIcon
              sx={{ color: "#D32F2F", margin: "4px 4px 8px 8px" }}
            />
            <InputLabel sx={{ margin: "4px 4px 8px 8px" }}>
              <FormattedMessage
                id="restrictions.search.error"
                defaultMessage="Please select a value"
                description="Error message when search not valid"
              />
            </InputLabel>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="productCode"
            label={intl.formatMessage({
              id: "restrictions.product_code.input",
              defaultMessage: "Enter product code",
              description: "Product code search input label",
            })}
            variant="outlined"
            value={productCode}
            error={showError}
            helperText={
              showError
                ? intl.formatMessage({
                    id: "restrictions.product_code.input",
                    defaultMessage: "Enter product code",
                    description: "Product code search input label",
                  })
                : intl.formatMessage({
                    id: "restrictions.product_code.input_description",
                    defaultMessage:
                      "When the Product Code is entered, below fields will be omitted.",
                    description: "Product code search input description",
                  })
            }
            onChange={(evt: any) => {
              setProductCode(evt.target.value);
              setShowError(false);
              document.getElementById("error")!.style.display = "none";
            }}
            sx={{ width: "100%", marginTop: "10px", paddingLeft: "8px" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider
            variant="middle"
            textAlign="left"
            sx={{ fontSize: "12px", color: (theme) => theme.palette.grey[500] }}
          >
            <FormattedMessage
              id="restrictions.or"
              defaultMessage="Or"
              description="Or"
            />
          </Divider>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="brand"
            value={brand}
            options={
              productSearchParams.data?.product_search_attributes.brands || []
            }
            onChange={(evt: object, value: any) => {
              setBrand(value);
              setShowError(false);
              document.getElementById("error")!.style.display = "none";
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip
                  {...getTagProps({ index })}
                  label={option}
                  intllabel={getIntlLabel(option as string)}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  intl.formatMessage({
                    id: "restrictions.brand.input",
                    defaultMessage: "Select brand",
                    description: "Brand search input label",
                  }) + "*"
                }
                error={showError}
                helperText={
                  showError
                    ? intl.formatMessage({
                        id: "restrictions.brand.input",
                        defaultMessage: "Select brand",
                        description: "Brand search input label",
                      })
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="gender"
            limitTags={2}
            multiple
            disableCloseOnSelect
            disableClearable={genders === selectFieldsDefaultValue}
            getOptionLabel={(option) => getIntlLabel(option as string)}
            options={[
              ...selectFieldsDefaultValue,
              ...(productSearchParams.data?.product_search_attributes.genders ||
                []),
            ]}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip
                  {...getTagProps({ index })}
                  label={option}
                  intllabel={getIntlLabel(option as string)}
                />
              ));
            }}
            onChange={(evt: object, value: any) => {
              handleChange(value, setGenders);
            }}
            value={genders}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "restrictions.gender.input",
                  defaultMessage: "Select gender",
                  description: "Gender search input label",
                })}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="season"
            limitTags={2}
            multiple
            disableCloseOnSelect
            disableClearable={seasons === selectFieldsDefaultValue}
            getOptionLabel={(option) => getIntlLabel(option as string)}
            value={seasons}
            options={[
              ...selectFieldsDefaultValue,
              ...(productSearchParams.data?.product_search_attributes
                .season_codes || []),
            ]}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip
                  {...getTagProps({ index })}
                  label={option}
                  intllabel={getIntlLabel(option as string)}
                />
              ));
            }}
            sx={{ margin: 0, padding: "8px 8px 0 8px" }}
            onChange={(evt: object, value: any) =>
              handleChange(value, setSeasons)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "restrictions.season.input",
                  defaultMessage: "Select season",
                  description: "Season search input label",
                })}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {productSearchParams.data && (
            <Autocomplete
              id="department"
              limitTags={2}
              multiple
              disableCloseOnSelect
              disableClearable={departments === selectFieldsDefaultValue}
              getOptionLabel={(option) => getIntlLabel(option as string)}
              value={departments}
              options={[
                ...selectFieldsDefaultValue,
                ...(productSearchParams.data?.product_search_attributes
                  .departments || []),
              ]}
              onChange={(evt: object, value: any) =>
                handleChange(value, setDepartments)
              }
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip
                    {...getTagProps({ index })}
                    label={option}
                    intllabel={getIntlLabel(option as string)}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "restrictions.department.input",
                    defaultMessage: "Select department",
                    description: "Department search input label",
                  })}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {productSearchParams.data && (
            <Autocomplete
              id="category"
              limitTags={2}
              multiple
              disableCloseOnSelect
              disableClearable={categories === selectFieldsDefaultValue}
              getOptionLabel={(option) => getIntlLabel(option as string)}
              value={categories}
              options={[
                ...selectFieldsDefaultValue,
                ...(productSearchParams.data?.product_search_attributes
                  .categories || []),
              ]}
              onChange={(evt: object, value: any) =>
                handleChange(value, setCategories)
              }
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip
                    {...getTagProps({ index })}
                    label={option}
                    intllabel={getIntlLabel(option as string)}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "restrictions.category.input",
                    defaultMessage: "Select category",
                    description: "Category search input label",
                  })}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {productSearchParams.data && (
            <Autocomplete
              id="subCategory"
              limitTags={2}
              multiple
              disableCloseOnSelect
              disableClearable={subcategories === selectFieldsDefaultValue}
              getOptionLabel={(option) => getIntlLabel(option as string)}
              value={subcategories}
              options={[
                ...selectFieldsDefaultValue,
                ...(productSearchParams.data?.product_search_attributes
                  .subcategories || []),
              ]}
              onChange={(evt: object, value: any) =>
                handleChange(value, setSubcategories)
              }
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <CustomChip
                    {...getTagProps({ index })}
                    label={option}
                    intllabel={getIntlLabel(option as string)}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "restrictions.subcategory.input",
                    defaultMessage: "Select sub-category",
                    description: "Sub-category search input label",
                  })}
                />
              )}
            />
          )}
        </Grid>
        {apiInfo.feature_flags
          ?.is_find_product_filter_by_exclusion_flags_enabled && (
          <Grid item xs={12}>
            <Autocomplete
              id="restriction"
              getOptionLabel={(option) => getIntlLabel(option as string)}
              options={Object.values(GeneralExclusionFlagFilter)}
              value={restrictionFilter.restrictionType}
              onChange={(_evt: object, value: any) => {
                setRestrictionFilter({ restrictionType: value });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "restrictions.restriction.input",
                    defaultMessage: "Select restriction",
                    description: "Restriction search input label",
                  })}
                />
              )}
            />
          </Grid>
        )}
        {restrictionFilter.restrictionType ===
          GeneralExclusionFlagFilter.FLAGS_SET &&
          productSearchParams.data?.promotions.map((promotion: Promotion) => (
            <ExclusionFlagDropdown
              key={promotion.exclusion_code}
              promotion={promotion}
            />
          ))}
        {process.env.REACT_APP_IS_USER_PREFERENCES_ENABLED === "true" && (
          <Grid item xs={12} sx={{ mr: 2, mb: 2, textAlign: "right" }}>
            <Button
              variant="outlined"
              startIcon={<BookmarkIcon />}
              onClick={handleSaveSearchModalOpen}
              disabled={!productCode && !brand}
              size="small"
            >
              <FormattedMessage
                id="restrictions.save_filter.button"
                defaultMessage="Save filters"
                description="Save filters text button"
              />
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        justifyContent="flex-end"
        sx={{ height: "100%" }}
      >
        <Grid item xs={12}>
          <Button
            role="find"
            variant="contained"
            onClick={validateFieldsAndFetchData}
            fullWidth
          >
            <FormattedMessage
              id="restrictions.search.button"
              defaultMessage="Find"
              description="Find text button"
            />
          </Button>
        </Grid>
      </Grid>
      <SaveSearchModal
        open={saveSearchModalVisible}
        onClose={handleSaveSearchModalClose}
        parameters={getSearchParameters()}
      />
    </Drawer>
  );
}

export default FindProductsDrawer;

import "moment-timezone";

import {
  UserSearch,
  useUserPreferences
} from "context/preferences/UserPreferences";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";

import { Delete } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";

type RestrictionsSearchesProps = {
  onSetProductDrawerVisible: Function;
};

function RestrictionsSearches(props: RestrictionsSearchesProps) {
  const userPreferences = useUserPreferences();

  const handleDeleteSearch = (
    _event: React.MouseEvent<HTMLElement>,
    search: UserSearch
  ) => {
    userPreferences.search.deleteSavedSearch?.(search.name, search.date);
  };

  const handleSearchClick = (
    _event: React.MouseEvent<HTMLElement>,
    search: UserSearch
  ) => {
    userPreferences.currentSearch.setSearch?.(search);
    props.onSetProductDrawerVisible(true);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      {userPreferences.search.recentSearches.length > 0 && (
        <Grid item xs minWidth={300}>
          <Paper sx={{ padding: 1 }}>
            <Typography align="left" variant="h6" gutterBottom>
              <FormattedMessage
                id="restrictions.recent_searches.title"
                defaultMessage="Recent searches"
                description="Recent searches block title"
              />
            </Typography>
            <Divider />
            <List dense sx={{ maxHeight: 288, overflow: "auto" }}>
              {userPreferences.search.recentSearches
                .map((search) => (
                  <ListItem disablePadding key={search.name + search.date}>
                    <ListItemButton
                      onClick={(event) => handleSearchClick(event, search)}
                    >
                      <Tooltip
                        enterDelay={1000}
                        placement="bottom-start"
                        title={JSON.stringify(search.parameters)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={search.name}
                          secondary={
                            <Moment
                              locale={userPreferences.language.language}
                              fromNow
                            >
                              {search.date}
                            </Moment>
                          }
                        />
                      </Tooltip>
                    </ListItemButton>
                  </ListItem>
                ))
                .reverse()}
            </List>
          </Paper>
        </Grid>
      )}
      {userPreferences.search.searches.length > 0 && (
        <Grid item xs minWidth={300}>
          <Paper sx={{ padding: 1 }}>
            <Typography align="left" variant="h6" gutterBottom>
              <FormattedMessage
                id="restrictions.saved_searches.title"
                defaultMessage="Saved searches"
                description="Saved searches block title"
              />
            </Typography>
            <Divider />
            <List dense sx={{ maxHeight: 288, overflow: "auto" }}>
              {userPreferences.search.searches
                .map((search) => (
                  <ListItem
                    disablePadding
                    key={search.name + search.date}
                    secondaryAction={
                      <IconButton
                        color="primary"
                        edge="end"
                        aria-label="delete"
                        onClick={(event) => handleDeleteSearch(event, search)}
                      >
                        <Delete />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      onClick={(event) => handleSearchClick(event, search)}
                    >
                      <Tooltip
                        enterDelay={1000}
                        placement="bottom-start"
                        title={JSON.stringify(search.parameters)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                          primary={search.name}
                          secondary={
                            <Moment
                              locale={userPreferences.language.language}
                              fromNow
                            >
                              {search.date}
                            </Moment>
                          }
                        />
                      </Tooltip>
                    </ListItemButton>
                  </ListItem>
                ))
                .reverse()}
            </List>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default RestrictionsSearches;

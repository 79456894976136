import { InfoApi, InfoResponse } from "api/ms-promotion/api";
import { Configuration } from "api/ms-promotion/configuration";
import { createContext, useContext, useEffect, useState } from "react";

export const ApiInfoContext = createContext<InfoResponse>({
  dm_auth_server_url: "unknown",
  environment: "unknown",
  git_commit: "unknown",
  feature_flags: null as any,
  user_connection_user_id_secret_key: "unknown",
});

/**
 * Provides api /info values.
 */
export const useApiInfo = () => useContext(ApiInfoContext);

export const infoApi = new InfoApi(
  new Configuration({
    basePath: process.env.REACT_APP_API_PATH,
  })
);

type ApiInfoProps = {
  children: React.ReactNode;
};

/**
 * Non-rendering component that provides a context for the /info api endpoint.
 */
function ApiInfo(props: ApiInfoProps) {
  const { children } = props;

  const [apiInfo, setApiInfo] = useState<InfoResponse | undefined>(undefined);

  useEffect(() => {
    const fetchApiInfo = async () => {
      const response = await infoApi.getApiInfo();
      setApiInfo(response.data);
    };

    fetchApiInfo();
  }, []);

  if (!apiInfo) {
    return null;
  }

  return (
    <ApiInfoContext.Provider value={apiInfo}>
      {children}
    </ApiInfoContext.Provider>
  );
}

export default ApiInfo;

import { FormattedMessage } from "react-intl";

import { Button, Grid } from "@mui/material";

interface SuccessToastProps {
  notification: any;
}

function SuccessToast(props: SuccessToastProps) {
  const { notification } = props;

  return (
    <Grid container alignItems="center" spacing={2}>
      {notification.details?.link ? (
        <>
          <Grid item sx={{ width: "230px", overflow: "auto" }}>
            <FormattedMessage
              id={notification.message}
              defaultMessage="Succeeded"
              description="Message for successful csv download received from back-end"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              href={notification.details?.link}
              target="_blank"
              color="inherit"
            >
              <FormattedMessage
                id="notification.succeeded.download"
                defaultMessage="Download"
                description="Download button text"
              />
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sx={{ overflow: "auto" }}>
          <FormattedMessage
            id={notification.message}
            defaultMessage="Succeeded"
            description="Message for successful status received from back-end"
            values={{
              restrictions_ingested:
                notification.details?.intl_values?.restrictions_ingested,
              csv_file_name: notification.details?.intl_values?.csv_file_name,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
export default SuccessToast;

import {
  ProductSearchAttributes,
  SearchProductsWithPromotionExclusionsByProductAttributesCommand
} from "api/ms-promotion";
import PageLayout from "components/layout/PageLayout";
import { useContinuationApi } from "hooks/api";
import { useEffect, useState } from "react";
// import { useIntl } from "components/translation/formatMessage";
import { FormattedMessage, useIntl } from "react-intl";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import FindProductsDrawer from "views/Restrictions/FindProducts/FindProductsDrawer";
import RestrictionsDownloadWithFilePath from "views/Restrictions/RestrictionsDownloadModal/RestrictionsDownloadWithFilePath";
import RestrictionsList from "views/Restrictions/RestrictionsList/RestrictionsList";
import RestrictionsSearches from "views/Restrictions/RestrictionsSearches/RestrictionsSearches";
import RestrictionsUploadModal from "views/Restrictions/RestrictionsUploadModal/RestrictionsUploadModal";
import RestrictionsUploadWithFilePath from "views/Restrictions/RestrictionsUploadModal/RestrictionsUploadWithFilePath";

import { FileUpload, Flag } from "@mui/icons-material";
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Switch
} from "@mui/material";

import RestrictionsCheckboxList from "../RestrictionsList/RestrictionsCheckboxList";
import RestrictionsListing from "../RestrictionsList/RestrictionsListing";

function RestrictionsPage() {
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);
  const [uploadWithFilePathVisible, setUploadWithFilePathVisible] =
    useState<boolean>(false);
  const [downloadWithFilePathVisible, setDownloadWithFilePathVisible] =
    useState<boolean>(false);
  const [searchVisible, setSearchVisible] = useState<boolean>(true);
  const [productDrawerVisible, setProductDrawerVisible] =
    useState<boolean>(false);

  const [newSearch, setNewSearch] = useState<boolean>(true);

  const [productCode, setProductCode] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [genders, setGenders] = useState<string[]>([]);
  const [seasons, setSeasons] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [restrictionFilter, setRestrictionFilter] = useState<any>({});
  const { pathname } = useLocation();
  const [isRestrictionsListVisible, setIsRestrictionsListVisible] =
    useState<boolean>(false);

  const ALL = "All";
  const getAttribute = (para: string[]) => {
    return para.includes(ALL) ? [] : para;
  };

  const productSearchAttributes = {
    brands: !brand ? [] : [brand],
    genders: getAttribute(genders),
    season_codes: getAttribute(seasons),
    departments: getAttribute(departments),
    categories: getAttribute(categories),
    subcategories: getAttribute(subCategories),
    product_code: productCode,
    exclusion_flag_attributes: restrictionFilter,
  } as ProductSearchAttributes;

  const getRestrictionsResponse = useContinuationApi(
    (api, continuationToken) =>
      api.searchProductsWithPromotionExclusionsByProductAttributes({
        product_search_attributes: productSearchAttributes,
        promotions: [],
        continuation_token: continuationToken,
      } as SearchProductsWithPromotionExclusionsByProductAttributesCommand),
    undefined,
    { manual: true }
  );

  const productsPromotions = getRestrictionsResponse.data || [];
  const selectedRestrictions: Set<string> = new Set();
  const productsList: Set<string> = new Set();
  productsPromotions.forEach((row: any, index: number) => {
    productsList.add(row.product_code);
    Object.keys(row.promotion_restrictions_by_promotion_code!).forEach(
      (key) => [
        Object.keys(row.promotion_restrictions_by_promotion_code![key]).forEach(
          (currency) => {
            selectedRestrictions.add(
              row.product_code + "-" + key + "-" + currency + "-true"
            );
          }
        ),
      ]
    );
  });

  let navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (matchPath("/promotion-restrictions/upload/*", pathname)) {
      setUploadModalVisible(true);
    }
    if (matchPath("/promotion-restrictions/upload-with-file-path", pathname)) {
      setUploadWithFilePathVisible(true);
    }
    if (
      matchPath("/promotion-restrictions/download-with-file-path", pathname)
    ) {
      setDownloadWithFilePathVisible(true);
    }
    if (getRestrictionsResponse.data) {
      setSearchVisible(false);
    }
  }, [getRestrictionsResponse.data, pathname]);

  const handleFindProductDrawerClose = () => {
    setProductDrawerVisible(false);
  };

  const handleClickUploadModalOpen = () => {
    navigate("/promotion-restrictions/upload");
    setUploadModalVisible(true);
  };
  const handleClickFindProductDrawerOpen = () => {
    setProductDrawerVisible(true);
    setIsRestrictionsListVisible(false);
  };
  const handleUploadModalClose = () => {
    navigate("/promotion-restrictions");
    setUploadModalVisible(false);
    setUploadWithFilePathVisible(false);
  };
  const handleDownloadModalClose = () => {
    navigate("/promotion-restrictions");
    setDownloadWithFilePathVisible(false);
  };

  const handleSearchVisible = () => {
    setSearchVisible((prev) => !prev);
  };

  return (
    <PageLayout
      title={intl.formatMessage({
        id: "restrictions.title",
        defaultMessage: "Set Product Exclusion Flag",
        description: "Title of Restrictions Page",
      })}
      surtitle={intl.formatMessage({
        id: "restrictions.surtitle",
        defaultMessage: "Product Restrictions",
        description: "Surtitle of Restrictions Page",
      })}
      icon={<Flag sx={{ mr: 1 }} />}
    >
      <Grid container rowSpacing={2}>
        <Grid item container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={handleClickUploadModalOpen}
              variant="contained"
              startIcon={<FileUpload />}
            >
              <FormattedMessage
                id="restrictions.upload.button"
                defaultMessage="Upload CSV"
                description="Upload CSV text button"
              />
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClickFindProductDrawerOpen}
              variant="contained"
            >
              <FormattedMessage
                id="restrictions.find.button"
                defaultMessage="Find product"
                description="Find product text button"
              />
            </Button>
          </Grid>

          {process.env.REACT_APP_IS_USER_PREFERENCES_ENABLED === "true" && (
            <Grid item xs={12}>
              {getRestrictionsResponse.data && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={searchVisible}
                      onChange={handleSearchVisible}
                    />
                  }
                  label={intl.formatMessage({
                    id: "restrictions.searches_toggle.button",
                    defaultMessage: "Display searches",
                    description: "Display search text button",
                  })}
                />
              )}
              <Collapse in={searchVisible}>
                <RestrictionsSearches
                  onSetProductDrawerVisible={setProductDrawerVisible}
                />
              </Collapse>
            </Grid>
          )}

          {process.env.REACT_APP_IS_NEW_RESTRICTION_LISTING_ENABLED ===
          "true" ? (
            <Grid item xs={12}>
              <RestrictionsListing
                newSearch={newSearch}
                onSetNewSearch={setNewSearch}
                getRestrictionsResponse={getRestrictionsResponse}
                productSearchAttributesForDownloadButton={
                  productSearchAttributes
                }
              />
            </Grid>
          ) : process.env.REACT_APP_IS_SET_RESTRICTIONS_ENABLED === "true" &&
            productsList.size > 0 ? (
            isRestrictionsListVisible && (
              <Grid item xs={12}>
                <RestrictionsCheckboxList
                  getRestrictionsResponse={getRestrictionsResponse}
                  productSearchAttributesForDownloadButton={
                    productSearchAttributes
                  }
                  selectedRestrictions={selectedRestrictions}
                  productsList={productsList}
                />
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <RestrictionsList
                getRestrictionsResponse={getRestrictionsResponse}
                productSearchAttributesForDownloadButton={
                  productSearchAttributes
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <RestrictionsUploadModal
        open={uploadModalVisible}
        onClose={handleUploadModalClose}
      />
      <RestrictionsUploadWithFilePath
        open={uploadWithFilePathVisible}
        onClose={handleUploadModalClose}
      />
      <RestrictionsDownloadWithFilePath
        open={downloadWithFilePathVisible}
        onClose={handleDownloadModalClose}
      />
      <FindProductsDrawer
        drawerVisible={productDrawerVisible}
        onSetNewSearch={setNewSearch}
        onClose={handleFindProductDrawerClose}
        onSetDrawerVisible={setProductDrawerVisible}
        onSetProductCode={setProductCode}
        onSetBrand={setBrand}
        onSetGenders={setGenders}
        onSetSeasons={setSeasons}
        onSetDepartments={setDepartments}
        onSetCategories={setCategories}
        onSetSubcategories={setSubCategories}
        onSetRestrictionFilter={setRestrictionFilter}
        onGetRestrictionResponse={getRestrictionsResponse.fetchOnManual}
        onSetRestrictionsListVisible={setIsRestrictionsListVisible}
      />
    </PageLayout>
  );
}

export default RestrictionsPage;

import React, { useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useNotification } from '../../context/notification/Notification';

const Notification = () => {
  const { notification, setNotification } = useNotification();

  useEffect(() => {
    const storedNotificationString = localStorage.getItem('notification');
    if (storedNotificationString) {
      const storedNotification = JSON.parse(storedNotificationString);
      setNotification(storedNotification);
      localStorage.removeItem('notification'); // Remove after showing
    }
  }, [setNotification]);

  const handleClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={3500}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiAlert-colorSuccess': {
          backgroundColor: '#2f7d31',
        },
        '& .MuiAlert-colorError': {
          backgroundColor: '#d3302f',
        },
      }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.severity}
        sx={{
            '& .MuiAlert-icon, & .MuiAlert-message': {
              color: '#fff',
            },
        }}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;

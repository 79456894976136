import { useUserPreferences } from "context/preferences/UserPreferences";
import { useMemo } from "react";

import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          background: {
            default: "#e5e5e5",
            paper: "#ffffff",
          },
          secondary: {
            main: "#000000",
          },
          neutral: {
            main: "#ffffff",
          },
        }
      : {
          secondary: {
            main: "#000",
          },
          neutral: {
            main: "#1f1f1f",
          },
        }),
  },
  typography: {
    fontSize: 14,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: "unset",
        },
      },
    },
  },
});

type AppThemeProviderProps = {
  children: React.ReactNode;
};

function AppThemeProvider(props: AppThemeProviderProps) {
  const { children } = props;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const userPreferences = useUserPreferences();

  let colorMode = userPreferences.colorMode.mode;

  if (colorMode === "system") {
    colorMode = prefersDarkMode ? "dark" : "light";
  }

  const theme = useMemo(
    () => createTheme(getDesignTokens(colorMode as PaletteMode)),
    [colorMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;

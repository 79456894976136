import { GeneratePresignedPostResult } from "api/ms-promotion";
import axios, { AxiosRequestTransformer } from "axios";

const getAxiosForExternalCalls = () => {
  const transformers: AxiosRequestTransformer[] = [
    (data: any, headers: any) => {
      // delete the Authorization header to not expose JWTs to AWS
      delete headers.common.Authorization;
      return data;
    },
  ];

  if (axios.defaults.transformRequest !== undefined) {
    // Keep any other transformer that might have set before
    if (Array.isArray(axios.defaults.transformRequest)) {
      transformers.push(...axios.defaults.transformRequest);
    } else {
      transformers.push(axios.defaults.transformRequest);
    }
  }
  return axios.create({
    transformRequest: transformers,
    timeout: 30000, // 30 seconds
  });
};

async function uploadFileToS3(
  presignedPostResponse: GeneratePresignedPostResult,
  file: any
): Promise<boolean> {
  const formData = new FormData();
  const presignedPostFields = presignedPostResponse.form_data_fields as {
    [key: string]: string;
  };

  Object.keys(presignedPostFields).forEach((key) => {
    formData.append(key, presignedPostFields[key]);
  });
  formData.append("file", file);

  const axiosAws = getAxiosForExternalCalls();

  let url = presignedPostResponse.presigned_post_url;

  if (process.env.REACT_APP_ENV === "dev") {
    url = url?.replace(
      "http://localstack:4566",
      process.env.REACT_APP_LOCALSTACK_ENDPOINT || ""
    );
  }

  return await axiosAws
    .post(url as string, formData)
    .then((response) => {
      if (!response || !response.status?.toString().startsWith("2")) {
        console.warn(response);
        return false;
      }
      return true;
    })
    .catch((e) => {
      console.error(e.name + "," + e.message);
      return false;
    });
}

export { uploadFileToS3 };

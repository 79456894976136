import { GeneratePresignedPostResult } from "api/ms-promotion";
import { useApi } from "hooks/api";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { uploadFileToS3 } from "services/s3/uploadFileToS3";

import { Clear, Close, Delete, FileUpload } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";

export interface RestrictionsUploadModalProps {
  open: boolean;
  onClose: () => void;
}

function RestrictionsUploadWithFilePath(props: RestrictionsUploadModalProps) {
  const { onClose, open } = props;

  const [presignedPostUrl, setPresignedPostUrl] = useState<string>("");
  const [isPresignedUrlPending, setIsPresignedUrlPending] =
    useState<boolean>(false);

  const [fileToUpload, setFileToUpload] = useState<any>();
  const [filePath, setFilePath] = useState<string>("");
  const [isUploadPending, setIsUploadPending] = useState<boolean>(false);
  const clearFilePathChange = () => {
    setFilePath("");
  };

  const intl = useIntl();

  const handleSelectFileButton = (event: EventAsTarget<HTMLInputElement>) => {
    setFileToUpload(event.target.files![0]);
    generatePresignedPostResponse.fetch();
    setIsPresignedUrlPending(true);
    event.target.value = "";
  };

  const clearFileInput = () => {
    setFileToUpload(undefined);
    setPresignedPostUrl("");
  };
  const handleClose = () => {
    onClose();
  };
  interface EventAsTarget<T = EventTarget> {
    target: T;
  }
  const generatePresignedPostResponse = useApi(
    (api) =>
      api.generatePresignedPost({
        key_name: filePath,
      }),
    undefined,
    { manual: true }
  );
  useEffect(() => {
    setPresignedPostUrl(
      generatePresignedPostResponse.data?.presigned_post_url || ""
    );
    setIsPresignedUrlPending(false);
  }, [generatePresignedPostResponse.data]);

  const handleSubmitButton = async () => {
    setIsUploadPending(true);
    const uploadStatus = await uploadFileToS3(
      generatePresignedPostResponse.data as GeneratePresignedPostResult,
      fileToUpload
    );
    setIsUploadPending(false);

    if (uploadStatus) {
      clearFileInput();
      handleClose();
      return;
    }
    toast.error(() => (
      <FormattedMessage
        id="notification.error.modal_upload"
        defaultMessage="File {file_name} was not uploaded."
        description="Upload CSV file modal error"
        values={{
          file_name: fileToUpload.name,
        }}
      />
    ));
  };
  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage
          id="restrictions.upload.modal_title_path"
          defaultMessage="Upload CSV File to Target S3 Path"
          description="Upload CSV file s3 target modal title"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container rowSpacing={2} textAlign="left">
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage
                id="restrictions.upload.modal_step1_path"
                defaultMessage="Step 1: Enter the Target File Path (Key Name) in Bucket"
                description="Upload CSV file s3 step 1 target modal"
              />
            </Typography>
          </Grid>
          <Grid item xs={15}>
            <FormControl fullWidth>
              <TextField
                id="file-path"
                label={intl.formatMessage({
                  id: "restrictions.upload.modal_path_input_label",
                  defaultMessage: "File path",
                  description: "Upload CSV file s3 input target modal",
                })}
                onChange={(event: any) => {
                  setFilePath(event.target.value);
                }}
                value={filePath}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={clearFilePathChange}>
                      <Clear />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          {filePath && (
            <Grid item xs={15}>
              <Typography variant="h6">
                <FormattedMessage
                  id="restrictions.upload.modal_step2_path"
                  defaultMessage="Step 2: Upload the File"
                  description="Upload CSV file s3 step 2 target modal"
                />
              </Typography>
              <Button variant="contained" component="label">
                <FormattedMessage
                  id="restrictions.upload.modal_browse"
                  defaultMessage="Browse file"
                  description="Upload CSV file modal browse button"
                />
                <input
                  onChange={handleSelectFileButton}
                  hidden
                  accept=".jsonl,.csv"
                  type="file"
                />
              </Button>
            </Grid>
          )}
          {fileToUpload && (
            <>
              <Grid item xs={11}>
                <Chip
                  sx={{ marginTop: "2px" }}
                  icon={<FileUpload />}
                  label={fileToUpload.name}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={clearFileInput}
                  aria-label="delete file"
                  component="label"
                >
                  <Delete />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            id="restrictions.upload.modal_cancel"
            defaultMessage="Cancel"
            description="Upload CSV file modal cancel button"
          />
        </Button>
        <LoadingButton
          loading={isPresignedUrlPending}
          variant="contained"
          disabled={presignedPostUrl === "" || isUploadPending}
          onClick={() => handleSubmitButton()}
          component="label"
        >
          <FormattedMessage
            id="restrictions.upload.modal_submit"
            defaultMessage="Submit"
            description="Upload CSV file modal submit button"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default RestrictionsUploadWithFilePath;

import ColorMode from "components/preferences/modules/ColorMode";
import Notifications from "components/preferences/modules/Notifications";
import Searches from "components/preferences/modules/Searches";
import TimeZone from "components/preferences/modules/TimeZone";

import { Box, Stack } from "@mui/material";

function PreferencesDashboard() {
  return (
    <Box sx={{ pl: 2, pr: 2 }}>
      <Stack spacing={2}>
        <Searches />
        <Notifications />
        <TimeZone />
        <ColorMode />
      </Stack>
    </Box>
  );
}

export default PreferencesDashboard;

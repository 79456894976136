import * as React from 'react';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Box, BoxProps, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getSaleEvents } from "../../../utils/api/apiTools";
import { useEffect, useState } from "react";


const SaleEventsContainer = styled('div')(({ theme }) => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '95%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '20px',
}));

const HeaderSection = styled('div')(({ theme }) => ({
  height: '72px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

const Title = styled("h2")({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
  marginTop: 0,
});

const StyledButton = styled(Button)({
  float: 'right',
  backgroundColor: '#1976D2',
  color: 'white',
  width: '134px',
  height: '36px',
});

interface PillProps extends BoxProps {
  status: string;
}

const Pill = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<PillProps>(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px 4px',
  borderRadius: '16px',
  width: status === 'Active' ? '49px' : status === 'Draft' ? '42px' : '49px',
  height: '18px',
  backgroundColor: status === 'Active' ? '#2E7D32' : status === 'Draft' ? '#EBEBEB' : '#EBEBEB',
  color: status === 'Active' ? '#fff' : status === 'Draft' ? '#000000' : '#000000',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  letterSpacing: '0.16px',
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  backgroundColor: 'white',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    color: '#757575',
    maxHeight: '32px',
    minHeight: '32px',
    height: '32px',
    lineHeight: '32px',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-menuIcon, & .MuiDataGrid-withBorderColor, & .MuiDataGrid-columnHeaderRow': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-columnHeader': {
    padding: '0px',
    maxHeight: '32px',
    minHeight: '32px',
    height: '32px',
    lineHeight: '32px',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    paddingRight: '32px',
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainerContent': {
    paddingRight: '0px',
  },
  '& .MuiDataGrid-cell': {
    padding: '0px',
    paddingRight: '32px',
    borderBottom: 'none',
  },
  '& .MuiDataGrid-cellCheckbox': {
    paddingRight: '0px',
  },
  '& .MuiDataGrid-row': {
    '--rowBorderColor': 'none',
  },
}));

type SaleEvent = {
  id: string;
  name: string;
  created_at: string;
  start_date: string;
  end_date: string;
  updated_at: string;
  type: string;
  simulations: string; // missing
  products: string; // missing
  cog: string; // missing
  status: string; // missing
}
export default function SaleEventsPage() {
  const intl = useIntl();
  const [saleEvents, setSaleEvents] = useState<SaleEvent[]>([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);

  useEffect(() => {
    const fetchSaleEventsData = async () => {
      const response = await getSaleEvents(pagination.page + 1, pagination.pageSize);

      setSaleEvents(response.results);
      setTotalRowCount(response.pagination.total_count);
    }
    fetchSaleEventsData();
  }, [pagination]);


  function formatDateRange(startDate: string, endDate: string) {
    const userLanguage = intl.locale;
    const formatDate = (isoString: string) => {
      const date = new Date(isoString);
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric'
      };
      return date.toLocaleDateString(`${userLanguage}-CA`, options);
    };

    const startFormatted = formatDate(startDate);
    const endFormatted = formatDate(endDate);
    const preposition = userLanguage === 'fr' ? 'au' : 'to';

    // e.g. Apr 1 to Apr 27
    return `${startFormatted} ${preposition} ${endFormatted}`;
  }

  const rows = saleEvents.map((data, index) => ({
    id: index,
    event: data.name,
    dateRange: formatDateRange(data.start_date, data.end_date),
    type: data.type,
    simulations: data.simulations, // missing
    products: data.products,  // missing
    cog: data.cog, // missing
    status: data.status  // missing
  }))

  const columns: GridColDef[] = [
    {
      field: 'event',
      headerName: intl.formatMessage({id: "sale_events.column_title.event_name"}),
      headerAlign: 'left',
      align: 'left',
      flex: 3
    },
    {
      field: 'dateRange',
      headerName: intl.formatMessage({id: "sale_events.column_title.date_range"}),
      headerAlign: 'right',
      align: 'right',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5
    },
    {
      field: 'simulations',
      headerName: 'Simulations',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      flex: 0.5
    },
    {
      field: 'products',
      headerName: intl.formatMessage({id: "sale_events.column_title.products"}),
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      description: 'This column has a value getter and is not sortable.',
      flex: 0.5
    },
    {
      field: 'cog',
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      headerName: 'COG',
      flex: 0.5
    },
    {
      field: 'status',
      headerName: intl.formatMessage({id: "sale_events.column_title.status"}),
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      renderCell: (params: any) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Pill status={params.value}>
            {params.value}
          </Pill>
        </Box>
      )
    }]

  const navigate = useNavigate();
  const createEvent = () => {
    navigate('/sale-events/create');
  };

  const handlePageChange = (model: GridPaginationModel) => {
    setPagination({
      ...pagination,
      page: model.page,
      pageSize: model.pageSize
    });
  };

  return (
    <SaleEventsContainer>
      <HeaderSection>
        <Title> { intl.formatMessage({ id: "sale_events.title" })} </Title>
        <StyledButton onClick={createEvent}> { intl.formatMessage({ id: "sale_events.create_event.title" }) } </StyledButton>
      </HeaderSection>

      <StyledDataGrid
        rows={rows}
        columns={columns}
        autoHeight
        rowCount={totalRowCount}
        paginationMode="server"
        paginationModel={{
          page: pagination.page,
          pageSize: pagination.pageSize
        }}
        pageSizeOptions={[5, 10, 50]}
        onPaginationModelChange={handlePageChange}
        checkboxSelection
        rowHeight={32}
      />
    </SaleEventsContainer>
  );
}

import "moment-timezone";

import {
  ProductPromotionRestriction,
  ProductSearchAttributes,
  Promotion,
  PromotionRestrictionUserSelectionExclusionRawInput,
  SearchProductsWithPromotionExclusionsByProductAttributesResultItem
} from "api/ms-promotion/api";
import ApiResponse from "components/api/ApiResponse";
import { useUserPreferences } from "context/preferences/UserPreferences";
import { fetchApi, useApi } from "hooks/api";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Moment from "react-moment";
import { toast } from "react-toastify";
import ContinuationWaypoint from "utils/waypoint/ContinuationWaypoint";
import RestrictionsDownloadButton from "views/Restrictions/RestrictionsDownloadButton/RestrictionsDownloadButton";
import RestrictionsModalConfirmation from "views/Restrictions/RestrictionsList/RestrictionsModalConfirmation";

import { VerticalAlignTop } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger
} from "@mui/material";

import { PLACE_HOLDER_IMG, ProductTable } from "./RestrictionsReference";
import {
  StyledHeaderTableRow,
  StyledPaper,
  StyledTableContainer
} from "./RestrictionsStyled";

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    neutral: true;
  }
}

export interface RestrictionsListingProps {
  newSearch: boolean;
  onSetNewSearch: Function;
  getRestrictionsResponse: any;
  productSearchAttributesForDownloadButton: ProductSearchAttributes;
}

// Helper to deep copy an element
const deepCopy = (toCopy: any) => JSON.parse(JSON.stringify(toCopy));

function RestrictionsListing(props: RestrictionsListingProps) {
  const [restrictionsInitial, setRestrictionsInitial] =
    useState<any>(undefined);
  const [restrictionsUpdated, setRestrictionsUpdated] =
    useState<any>(undefined);
  const [restrictionsToSend, setRestrictionsToSend] = useState<any>([]);

  // Modal confirmation states
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const [columnSelectedChecked, setColumnSelectedChecked] =
    useState<boolean>(false);
  const [columnSelectedPromotionCode, setColumnSelectedPromotionCode] =
    useState<string>("");
  const [columnSelectedExclusionCode, setColumnSelectedExclusionCode] =
    useState<string>("");
  const [columnSelectedSelectionNumber, setColumnSelectedSelectionNumber] =
    useState<number>(0);

  const userPreferences = useUserPreferences();
  const intl = useIntl();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const getReferenceData: any = useApi((api: any) =>
    api.getPromotionReferenceData()
  );

  const currencies: string[] = getReferenceData.data?.list_promotion_currencies;

  const promotions: Promotion[] = useMemo(
    () => getReferenceData.data?.list_promotions,
    [getReferenceData.data?.list_promotions]
  );

  // Selection confirmation modal logic
  const handleModalConfirmationClose = () => {
    setConfirmationModalVisible(false);
  };

  const validateColumnSelection = (checked: boolean, promotionCode: string) => {
    const restrictionsUpdatedCopy: SearchProductsWithPromotionExclusionsByProductAttributesResultItem[] =
      deepCopy(restrictionsUpdated.data);

    restrictionsUpdatedCopy.map((product) =>
      Object.values(
        product.promotion_restrictions_by_promotion_code[promotionCode]
      ).map((restriction: any) => (restriction.value = checked))
    );

    setRestrictionsUpdated((prevState: any) => ({
      ...prevState,
      data: restrictionsUpdatedCopy,
    }));
  };

  const validatePageSelection = (checked: boolean) => {
    const restrictionsUpdatedCopy: SearchProductsWithPromotionExclusionsByProductAttributesResultItem[] =
      deepCopy(restrictionsUpdated.data);

    restrictionsUpdatedCopy.forEach((product) =>
      Object.values(product.promotion_restrictions_by_promotion_code).map(
        (restrictions: any) =>
          Object.values(restrictions).forEach((restriction: any) => {
            restriction.value = checked;
          })
      )
    );

    setRestrictionsUpdated((prevState: any) => ({
      ...prevState,
      data: restrictionsUpdatedCopy,
    }));
  };

  const handleRestrictionCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    productCode: string,
    promotionCode: string,
    currencyCode: string
  ) => {
    const productIndex: number = restrictionsUpdated.data.findIndex(
      (
        element: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => element.product_code === productCode
    );

    const newRestrictionsUpdated = deepCopy(restrictionsUpdated);

    newRestrictionsUpdated.data[
      productIndex
    ].promotion_restrictions_by_promotion_code[promotionCode][
      currencyCode
    ].value = event.target.checked;

    setRestrictionsUpdated(newRestrictionsUpdated);
  };

  const getRestrictionsDifferences = (
    restrictionsLeft: any,
    restrictionsRight: any
  ) => {
    const differences: any[] = [];

    Object.values(restrictionsLeft).map((restrictionLeft: any, index: number) =>
      Object.entries(restrictionLeft).forEach((exclusion: any) => {
        if (exclusion[0] === "value") {
          if (
            exclusion[1] !==
            (Object.values(restrictionsRight)[index] as any).value
          ) {
            if (
              !differences.includes(Object.entries(restrictionsLeft)[index])
            ) {
              differences.push(Object.entries(restrictionsLeft)[index]);
            }
          }
        }
      })
    );

    return differences;
  };

  const getDifferences = (left: any, right: any) => {
    const differences: SearchProductsWithPromotionExclusionsByProductAttributesResultItem[] =
      [];

    left.map(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem,
        index: number
      ) =>
        Object.entries(product.promotion_restrictions_by_promotion_code).map(
          (restrictions: any) =>
            Object.entries(restrictions[1]).forEach((restriction: any) => {
              if (
                restriction[1].value !==
                right[index].promotion_restrictions_by_promotion_code[
                  restrictions[0]
                ][restriction[0]].value
              ) {
                if (!differences.includes(product)) {
                  differences.push(product);
                }
              }
            })
        )
    );

    return differences;
  };

  useEffect(() => {
    if (props.getRestrictionsResponse.data !== undefined) {
      const normalize = (backEndResponse: any) => {
        // Setting all non existing exclusion flags to { flag : false }
        const backEndResponseCopy = deepCopy(backEndResponse);

        const backEndResponseCopyNormalizedData = backEndResponseCopy.data.map(
          (row: any) => {
            Object.keys(row.promotion_restrictions_by_promotion_code).map(
              (restrictions: string) =>
                currencies?.forEach((restriction) => {
                  if (
                    !row.promotion_restrictions_by_promotion_code[
                      restrictions
                    ].hasOwnProperty(restriction)
                  ) {
                    row.promotion_restrictions_by_promotion_code[restrictions][
                      restriction
                    ] = {};

                    row.promotion_restrictions_by_promotion_code[restrictions][
                      restriction
                    ]["value"] = false;
                  }
                })
            );
            return row;
          }
        );

        backEndResponseCopy.data = backEndResponseCopyNormalizedData;

        return backEndResponseCopy;
      };

      if (props.newSearch) {
        setRestrictionsInitial(normalize(props.getRestrictionsResponse));

        setRestrictionsUpdated(
          deepCopy(normalize(props.getRestrictionsResponse))
        );
        props.onSetNewSearch(false);
      } else {
        const responseCopyNewState = [
          ...restrictionsInitial.data,
          ...normalize(props.getRestrictionsResponse).data.slice(
            restrictionsInitial.data.length
          ),
        ];

        setRestrictionsInitial((prevState: any) => ({
          ...prevState,
          data: responseCopyNewState,
        }));

        const newState = [
          ...restrictionsUpdated.data,
          ...deepCopy(
            normalize(props.getRestrictionsResponse).data.slice(
              restrictionsUpdated.data.length
            )
          ),
        ];

        setRestrictionsUpdated((prevState: any) => ({
          ...prevState,
          data: newState,
        }));
      }
    } // eslint-disable-next-line
  }, [props.getRestrictionsResponse.data]);

  useEffect(() => {
    if (restrictionsUpdated?.data && restrictionsInitial?.data) {
      const convertToBackEndFormat = (toConvert: any) => {
        const arr: any[] = [];
        toConvert.forEach((currencyExclusion: any) => {
          arr.push({
            code: currencyExclusion[0],
            exclusion: currencyExclusion[1].value,
          });
        });
        return arr;
      };

      const differences = getDifferences(
        restrictionsUpdated.data,
        restrictionsInitial.data
      );

      const toSend: PromotionRestrictionUserSelectionExclusionRawInput[] =
        differences.map((currentProduct) => {
          const restrictions = {
            productCode: currentProduct.product_code,
            restrictions: [] as ProductPromotionRestriction[],
          };

          const productIndex = restrictionsInitial.data.findIndex(
            (
              product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
            ) => product.product_code === currentProduct.product_code
          );

          restrictions.restrictions = promotions
            ?.map((promotion) => {
              const updatedValues = getRestrictionsDifferences(
                currentProduct.promotion_restrictions_by_promotion_code[
                  promotion.code
                ],
                restrictionsInitial.data[productIndex]
                  .promotion_restrictions_by_promotion_code[promotion.code]
              );

              return {
                promotionCode: promotion.code,
                currenciesRestriction: convertToBackEndFormat(updatedValues),
              };
            })
            .filter((item) => item.currenciesRestriction.length);

          return restrictions;
        });

      setRestrictionsToSend(toSend);
    }
  }, [restrictionsUpdated, restrictionsInitial, promotions]);

  // Individual checkbox logic
  const getRestrictionCheckedValue = (
    products: [],
    productCode: string,
    promotionCode: string,
    currencyCode: string
  ) => {
    const product: any = products?.find(
      (
        element: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => element.product_code === productCode
    );

    return (
      product?.promotion_restrictions_by_promotion_code[promotionCode][
        currencyCode
      ].value || false
    );
  };

  const isRestrictionChecked = (
    productCode: string,
    exclusionCode: string,
    currencyCode: string
  ) => {
    const checkedInRestrictionsUpdated = getRestrictionCheckedValue(
      restrictionsUpdated?.data,
      productCode,
      exclusionCode,
      currencyCode
    );

    return checkedInRestrictionsUpdated || false;
  };

  const handleSaveRestrictions = () => {
    fetchApi((api) => {
      api
        .setPromotionProductExclusions({
          product_exclusions_to_set: restrictionsToSend,
        })
        .then((response) => {
          if (!response || !response.status?.toString().startsWith("2")) {
            console.error(response);
            // Error response from backend
            toast.error(
              intl.formatMessage({
                id: "notification.error.saving_restriction_failed",
                defaultMessage:
                  "Saving restrictions failed. Retry or contact Stash team.",
                description: "Error message for restriction save failure.",
              })
            );
          }
          setRestrictionsInitial(deepCopy(restrictionsUpdated));
        })
        .catch((error) => {
          console.error(error.name + "," + error.message);
          // Communication problem with backend
          toast.error(
            intl.formatMessage({
              id: "notification.error.saving_restriction_failed",
              defaultMessage:
                "Saving restrictions failed. Retry or contact Stash team.",
              description: "Error message for restriction save failure.",
            })
          );
        });
    });
  };

  // Rows checkboxes logic
  const isRowFullyChecked = (productCode: string) => {
    const product = restrictionsUpdated?.data.find(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => product.product_code === productCode
    );

    let isTrue = false;

    if (product) {
      isTrue = Object.values(
        product.promotion_restrictions_by_promotion_code
      ).every((restrictions: any) =>
        Object.values(restrictions).every(
          (restriction: any) => restriction.value === true
        )
      );
    }

    return isTrue;
  };

  const isRowPartiallyChecked = (productCode: string) => {
    const product = restrictionsUpdated?.data.find(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => product.product_code === productCode
    );

    let oneChecked = false;
    let counter = 0;
    const totalRestrictions = currencies?.length * promotions?.length;

    if (product) {
      Object.values(product.promotion_restrictions_by_promotion_code).forEach(
        (restrictions: any) =>
          Object.values(restrictions).forEach((restriction: any) => {
            if (restriction.value === true) {
              oneChecked = true;
              counter++;
            }
          })
      );
    }
    return oneChecked && counter < totalRestrictions;
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    productCode: string
  ) => {
    const productIndex = restrictionsUpdated.data.findIndex(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => product.product_code === productCode
    );

    const product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem =
      deepCopy(restrictionsUpdated.data[productIndex]);

    if (event.target.checked) {
      Object.values(product.promotion_restrictions_by_promotion_code).map(
        (restrictions: any) =>
          Object.values(restrictions).map(
            (restriction: any) => (restriction.value = true)
          )
      );
    } else {
      Object.values(product.promotion_restrictions_by_promotion_code).map(
        (restrictions: any) =>
          Object.values(restrictions).map(
            (restriction: any) => (restriction.value = false)
          )
      );
    }
    const newRestrictionsUpdatedData = [
      ...restrictionsUpdated.data.slice(0, productIndex),
      product,
      ...restrictionsUpdated.data.slice(productIndex + 1),
    ];

    setRestrictionsUpdated((prevState: any) => ({
      ...prevState,
      data: newRestrictionsUpdatedData,
    }));
  };

  // Columns checkboxes logic
  const isColumnFullyChecked = (exclusionCode: string) => {
    let isTrue = restrictionsUpdated?.data.every(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) =>
        Object.values(
          product.promotion_restrictions_by_promotion_code[exclusionCode]
        ).every((restriction: any) => restriction.value === true)
    );

    return isTrue || false;
  };

  const isColumnPartiallyChecked = (exclusionCode: string) => {
    let oneChecked = false;
    let counter = 0;

    const totalRestrictions =
      currencies?.length * restrictionsUpdated?.data.length;

    restrictionsUpdated?.data.forEach(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) => {
        Object.values(
          product.promotion_restrictions_by_promotion_code[exclusionCode]
        ).forEach((restriction: any) => {
          if (restriction.value === true) {
            oneChecked = true;
            counter++;
          }
        });
      }
    );

    return oneChecked && counter < totalRestrictions;
  };

  const handleColumnCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    promotionCode: string,
    exclusionCode: string
  ) => {
    if (
      restrictionsUpdated?.data.length <
      props.getRestrictionsResponse?.totalCount
    ) {
      setColumnSelectedChecked(event.target.checked);
      setColumnSelectedPromotionCode(promotionCode);
      setColumnSelectedSelectionNumber(restrictionsUpdated.data.length);
      setColumnSelectedExclusionCode(exclusionCode);
      setConfirmationModalVisible(true);
    } else {
      validateColumnSelection(event.target.checked, promotionCode);
    }
  };

  const handleClearAllClick = () => {
    setRestrictionsUpdated(deepCopy(restrictionsInitial));
  };

  // Page checkbox logic
  const isPageFullyChecked = () => {
    let isTrue = restrictionsUpdated?.data.every(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) =>
        Object.values(product.promotion_restrictions_by_promotion_code).every(
          (promotions: any) =>
            Object.values(promotions).every(
              (restriction: any) => restriction.value === true
            )
        )
    );
    return isTrue || false;
  };

  const isPagePartiallyChecked = () => {
    let oneChecked = false;
    let counter = 0;

    const totalRestrictions =
      currencies?.length *
      promotions?.length *
      restrictionsUpdated?.data.length;

    restrictionsUpdated?.data.forEach(
      (
        product: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
      ) =>
        Object.values(product.promotion_restrictions_by_promotion_code).forEach(
          (promotions: any) =>
            Object.values(promotions).forEach((restriction: any) => {
              if (restriction.value === true) {
                oneChecked = true;
                counter++;
              }
            })
        )
    );
    return oneChecked && counter < totalRestrictions;
  };

  const handlePageCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      restrictionsUpdated?.data.length <
      props.getRestrictionsResponse?.totalCount
    ) {
      setColumnSelectedChecked(event.target.checked);
      setColumnSelectedSelectionNumber(restrictionsUpdated.data.length);
      setColumnSelectedExclusionCode("all_exclusions");
      setConfirmationModalVisible(true);
    } else {
      validatePageSelection(event.target.checked);
    }
  };

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getCurrencyCheckboxes = (
    productCode: string,
    promotionCode: string
  ) => (
    <>
      {currencies.map((currencyCode) => (
        <Stack key={currencyCode}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleRestrictionCheckboxChange(
                    e,
                    productCode,
                    promotionCode,
                    currencyCode
                  )
                }
                checked={isRestrictionChecked(
                  productCode,
                  promotionCode,
                  currencyCode
                )}
              />
            }
            label={currencyCode}
          />
        </Stack>
      ))}
    </>
  );

  return (
    <ApiResponse response={props.getRestrictionsResponse}>
      {props.getRestrictionsResponse.data?.length === 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item>
            <Typography variant={"h4"}>
              <FormattedMessage
                id="restrictions.no-result"
                defaultMessage="Empty"
                description="No result message"
              />
            </Typography>
            <Typography variant={"h5"}>
              <FormattedMessage
                id="restrictions.no-result.details"
                defaultMessage="No results were found, please try again"
                description="No result message details"
              />
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <StyledPaper>
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item justifyContent="flex-end">
              <RestrictionsDownloadButton
                productSearchAttributes={
                  props.productSearchAttributesForDownloadButton
                }
              />
            </Grid>
          </Grid>

          {/* TABLE START */}

          <StyledTableContainer>
            <Table
              sx={{ minWidth: 750, maxHeight: 450 }}
              aria-labelledby="tableTitle"
              size="small"
              stickyHeader
            >
              <TableHead>
                <StyledHeaderTableRow>
                  <TableCell sx={{ backgroundColor: "neutral.main" }}>
                    <FormattedMessage
                      id="restrictions.listing.product_code"
                      defaultMessage="Product Code"
                      description="Product code table header text"
                    />
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "neutral.main" }}>
                    <FormattedMessage
                      id="restrictions.listing.image"
                      defaultMessage="Image"
                      description="Image table header text"
                    />
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "neutral.main" }}>
                    <FormattedMessage
                      id="restrictions.listing.product_info"
                      defaultMessage="Product Info"
                      description="Product information table header text"
                    />
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "neutral.main" }}>
                    <FormControlLabel
                      key={"All Flags"}
                      control={
                        <Checkbox
                          checked={isPageFullyChecked()}
                          indeterminate={isPagePartiallyChecked()}
                          onChange={(event) => handlePageCheckboxChange(event)}
                        />
                      }
                      label={intl.formatMessage({
                        id: "restrictions.listing.all_flags",
                        defaultMessage: "All flags",
                        description: "All flags checkbox text",
                      })}
                    />
                  </TableCell>
                  {promotions?.map((promotion) => (
                    <TableCell
                      key={promotion.exclusion_code}
                      sx={{ backgroundColor: "neutral.main" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isColumnFullyChecked(promotion.code)}
                            indeterminate={isColumnPartiallyChecked(
                              promotion.code
                            )}
                            onChange={(event) =>
                              handleColumnCheckboxChange(
                                event,
                                promotion.code,
                                promotion.exclusion_code
                              )
                            }
                          />
                        }
                        label={promotion.exclusion_code}
                      />
                    </TableCell>
                  ))}

                  <TableCell sx={{ backgroundColor: "neutral.main" }}>
                    <FormattedMessage
                      id="restrictions.listing.last_updated"
                      defaultMessage="Last Updated"
                      description="Last updated table header text"
                    />
                  </TableCell>
                </StyledHeaderTableRow>
              </TableHead>

              <TableBody>
                {props.getRestrictionsResponse.data?.map(
                  (
                    row: SearchProductsWithPromotionExclusionsByProductAttributesResultItem
                  ) => (
                    <TableRow
                      key={row.product_code}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      selected={restrictionsToSend.some(
                        (product: any) =>
                          product.productCode === row.product_code
                      )}
                      hover
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ verticalAlign: "top" }}
                      >
                        {row.product_code}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <img
                          width="100px"
                          alt={row.product_code}
                          src={row.image_url || PLACE_HOLDER_IMG}
                        />
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <ProductTable row={row} />
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <FormControlLabel
                          key={"All"}
                          control={
                            <Checkbox
                              checked={isRowFullyChecked(row.product_code)}
                              indeterminate={isRowPartiallyChecked(
                                row.product_code
                              )}
                              onChange={(event) =>
                                handleRowCheckboxChange(event, row.product_code)
                              }
                            />
                          }
                          label={intl.formatMessage({
                            id: "restrictions.listing.all",
                            defaultMessage: "All",
                            description: "All flags per product checkbox text",
                          })}
                        />
                      </TableCell>

                      {promotions?.map((promotion) => (
                        <TableCell key={promotion.code}>
                          {getCurrencyCheckboxes(
                            row.product_code,
                            promotion.code
                          )}
                        </TableCell>
                      ))}

                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack>
                          {row.last_updated_time ? (
                            <>
                              <Moment
                                locale={userPreferences.language.language}
                                tz={
                                  userPreferences.timezone.format === "utc"
                                    ? "UTC"
                                    : "America/Montreal"
                                }
                                format="ddd YYYY-MM-DD, HH:mm:ss z"
                              >
                                {row.last_updated_time}
                              </Moment>
                              <FormattedMessage
                                id="restrictions.listing.by"
                                defaultMessage="By"
                                description="By"
                              />{" "}
                              {row.last_update_requestor}
                            </>
                          ) : (
                            <>{"N/A"}</>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <ContinuationWaypoint
            response={props.getRestrictionsResponse}
            topOffset="400px"
            bottomOffset="-300px"
          />

          <AppBar
            enableColorOnDark
            position="sticky"
            color="neutral"
            sx={{ top: "auto", bottom: 0 }}
          >
            <Toolbar>
              <Tooltip title="Back To Top">
                <Box>
                  <Fab
                    size="small"
                    color="primary"
                    sx={{ mr: 1 }}
                    disabled={!trigger}
                    onClick={backToTop}
                  >
                    <VerticalAlignTop />
                  </Fab>
                </Box>
              </Tooltip>

              <Typography
                sx={{ flexGrow: 1 }}
                variant="body1"
                color="inherit"
                component="div"
              >
                <FormattedMessage
                  id="restrictions.listing.total_rows"
                  defaultMessage="Total Rows {rows_current} of {rows_total}"
                  description="Total rows bottom page text"
                  values={{
                    rows_current: restrictionsInitial?.data.length,
                    rows_total: props.getRestrictionsResponse?.totalCount,
                  }}
                />
              </Typography>
              <Button
                sx={{ mr: 2 }}
                variant="outlined"
                disabled={!restrictionsToSend.length}
                onClick={handleClearAllClick}
              >
                <FormattedMessage
                  id="restrictions.listing.clear_changes"
                  defaultMessage="Clear changes"
                  description="Clear changes button"
                />
              </Button>
              <Button
                variant="contained"
                disabled={!restrictionsToSend.length}
                onClick={handleSaveRestrictions}
              >
                <FormattedMessage
                  id="restrictions.listing.save"
                  defaultMessage="Save"
                  description="Save changes button"
                />
              </Button>
            </Toolbar>
          </AppBar>
        </StyledPaper>
      )}
      <RestrictionsModalConfirmation
        open={confirmationModalVisible}
        onClose={handleModalConfirmationClose}
        onValidateSelection={validateColumnSelection}
        onValidatePageSelection={validatePageSelection}
        checked={columnSelectedChecked}
        promotionCode={columnSelectedPromotionCode}
        exclusionCode={columnSelectedExclusionCode}
        selectionNumber={columnSelectedSelectionNumber}
        totalNumber={props.getRestrictionsResponse?.totalCount}
      />
    </ApiResponse>
  );
}
export default RestrictionsListing;

import { useUserPreferences } from "context/preferences/UserPreferences";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

export interface SaveSearchModalProps {
  open: boolean;
  onClose: () => void;
  parameters: any;
}

function SaveSearchModal(props: SaveSearchModalProps) {
  const { open, onClose, parameters } = props;

  const userPreferences = useUserPreferences();
  const intl = useIntl();

  const [searchName, setSearchName] = useState<string>("");

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const searchToSave = {
    name: searchName,
    parameters: parameters,
    date: new Date().toUTCString(),
  };

  const handleSaveButton = () => {
    userPreferences.search.addSearch?.(searchToSave, "savedSearches");
    handleClose();
  };

  const isSearchNameExist = (searchName: string) => {
    const index = userPreferences.search.searches.findIndex(
      (search) => search.name === searchName
    );
    if (index !== -1) {
      return true;
    }
    return false;
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage
          id="restrictions.searches.save_title"
          defaultMessage="Save search"
          description="Save filter modal title"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage
                id="restrictions.searches.save_content"
                defaultMessage="Choose a name"
                description="Save filter modal content"
              />
            </Typography>
          </Grid>
          {isSearchNameExist(searchName) && (
            <Grid item xs={12}>
              <Alert severity="error">
                <FormattedMessage
                  id="restrictions.searches.name_duplicate"
                  defaultMessage="This name has been used. Try another name."
                  description="Save filter name already used"
                />
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="search name"
              label={intl.formatMessage({
                id: "restrictions.searches.name_input",
                defaultMessage: "name",
                description: "Name input label",
              })}
              variant="outlined"
              size="small"
              value={searchName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            id="restrictions.searches.save_cancel"
            defaultMessage="Cancel"
            description="Save filter cancel text button"
          />
        </Button>
        <Tooltip
          title={
            isSearchNameExist(searchName)
              ? intl.formatMessage({
                  id: "restrictions.searches.name_duplicate",
                  defaultMessage: "This name has been used. Try another name.",
                  description: "Save filter tooltip already used",
                })
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              disabled={searchName === "" || isSearchNameExist(searchName)}
              onClick={handleSaveButton}
              component="label"
            >
              <FormattedMessage
                id="restrictions.searches.save"
                defaultMessage="Save"
                description="Save filter save text button"
              />
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default SaveSearchModal;

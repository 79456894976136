import "moment/locale/fr";

import { useUserPreferences } from "context/preferences/UserPreferences";
import { IntlProvider } from "react-intl";
import enMessages from "translations/en.json";
import frMessages from "translations/fr.json";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const isMarkdownUIEnabled = process.env.REACT_APP_IS_MARKDOWN_UI_ENABLED === 'true';

// Overriding this global namespace to allow only existing ids
declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: keyof typeof enMessages;
    }
  }
}

const messages: any = {
  en: enMessages,
  fr: frMessages,
};

type AppThemeProviderProps = {
  children: React.ReactNode;
};

function AppIntlProvider(props: AppThemeProviderProps) {
  const { children } = props;

  const userPreferences = useUserPreferences();

  const language = userPreferences.language.language;

  return (
    <div>
      {isMarkdownUIEnabled
        ?
          <IntlProvider locale={language} messages={messages[language]} defaultLocale="en">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {children}
            </LocalizationProvider>
          </IntlProvider>
        : 
          <IntlProvider
            locale={language}
            messages={messages[language]}
            defaultLocale="en"
          >
            {children}
          </IntlProvider>
      }
    </div>
  );
}

export default AppIntlProvider;

import PreferencesDashboard from "components/preferences/PreferencesDashboard";
import { FormattedMessage } from "react-intl";

import { Close } from "@mui/icons-material";
import {
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Typography,
  useTheme
} from "@mui/material";

import { DrawerHeader } from "../navigation/AppNavigation";

type UserPreferencesDrawerProps = {
  open: boolean;
  onClose: Function;
};

function UserPreferencesDrawer(props: UserPreferencesDrawerProps) {
  const { open, onClose } = props;

  const theme = useTheme();

  return (
    <Drawer anchor="right" open={open} onClose={onClose(false)}>
      <DrawerHeader />
      <Grid
        container
        mb={2}
        sx={{
          background: theme.palette.primary.main,
        }}
      >
        <Grid item>
          <Typography sx={{ verticalAlign: "middle", display: "inline-flex" }}>
            <IconButton
              onClick={onClose(false)}
              sx={{ color: theme.palette.neutral.light }}
            >
              <Close />
            </IconButton>
            <InputLabel
              sx={{
                color: theme.palette.neutral.light,
                fontSize: 16,
                padding: "10px",
              }}
            >
              <FormattedMessage
                id="restrictions.settings"
                defaultMessage="Settings"
                description="Settings drawer title"
              />
            </InputLabel>
          </Typography>
        </Grid>
      </Grid>

      <PreferencesDashboard />
    </Drawer>
  );
}

export default UserPreferencesDrawer;

import UserPreferencesDrawer from "components/layout/userPreferences/UserPreferencesDrawer";
import { useAuthentication } from "context/authentication/UserAuthentication";
import { useUserPreferences } from "context/preferences/UserPreferences";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

import { Close, Menu as MenuIcon } from "@mui/icons-material";
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

const linkStyle = {
  textDecoration: "unset",
  color: "unset",
};

interface StyledAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<StyledAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

type AppBarProps = {
  open: boolean;
  setOpen: Function;
};

function AppBar(props: AppBarProps) {
  const { open, setOpen } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [preferencesDrawerOpen, setPreferencesDrawerOpen] =
    useState<boolean>(false);

  const authentication = useAuthentication();
  const userPreferences = useUserPreferences();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const togglePreferencesDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      handleClose();
      setPreferencesDrawerOpen(open);
    };

  const location = useLocation();
  const salesManagementValidPathNames = ['/sale-events', '/sale-events/create', '/simulations', '/simulations/create']
  const isMarkdownUIEnabled = process.env.REACT_APP_IS_MARKDOWN_UI_ENABLED === 'true';
  const getToolbarTitle = isMarkdownUIEnabled && salesManagementValidPathNames.includes(location?.pathname) ? 'Sales Management' : 'Promotion';

  return (
    <StyledAppBar
      position="fixed"
      color="secondary"
      enableColorOnDark
      open={open}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => (open === true ? setOpen(false) : setOpen(true))}
          sx={{ mr: 2 }}
        >
          {open ? <Close /> : <MenuIcon />}
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link style={linkStyle} to="/">
            <strong>SSENSE</strong> { getToolbarTitle }
          </Link>
        </Typography>

        <div>
          <Button
            color="inherit"
            variant="text"
            sx={{ fontSize: 16 }}
            onClick={userPreferences.language.toggle}
          >
            {userPreferences.language.language === "en" ? "fr" : "en"}
          </Button>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={authentication.userInfo?.email}
              src={authentication.userInfo?.picture}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled={true}>
              {authentication.userInfo?.firstName}{" "}
              {authentication.userInfo?.lastName}
            </MenuItem>
            {process.env.REACT_APP_IS_USER_PREFERENCES_ENABLED === "true" && (
              <MenuItem onClick={togglePreferencesDrawer(true)}>
                <FormattedMessage
                  id="restrictions.settings"
                  defaultMessage="Settings"
                  description="Settings menu option"
                />
              </MenuItem>
            )}
            <MenuItem onClick={() => authentication.logout()}>
              <FormattedMessage
                id="restrictions.logout"
                defaultMessage="Logout"
                description="Logout menu option"
              />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <UserPreferencesDrawer
        open={preferencesDrawerOpen}
        onClose={togglePreferencesDrawer}
      />
    </StyledAppBar>
  );
}

export default AppBar;

/* tslint:disable */
/* eslint-disable */
/**
 * ms-promotion
 * HTTP API for the ms-promotion service.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AppServicesSharedPublishProductCodesAggregatesPublishProductStatusEnum = {
    Successful: 'successful',
    Partial: 'partial'
} as const;

export type AppServicesSharedPublishProductCodesAggregatesPublishProductStatusEnum = typeof AppServicesSharedPublishProductCodesAggregatesPublishProductStatusEnum[keyof typeof AppServicesSharedPublishProductCodesAggregatesPublishProductStatusEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AppServicesStateMachinesCreatePromotionRestrictionsStep02PublishProductRestrictionsEventsPublishProductStatusEnum = {
    Successful: 'successful',
    Partial: 'partial'
} as const;

export type AppServicesStateMachinesCreatePromotionRestrictionsStep02PublishProductRestrictionsEventsPublishProductStatusEnum = typeof AppServicesStateMachinesCreatePromotionRestrictionsStep02PublishProductRestrictionsEventsPublishProductStatusEnum[keyof typeof AppServicesStateMachinesCreatePromotionRestrictionsStep02PublishProductRestrictionsEventsPublishProductStatusEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AppServicesStateMachinesStartStateMachineStartStateMachineResultStatus = {
    Ok: 'OK',
    Error: 'ERROR'
} as const;

export type AppServicesStateMachinesStartStateMachineStartStateMachineResultStatus = typeof AppServicesStateMachinesStartStateMachineStartStateMachineResultStatus[keyof typeof AppServicesStateMachinesStartStateMachineStartStateMachineResultStatus];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const AppServicesStateMachinesStartSyncStateMachineStartStateMachineResultStatus = {
    Ok: 'OK',
    Error: 'ERROR'
} as const;

export type AppServicesStateMachinesStartSyncStateMachineStartStateMachineResultStatus = typeof AppServicesStateMachinesStartSyncStateMachineStartStateMachineResultStatus[keyof typeof AppServicesStateMachinesStartSyncStateMachineStartStateMachineResultStatus];


/**
 * DTO for command descriptions.
 * @export
 * @interface CommandDescription
 */
export interface CommandDescription {
    /**
     * The full command name.
     * @type {string}
     * @memberof CommandDescription
     */
    'name': string;
    /**
     * The short command name.
     * @type {string}
     * @memberof CommandDescription
     */
    'short_name': string;
    /**
     * Complete JSON schema of the command.
     * @type {object}
     * @memberof CommandDescription
     */
    'command_schema': object;
    /**
     * Complete JSON schema of the result.
     * @type {object}
     * @memberof CommandDescription
     */
    'result_schema': object;
}
/**
 * The result status of converting the csv file to jsonl.
 * @export
 * @enum {string}
 */

export const ConcertCsvToJsonlStatusEnum = {
    Fail: 'FAIL',
    Success: 'SUCCESS'
} as const;

export type ConcertCsvToJsonlStatusEnum = typeof ConcertCsvToJsonlStatusEnum[keyof typeof ConcertCsvToJsonlStatusEnum];


/**
 * Result of converting user uploaded promotion restrictions csv file to jsonl.
 * @export
 * @interface ConvertCsvToJsonlResult
 */
export interface ConvertCsvToJsonlResult {
    /**
     * 
     * @type {ConcertCsvToJsonlStatusEnum}
     * @memberof ConvertCsvToJsonlResult
     */
    'convert_results'?: ConcertCsvToJsonlStatusEnum;
    /**
     * Failed error message. None if it is successful
     * @type {string}
     * @memberof ConvertCsvToJsonlResult
     */
    'message'?: string;
    /**
     * List of files converted
     * @type {Array<string>}
     * @memberof ConvertCsvToJsonlResult
     */
    'converted_file_list': Array<string>;
}
/**
 * Convert user uploaded promotion restrictions .csv file to .jsonl.
 * @export
 * @interface ConvertPromotionRestrictionsCsvToJsonlCommand
 */
export interface ConvertPromotionRestrictionsCsvToJsonlCommand {
    /**
     * S3 URI where CSV file can be read from.
     * @type {string}
     * @memberof ConvertPromotionRestrictionsCsvToJsonlCommand
     */
    'csv_path': string;
    /**
     * Batch size for conversion
     * @type {number}
     * @memberof ConvertPromotionRestrictionsCsvToJsonlCommand
     */
    'batch_size'?: number;
}
/**
 * This command will receive a file with all historical restrictions, grouped by product code first and sorted by time in ascending order.  The output will be a list of files that can be imported into the Restriction System
 * @export
 * @interface ConvertRestrictionLogFileIntoRestrictionImportFileCommand
 */
export interface ConvertRestrictionLogFileIntoRestrictionImportFileCommand {
    /**
     * Where to read the payload from
     * @type {string}
     * @memberof ConvertRestrictionLogFileIntoRestrictionImportFileCommand
     */
    'source_file_url': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ConvertRestrictionLogFileIntoRestrictionImportFileResult
 */
export interface ConvertRestrictionLogFileIntoRestrictionImportFileResult {
    /**
     * 
     * @type {number}
     * @memberof ConvertRestrictionLogFileIntoRestrictionImportFileResult
     */
    'number_of_chunks': number;
    /**
     * 
     * @type {number}
     * @memberof ConvertRestrictionLogFileIntoRestrictionImportFileResult
     */
    'chunk_size': number;
    /**
     * 
     * @type {number}
     * @memberof ConvertRestrictionLogFileIntoRestrictionImportFileResult
     */
    'total_lines_read': number;
}
/**
 * Convert user uploaded promotion restrictions .csv file to .jsonl.
 * @export
 * @interface CreatePromotionCommand
 */
export interface CreatePromotionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'promotion_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'promotion_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'exclusion_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCommand
     */
    'end_time'?: string;
}
/**
 * Result of create promotion.
 * @export
 * @interface CreatePromotionResult
 */
export interface CreatePromotionResult {
    /**
     * 
     * @type {CreatePromotionStatusEnum}
     * @memberof CreatePromotionResult
     */
    'result'?: CreatePromotionStatusEnum;
    /**
     * Failed error message. None if it is successful
     * @type {string}
     * @memberof CreatePromotionResult
     */
    'error_message'?: string;
}
/**
 * The result status of creating promotion.
 * @export
 * @enum {string}
 */

export const CreatePromotionStatusEnum = {
    Fail: 'FAIL',
    Success: 'SUCCESS'
} as const;

export type CreatePromotionStatusEnum = typeof CreatePromotionStatusEnum[keyof typeof CreatePromotionStatusEnum];


/**
 * Delete a websocket user connection in database.
 * @export
 * @interface DeleteConnectionCommand
 */
export interface DeleteConnectionCommand {
    /**
     * The connection identifier of the connected user.
     * @type {string}
     * @memberof DeleteConnectionCommand
     */
    'connection_id': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface DeleteConnectionCommandResult
 */
export interface DeleteConnectionCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteConnectionCommandResult
     */
    'result': boolean;
}
/**
 * The promotion codes for filtering.
 * @export
 * @interface ExclusionFlagAttributes
 */
export interface ExclusionFlagAttributes {
    /**
     * User selected promotion codes to filter product with corresponding promotion restriction or not
     * @type {{ [key: string]: boolean; }}
     * @memberof ExclusionFlagAttributes
     */
    'promotion_code_filters'?: { [key: string]: boolean; };
    /**
     * When user only want one of the filters select to qualify instead of all promotion codes filters
     * @type {boolean}
     * @memberof ExclusionFlagAttributes
     */
    'is_meeting_any_of_filter_is_enough'?: boolean;
}
/**
 * Command to generate the csv of exclusion flags for products that user is searching for.
 * @export
 * @interface ExportPromotionRestrictionsCSVWithProductAttributesCommand
 */
export interface ExportPromotionRestrictionsCSVWithProductAttributesCommand {
    /**
     * A location where the jsonl can be read from. It can be a local file or an external resource such as HTTP or S3 URI
     * @type {string}
     * @memberof ExportPromotionRestrictionsCSVWithProductAttributesCommand
     */
    'product_search_attributes_file_url': string;
}
/**
 * Result of generating the csv for download
 * @export
 * @interface ExportPromotionRestrictionsCSVWithProductAttributesResult
 */
export interface ExportPromotionRestrictionsCSVWithProductAttributesResult {
    /**
     * 
     * @type {string}
     * @memberof ExportPromotionRestrictionsCSVWithProductAttributesResult
     */
    'download_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportPromotionRestrictionsCSVWithProductAttributesResult
     */
    'error_message'?: string;
}
/**
 * The various feature flags used in the promotion domain.
 * @export
 * @interface FeatureFlags
 */
export interface FeatureFlags {
    /**
     * A Sample feature flag
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_my_feature_flag_enabled'?: boolean;
    /**
     * Enable this flag to enable role based access to application resources.
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_role_based_access_enabled'?: boolean;
    /**
     * See II-8938, this flag allows the injection of an aws session into containers that need it
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_aws_session_injected_as_dependency_enabled'?: boolean;
    /**
     * Enable this flag to enable metrics publishing.
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_metrics_enabled'?: boolean;
    /**
     * Enable this flag to enable pubsub for topic Promotion.Promotion.
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_promotion_pubsub_enabled'?: boolean;
    /**
     * Enable this flag to enable load test functionality.
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_load_test_enabled'?: boolean;
    /**
     * Whether race conditions are avoided at the Infrastructure level for Restrictions
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_restriction_race_condition_check_enabled'?: boolean;
    /**
     * Enable this flag to allow user find product filter by exclusion flags
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_find_product_filter_by_exclusion_flags_enabled'?: boolean;
    /**
     * Enable this flag to allow tracking users who update the promotion restriction
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_user_tracking_enabled'?: boolean;
    /**
     * Enable this flag to allow all promotion exclusions for product to be published not only value=True
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_publishing_all_product_exclusion'?: boolean;
    /**
     * Enable this flag will allow Step02PublishProductRestrictionsEventsCommand to call the PublishProductCodesAggregateCommand to publish products restrictions to Promotion.Product topic
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_using_republish_product_codes_in_step_function'?: boolean;
    /**
     * Enable this flag will filter out products in OpenSearch queries to only show PO approved products
     * @type {boolean}
     * @memberof FeatureFlags
     */
    'is_products_filter_by_lifecycle_status_enabled'?: boolean;
}
/**
 * Fetch Product Exclusion Flags
 * @export
 * @interface FetchProductExclusionFlagsCommand
 */
export interface FetchProductExclusionFlagsCommand {
    /**
     * 
     * @type {string}
     * @memberof FetchProductExclusionFlagsCommand
     */
    'promotion_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchProductExclusionFlagsCommand
     */
    'product_excluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FetchProductExclusionFlagsCommand
     */
    'batch_size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchProductExclusionFlagsCommand
     */
    'continuation_token'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface FetchProductExclusionFlagsResult
 */
export interface FetchProductExclusionFlagsResult {
    /**
     * 
     * @type {Array<RestrictionItem>}
     * @memberof FetchProductExclusionFlagsResult
     */
    'restrictions': Array<RestrictionItem>;
    /**
     * 
     * @type {string}
     * @memberof FetchProductExclusionFlagsResult
     */
    'continuation_token'?: string;
}
/**
 * Fetch Promotion Restrictions By Product Code
 * @export
 * @interface FetchPromotionRestrictionsByProductCodeCommand
 */
export interface FetchPromotionRestrictionsByProductCodeCommand {
    /**
     * 
     * @type {string}
     * @memberof FetchPromotionRestrictionsByProductCodeCommand
     */
    'product_code': string;
    /**
     * 
     * @type {number}
     * @memberof FetchPromotionRestrictionsByProductCodeCommand
     */
    'batch_size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchPromotionRestrictionsByProductCodeCommand
     */
    'continuation_token'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface FetchPromotionRestrictionsByProductCodeResult
 */
export interface FetchPromotionRestrictionsByProductCodeResult {
    /**
     * 
     * @type {Array<RestrictionItem>}
     * @memberof FetchPromotionRestrictionsByProductCodeResult
     */
    'restrictions': Array<RestrictionItem>;
    /**
     * 
     * @type {string}
     * @memberof FetchPromotionRestrictionsByProductCodeResult
     */
    'continuation_token'?: string;
}
/**
 * Fetch Promotion Restrictions By Product Code List
 * @export
 * @interface FetchPromotionRestrictionsByProductCodesCommand
 */
export interface FetchPromotionRestrictionsByProductCodesCommand {
    /**
     * List of product codes for which we want to fetch restrictions
     * @type {Array<string>}
     * @memberof FetchPromotionRestrictionsByProductCodesCommand
     */
    'product_codes'?: Array<string>;
    /**
     * Define if we returns exclusion = True or False or all
     * @type {boolean}
     * @memberof FetchPromotionRestrictionsByProductCodesCommand
     */
    'product_exclusion'?: boolean;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface FetchPromotionRestrictionsByProductCodesResult
 */
export interface FetchPromotionRestrictionsByProductCodesResult {
    /**
     * 
     * @type {Array<RestrictionItem>}
     * @memberof FetchPromotionRestrictionsByProductCodesResult
     */
    'restrictions': Array<RestrictionItem>;
}
/**
 * Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3 for execution by the CommandExecutionManager. Abides by the naming convention required to trigger the lambda (s3://batch_jobs/CommandName/(timestamp)/(username)/upload.[csv,jsonl]).
 * @export
 * @interface GenerateCommandExecutionPresignedUrlCommand
 */
export interface GenerateCommandExecutionPresignedUrlCommand {
    /**
     * Name of the command that will be used to build the S3 object key name and trigger the relevent command via the CommandExecutionManager.
     * @type {string}
     * @memberof GenerateCommandExecutionPresignedUrlCommand
     */
    'command_name': string;
    /**
     * File extensions supported for use with the CommandExecutionManager.
     * @type {string}
     * @memberof GenerateCommandExecutionPresignedUrlCommand
     */
    'file_extension': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const GenerateCommandExecutionPresignedUrlEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type GenerateCommandExecutionPresignedUrlEnum = typeof GenerateCommandExecutionPresignedUrlEnum[keyof typeof GenerateCommandExecutionPresignedUrlEnum];


/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface GenerateCommandExecutionPresignedUrlResult
 */
export interface GenerateCommandExecutionPresignedUrlResult {
    /**
     * Presigned URL for file upload via a Content-Type: multipart/form-data POST request.
     * @type {string}
     * @memberof GenerateCommandExecutionPresignedUrlResult
     */
    'presigned_url'?: string;
    /**
     * Form data fields that are required to construct the Content-Type: multipart/form-data POST request for file upload.
     * @type {object}
     * @memberof GenerateCommandExecutionPresignedUrlResult
     */
    'form_data_fields'?: object;
    /**
     * 
     * @type {GenerateCommandExecutionPresignedUrlEnum}
     * @memberof GenerateCommandExecutionPresignedUrlResult
     */
    'status': GenerateCommandExecutionPresignedUrlEnum;
    /**
     * If the presigned URL generation had an error, there will be an error message.
     * @type {string}
     * @memberof GenerateCommandExecutionPresignedUrlResult
     */
    'error'?: string;
}
/**
 * Generates a presigned URL to download files from S3. The full path for the file will be similar like (s3://promotion-bucket/key_name).
 * @export
 * @interface GeneratePresignedGetCommand
 */
export interface GeneratePresignedGetCommand {
    /**
     * A S3 full file name includes path the user will download
     * @type {string}
     * @memberof GeneratePresignedGetCommand
     */
    'key_name': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface GeneratePresignedGetResult
 */
export interface GeneratePresignedGetResult {
    /**
     * AWS S3 Presigned URL for file to be download.
     * @type {string}
     * @memberof GeneratePresignedGetResult
     */
    'presigned_get_url'?: string;
    /**
     * 
     * @type {GeneratePresignedGetStatus}
     * @memberof GeneratePresignedGetResult
     */
    'status': GeneratePresignedGetStatus;
    /**
     * if status is error, this will be the error message
     * @type {string}
     * @memberof GeneratePresignedGetResult
     */
    'error'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const GeneratePresignedGetStatus = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type GeneratePresignedGetStatus = typeof GeneratePresignedGetStatus[keyof typeof GeneratePresignedGetStatus];


/**
 * Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3. The full path for the uploaded file will be similar like (s3://promotion-bucket/key_name).
 * @export
 * @interface GeneratePresignedPostCommand
 */
export interface GeneratePresignedPostCommand {
    /**
     * A S3 full file name includes path the user will upload
     * @type {string}
     * @memberof GeneratePresignedPostCommand
     */
    'key_name': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface GeneratePresignedPostResult
 */
export interface GeneratePresignedPostResult {
    /**
     * AWS S3 Presigned URL for file upload via a Content-Type: multipart/form-data POST request.
     * @type {string}
     * @memberof GeneratePresignedPostResult
     */
    'presigned_post_url'?: string;
    /**
     * Form data fields that are required to construct the Content-Type: multipart/form-data S3 POST request for file upload.
     * @type {object}
     * @memberof GeneratePresignedPostResult
     */
    'form_data_fields'?: object;
    /**
     * 
     * @type {GeneratePresignedPostStatus}
     * @memberof GeneratePresignedPostResult
     */
    'status': GeneratePresignedPostStatus;
    /**
     * if status is error, this will be the error message
     * @type {string}
     * @memberof GeneratePresignedPostResult
     */
    'error'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const GeneratePresignedPostStatus = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type GeneratePresignedPostStatus = typeof GeneratePresignedPostStatus[keyof typeof GeneratePresignedPostStatus];


/**
 * Generate csv with random promotion restrictions for testing purpose
 * @export
 * @interface GeneratePromotionRestrictionsCsvForTestCommand
 */
export interface GeneratePromotionRestrictionsCsvForTestCommand {
    /**
     * Minimum number of products to generate
     * @type {number}
     * @memberof GeneratePromotionRestrictionsCsvForTestCommand
     */
    'number_of_product_codes_min'?: number;
    /**
     * Maximum number of products to generate
     * @type {number}
     * @memberof GeneratePromotionRestrictionsCsvForTestCommand
     */
    'number_of_product_codes_max'?: number;
}
/**
 * Result of Promotion Restriction creation
 * @export
 * @interface GeneratePromotionRestrictionsCsvForTestResult
 */
export interface GeneratePromotionRestrictionsCsvForTestResult {
    /**
     * File name created for the load test
     * @type {string}
     * @memberof GeneratePromotionRestrictionsCsvForTestResult
     */
    'file_name_created'?: string;
    /**
     * List of restrictions generated
     * @type {Array<string>}
     * @memberof GeneratePromotionRestrictionsCsvForTestResult
     */
    'list_of_restrictions_generated'?: Array<string>;
    /**
     * Message returned by the command
     * @type {string}
     * @memberof GeneratePromotionRestrictionsCsvForTestResult
     */
    'message'?: string;
    /**
     * Number of product codes generated
     * @type {number}
     * @memberof GeneratePromotionRestrictionsCsvForTestResult
     */
    'number_of_product_codes_created'?: number;
    /**
     * Number of restrictions generated
     * @type {number}
     * @memberof GeneratePromotionRestrictionsCsvForTestResult
     */
    'number_of_restrictions_created'?: number;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface GetExecutionDetailsResponse
 */
export interface GetExecutionDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof GetExecutionDetailsResponse
     */
    'execution_arn': string;
    /**
     * 
     * @type {object}
     * @memberof GetExecutionDetailsResponse
     */
    'input': object;
    /**
     * 
     * @type {string}
     * @memberof GetExecutionDetailsResponse
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof GetExecutionDetailsResponse
     */
    'output': object;
    /**
     * 
     * @type {string}
     * @memberof GetExecutionDetailsResponse
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof GetExecutionDetailsResponse
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetExecutionDetailsResponse
     */
    'stop_date'?: string;
}
/**
 * Values of all Latest Promotions
 * @export
 * @interface GetListPromotionsDetailsResult
 */
export interface GetListPromotionsDetailsResult {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof GetListPromotionsDetailsResult
     */
    'promotions': Array<Promotion>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetListPromotionsDetailsResult
     */
    'error_messages'?: Array<string>;
}
/**
 * Values that are available for the search parameters.
 * @export
 * @interface GetProductSearchParametersResult
 */
export interface GetProductSearchParametersResult {
    /**
     * 
     * @type {ProductSearchAttributes}
     * @memberof GetProductSearchParametersResult
     */
    'product_search_attributes': ProductSearchAttributes;
    /**
     * The list of delivery.
     * @type {Array<string>}
     * @memberof GetProductSearchParametersResult
     */
    'delivery': Array<string>;
    /**
     * The list of promotion.
     * @type {Array<Promotion>}
     * @memberof GetProductSearchParametersResult
     */
    'promotions': Array<Promotion>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface GetPromotionReferenceDataCommandResult
 */
export interface GetPromotionReferenceDataCommandResult {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof GetPromotionReferenceDataCommandResult
     */
    'list_promotions': Array<Promotion>;
    /**
     * currency codes supported in promotion engine
     * @type {Array<any>}
     * @memberof GetPromotionReferenceDataCommandResult
     */
    'list_promotion_currencies'?: Array<any>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface HealthCheckResult
 */
export interface HealthCheckResult {
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResult
     */
    'name': string;
    /**
     * 
     * @type {HealthCheckStatus}
     * @memberof HealthCheckResult
     */
    'status'?: HealthCheckStatus;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckResult
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthCheckResult
     */
    'elapsed'?: number;
    /**
     * 
     * @type {Array<HealthCheckResult>}
     * @memberof HealthCheckResult
     */
    'children'?: Array<HealthCheckResult>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const HealthCheckStatus = {
    Inconclusive: 'inconclusive',
    Ok: 'ok',
    Warning: 'warning',
    Error: 'error'
} as const;

export type HealthCheckStatus = typeof HealthCheckStatus[keyof typeof HealthCheckStatus];


/**
 * General information about the promotion engine.
 * @export
 * @interface InfoResponse
 */
export interface InfoResponse {
    /**
     * The name of the environment.
     * @type {string}
     * @memberof InfoResponse
     */
    'environment': string;
    /**
     * The current git commit sha1.
     * @type {string}
     * @memberof InfoResponse
     */
    'git_commit': string;
    /**
     * The base url for the dm-auth instance used.
     * @type {string}
     * @memberof InfoResponse
     */
    'dm_auth_server_url': string;
    /**
     * 
     * @type {FeatureFlags}
     * @memberof InfoResponse
     */
    'feature_flags': FeatureFlags;
    /**
     * The key used to hide clear user ids.
     * @type {string}
     * @memberof InfoResponse
     */
    'user_connection_user_id_secret_key': string;
}
/**
 * Standard metadata for integration events of generation 2.
 * @export
 * @interface IntegrationEvent2Metadata
 */
export interface IntegrationEvent2Metadata {
    /**
     * 
     * @type {number}
     * @memberof IntegrationEvent2Metadata
     */
    'publication_timestamp': number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationEvent2Metadata
     */
    'topic_name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationEvent2Metadata
     */
    'publisher': string;
    /**
     * 
     * @type {number}
     * @memberof IntegrationEvent2Metadata
     */
    'publishing_timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof IntegrationEvent2Metadata
     */
    'request_id': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationEvent2Metadata
     */
    'version'?: string;
}
/**
 * Generic class that fits the SSENSE standard of integration events generation 2. https://readthedocs.ssense.com/standards/event-sourcing/message-structure/
 * @export
 * @interface IntegrationEvent2PromotionProductIntegrationEvent
 */
export interface IntegrationEvent2PromotionProductIntegrationEvent {
    /**
     * 
     * @type {IntegrationEvent2Metadata}
     * @memberof IntegrationEvent2PromotionProductIntegrationEvent
     */
    'metadata': IntegrationEvent2Metadata;
    /**
     * 
     * @type {PromotionProductIntegrationEvent}
     * @memberof IntegrationEvent2PromotionProductIntegrationEvent
     */
    'aggregate': PromotionProductIntegrationEvent;
}
/**
 * Holds a list of items, along with eventually a continuation token to retrieve the next batch.
 * @export
 * @interface ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem
 */
export interface ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem {
    /**
     * The list of items for the current set.
     * @type {Array<SearchProductsWithPromotionExclusionsByProductAttributesResultItem>}
     * @memberof ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'items': Array<SearchProductsWithPromotionExclusionsByProductAttributesResultItem>;
    /**
     * The token to retrieve the next batch, if any.
     * @type {string}
     * @memberof ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'continuation_token'?: string;
    /**
     * The total count of items in all batches.
     * @type {number}
     * @memberof ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'item_count'?: number;
}
/**
 * Holds a list of items, along with eventually a continuation token to retrieve the next batch.
 * @export
 * @interface ListContinuationStateMachineListExecutionsResponseItem
 */
export interface ListContinuationStateMachineListExecutionsResponseItem {
    /**
     * The list of items for the current set.
     * @type {Array<StateMachineListExecutionsResponseItem>}
     * @memberof ListContinuationStateMachineListExecutionsResponseItem
     */
    'items': Array<StateMachineListExecutionsResponseItem>;
    /**
     * The token to retrieve the next batch, if any.
     * @type {string}
     * @memberof ListContinuationStateMachineListExecutionsResponseItem
     */
    'continuation_token'?: string;
    /**
     * The total count of items in all batches.
     * @type {number}
     * @memberof ListContinuationStateMachineListExecutionsResponseItem
     */
    'item_count'?: number;
}
/**
 * Holds a list of items, along with eventually a continuation token to retrieve the next batch.
 * @export
 * @interface ListContinuationStr
 */
export interface ListContinuationStr {
    /**
     * The list of items for the current set.
     * @type {Array<string>}
     * @memberof ListContinuationStr
     */
    'items': Array<string>;
    /**
     * The token to retrieve the next batch, if any.
     * @type {string}
     * @memberof ListContinuationStr
     */
    'continuation_token'?: string;
    /**
     * The total count of items in all batches.
     * @type {number}
     * @memberof ListContinuationStr
     */
    'item_count'?: number;
}
/**
 * List state machine executions by state machine name
 * @export
 * @interface ListExecutionsCommand
 */
export interface ListExecutionsCommand {
    /**
     * The state machine name, exactly as defined in the Serverless file
     * @type {string}
     * @memberof ListExecutionsCommand
     */
    'state_machine_name': string;
    /**
     * 
     * @type {string}
     * @memberof ListExecutionsCommand
     */
    'continuation_token'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PermissionNameEnum = {
    Read: 'read',
    Write: 'write',
    Delete: 'delete',
    Execute: 'execute'
} as const;

export type PermissionNameEnum = typeof PermissionNameEnum[keyof typeof PermissionNameEnum];


/**
 * The group of product. It could be a brand, a category etc. For now, we only allow a product code.
 * @export
 * @interface ProductGroup
 */
export interface ProductGroup {
    /**
     * Product code
     * @type {string}
     * @memberof ProductGroup
     */
    'product_code': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ProductPromotionCurrenciesRestriction
 */
export interface ProductPromotionCurrenciesRestriction {
    /**
     * The target currency for this exclusion
     * @type {string}
     * @memberof ProductPromotionCurrenciesRestriction
     */
    'code': string;
    /**
     * The exclusion value
     * @type {boolean}
     * @memberof ProductPromotionCurrenciesRestriction
     */
    'exclusion': boolean;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ProductPromotionRestriction
 */
export interface ProductPromotionRestriction {
    /**
     * 
     * @type {string}
     * @memberof ProductPromotionRestriction
     */
    'promotionCode': string;
    /**
     * 
     * @type {Array<ProductPromotionCurrenciesRestriction>}
     * @memberof ProductPromotionRestriction
     */
    'currenciesRestriction': Array<ProductPromotionCurrenciesRestriction>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ProductSearchAttributes
 */
export interface ProductSearchAttributes {
    /**
     * The brand
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'brands'?: Array<string>;
    /**
     * The various genders that can be searched.
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'genders'?: Array<string>;
    /**
     * The season codes that can be searched.
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'season_codes'?: Array<string>;
    /**
     * The list of department
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'departments'?: Array<string>;
    /**
     * The list of categories.
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'categories'?: Array<string>;
    /**
     * The list of subcategories.
     * @type {Array<string>}
     * @memberof ProductSearchAttributes
     */
    'subcategories'?: Array<string>;
    /**
     * 
     * @type {ExclusionFlagAttributes}
     * @memberof ProductSearchAttributes
     */
    'exclusion_flag_attributes'?: ExclusionFlagAttributes;
    /**
     * Product Code
     * @type {string}
     * @memberof ProductSearchAttributes
     */
    'product_code'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ProductSearchByExclusionFlagAttribute
 */
export interface ProductSearchByExclusionFlagAttribute {
    /**
     * User selected promotion codes to filter product with corresponding promotion restriction or not
     * @type {{ [key: string]: boolean; }}
     * @memberof ProductSearchByExclusionFlagAttribute
     */
    'promotion_code_filters'?: { [key: string]: boolean; };
    /**
     * When user only want one of the filters select to qualify instead of all promotion codes filters
     * @type {boolean}
     * @memberof ProductSearchByExclusionFlagAttribute
     */
    'is_meeting_any_of_filter_is_enough'?: boolean;
}
/**
 * The promotion definition
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * The entity tag that uniquely identifies the content/version of this aggregate. This is used for concurrency control.
     * @type {string}
     * @memberof Promotion
     */
    'etag'?: string;
    /**
     * The code of the promotion.
     * @type {string}
     * @memberof Promotion
     */
    'code': string;
    /**
     * The name of the promotion.
     * @type {string}
     * @memberof Promotion
     */
    'name': string;
    /**
     * The description of the promotion.
     * @type {string}
     * @memberof Promotion
     */
    'description'?: string;
    /**
     * The name of the promotion exclusion eg: NOS.
     * @type {string}
     * @memberof Promotion
     */
    'exclusion_code': string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'end_time'?: string;
    /**
     * The timestamp of the promotion creation.
     * @type {string}
     * @memberof Promotion
     */
    'timestamp'?: string;
}
/**
 * This is the payload of Promotion.Promotion Integration event.
 * @export
 * @interface PromotionProductIntegrationEvent
 */
export interface PromotionProductIntegrationEvent {
    /**
     * Event id
     * @type {string}
     * @memberof PromotionProductIntegrationEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionProductIntegrationEvent
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionProductIntegrationEvent
     */
    'aggregate_version': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionProductIntegrationEvent
     */
    'sequential_version': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionProductIntegrationEvent
     */
    'last_update': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionProductIntegrationEvent
     */
    'business_entity'?: string;
    /**
     * The product code
     * @type {string}
     * @memberof PromotionProductIntegrationEvent
     */
    'product_code': string;
    /**
     * Restrictions associated to the promotion
     * @type {Array<PromotionProductRestrictionIntegrationEvent>}
     * @memberof PromotionProductIntegrationEvent
     */
    'restrictions': Array<PromotionProductRestrictionIntegrationEvent>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface PromotionProductRestrictionIntegrationEvent
 */
export interface PromotionProductRestrictionIntegrationEvent {
    /**
     * 
     * @type {string}
     * @memberof PromotionProductRestrictionIntegrationEvent
     */
    'promotion_code': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PromotionProductRestrictionIntegrationEvent
     */
    'target': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PromotionProductRestrictionIntegrationEvent
     */
    'restriction': { [key: string]: boolean; };
    /**
     * 
     * @type {PromotionProductRestrictionsLastUpdateIntegrationEvent}
     * @memberof PromotionProductRestrictionIntegrationEvent
     */
    'last_update': PromotionProductRestrictionsLastUpdateIntegrationEvent;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface PromotionProductRestrictionUserInformation
 */
export interface PromotionProductRestrictionUserInformation {
    /**
     * 
     * @type {string}
     * @memberof PromotionProductRestrictionUserInformation
     */
    'name'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface PromotionProductRestrictionUserInformationWithEmail
 */
export interface PromotionProductRestrictionUserInformationWithEmail {
    /**
     * User email
     * @type {string}
     * @memberof PromotionProductRestrictionUserInformationWithEmail
     */
    'email': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface PromotionProductRestrictionsLastUpdateIntegrationEvent
 */
export interface PromotionProductRestrictionsLastUpdateIntegrationEvent {
    /**
     * Date time of the update
     * @type {string}
     * @memberof PromotionProductRestrictionsLastUpdateIntegrationEvent
     */
    'datetime': string;
    /**
     * 
     * @type {User}
     * @memberof PromotionProductRestrictionsLastUpdateIntegrationEvent
     */
    'user': User;
}
/**
 * A product restriction.
 * @export
 * @interface PromotionRestriction
 */
export interface PromotionRestriction {
    /**
     * The entity tag that uniquely identifies the content/version of this aggregate. This is used for concurrency control.
     * @type {string}
     * @memberof PromotionRestriction
     */
    'etag'?: string;
    /**
     * The unique promotion code.
     * @type {string}
     * @memberof PromotionRestriction
     */
    'promotion_code': string;
    /**
     * 
     * @type {ProductGroup}
     * @memberof PromotionRestriction
     */
    'product_group': ProductGroup;
    /**
     * 
     * @type {Target1}
     * @memberof PromotionRestriction
     */
    'target': Target1;
    /**
     * The restriction\'s type (e.g. product exclusion).
     * @type {PromotionRestrictionTypeEnum}
     * @memberof PromotionRestriction
     */
    'type': PromotionRestrictionTypeEnum;
    /**
     * The restriction\'s value, for now it\'s only a boolean but could become an object later (e.g. start/end date).
     * @type {boolean}
     * @memberof PromotionRestriction
     */
    'value': boolean;
    /**
     * The user who request the ingestion of this promotion restriction.
     * @type {string}
     * @memberof PromotionRestriction
     */
    'requestor'?: string;
    /**
     * The timestamp of the restriction creation.
     * @type {string}
     * @memberof PromotionRestriction
     */
    'timestamp'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PromotionRestrictionTypeEnum = {
    Exclusion: 'exclusion'
} as const;

export type PromotionRestrictionTypeEnum = typeof PromotionRestrictionTypeEnum[keyof typeof PromotionRestrictionTypeEnum];


/**
 * A promotion restriction raw input from jsonl file.
 * @export
 * @interface PromotionRestrictionUserSelectionExclusionRawInput
 */
export interface PromotionRestrictionUserSelectionExclusionRawInput {
    /**
     * 
     * @type {string}
     * @memberof PromotionRestrictionUserSelectionExclusionRawInput
     */
    'productCode': string;
    /**
     * 
     * @type {Array<ProductPromotionRestriction>}
     * @memberof PromotionRestrictionUserSelectionExclusionRawInput
     */
    'restrictions': Array<ProductPromotionRestriction>;
}
/**
 * Publish products restrictions to the topic Promotion.Product
 * @export
 * @interface PublishProductCodesAggregateCommand
 */
export interface PublishProductCodesAggregateCommand {
    /**
     * The list of product codes will be published
     * @type {Array<string>}
     * @memberof PublishProductCodesAggregateCommand
     */
    'product_codes': Array<string>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface PublishProductCodesAggregateCommandResult
 */
export interface PublishProductCodesAggregateCommandResult {
    /**
     * Status of the publication
     * @type {PublishProductStatusEnum}
     * @memberof PublishProductCodesAggregateCommandResult
     */
    'status': PublishProductStatusEnum;
    /**
     * Number of product published
     * @type {number}
     * @memberof PublishProductCodesAggregateCommandResult
     */
    'number_of_products_published': number;
    /**
     * Number of errors
     * @type {number}
     * @memberof PublishProductCodesAggregateCommandResult
     */
    'number_of_errors': number;
    /**
     * List of product code with errors
     * @type {Array<string>}
     * @memberof PublishProductCodesAggregateCommandResult
     */
    'product_codes_with_errors': Array<string>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PublishProductStatusEnum = {
    Successful: 'successful',
    Partial: 'partial'
} as const;

export type PublishProductStatusEnum = typeof PublishProductStatusEnum[keyof typeof PublishProductStatusEnum];


/**
 * Create a user request to download .csv file based on specific search attributes
 * @export
 * @interface RequestPromotionRestrictionsCSVDownloadCommand
 */
export interface RequestPromotionRestrictionsCSVDownloadCommand {
    /**
     * 
     * @type {ProductSearchAttributes}
     * @memberof RequestPromotionRestrictionsCSVDownloadCommand
     */
    'product_search_attributes': ProductSearchAttributes;
}
/**
 * Result of requesting to download promotion restrictions in csv format
 * @export
 * @interface RequestPromotionRestrictionsCSVDownloadResult
 */
export interface RequestPromotionRestrictionsCSVDownloadResult {
    /**
     * request file path
     * @type {string}
     * @memberof RequestPromotionRestrictionsCSVDownloadResult
     */
    'request_file_url': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface ResourcePermission
 */
export interface ResourcePermission {
    /**
     * 
     * @type {string}
     * @memberof ResourcePermission
     */
    'resource': string;
    /**
     * 
     * @type {PermissionNameEnum}
     * @memberof ResourcePermission
     */
    'permission': PermissionNameEnum;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface RestrictionItem
 */
export interface RestrictionItem {
    /**
     * The unique promotion code.
     * @type {string}
     * @memberof RestrictionItem
     */
    'promotion_code': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RestrictionItem
     */
    'product_group': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Target2; }}
     * @memberof RestrictionItem
     */
    'target': { [key: string]: Target2; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RestrictionItem
     */
    'restriction': { [key: string]: boolean; };
}
/**
 * Save a websocket user connection in database.
 * @export
 * @interface SaveConnectionCommand
 */
export interface SaveConnectionCommand {
    /**
     * The identifier of the connected user.
     * @type {string}
     * @memberof SaveConnectionCommand
     */
    'user_id': string;
    /**
     * The connection identifier of the connected user
     * @type {string}
     * @memberof SaveConnectionCommand
     */
    'connection_id': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface SaveConnectionCommandResult
 */
export interface SaveConnectionCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof SaveConnectionCommandResult
     */
    'result': boolean;
}
/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface SaveLegacyNOSRecordsCommand
 */
export interface SaveLegacyNOSRecordsCommand {
    /**
     * 
     * @type {Array<PromotionProductIntegrationEvent>}
     * @memberof SaveLegacyNOSRecordsCommand
     */
    'promotion_restriction_aggregates'?: Array<PromotionProductIntegrationEvent>;
    /**
     * 
     * @type {Array<IntegrationEvent2PromotionProductIntegrationEvent>}
     * @memberof SaveLegacyNOSRecordsCommand
     */
    'promotion_restriction_pubsub_integration_events'?: Array<IntegrationEvent2PromotionProductIntegrationEvent>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface SaveLegacyNOSRecordsCommandResult
 */
export interface SaveLegacyNOSRecordsCommandResult {
    /**
     * 
     * @type {SaveLegacyNOSRecordsCommandResultStatusEnum}
     * @memberof SaveLegacyNOSRecordsCommandResult
     */
    'status': SaveLegacyNOSRecordsCommandResultStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SaveLegacyNOSRecordsCommandResult
     */
    'batch_size': number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SaveLegacyNOSRecordsCommandResultStatusEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type SaveLegacyNOSRecordsCommandResultStatusEnum = typeof SaveLegacyNOSRecordsCommandResultStatusEnum[keyof typeof SaveLegacyNOSRecordsCommandResultStatusEnum];


/**
 * Save a restriction in DB and returns all latest by type and promotion code hardcoded.
 * @export
 * @interface SaveRestrictionCommand
 */
export interface SaveRestrictionCommand {
    /**
     * The promotion code.
     * @type {string}
     * @memberof SaveRestrictionCommand
     */
    'promotion_code': string;
    /**
     * The product group.
     * @type {{ [key: string]: string; }}
     * @memberof SaveRestrictionCommand
     */
    'product_group': { [key: string]: string; };
    /**
     * The target.
     * @type {{ [key: string]: string; }}
     * @memberof SaveRestrictionCommand
     */
    'target': { [key: string]: string; };
    /**
     * The type.
     * @type {string}
     * @memberof SaveRestrictionCommand
     */
    'type': string;
    /**
     * The value.
     * @type {any}
     * @memberof SaveRestrictionCommand
     */
    'value'?: any;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface SaveRestrictionCommandResult
 */
export interface SaveRestrictionCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof SaveRestrictionCommandResult
     */
    'result': boolean;
}
/**
 * Get search parameters for product prices.
 * @export
 * @interface SearchProductsWithPromotionExclusionsByProductAttributesCommand
 */
export interface SearchProductsWithPromotionExclusionsByProductAttributesCommand {
    /**
     * 
     * @type {ProductSearchAttributes}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesCommand
     */
    'product_search_attributes': ProductSearchAttributes;
    /**
     * The list of promotions user selected.
     * @type {Array<Promotion>}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesCommand
     */
    'promotions': Array<Promotion>;
    /**
     * 
     * @type {number}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesCommand
     */
    'batch_size'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesCommand
     */
    'continuation_token'?: string;
}
/**
 * Values that are available for the search parameters.
 * @export
 * @interface SearchProductsWithPromotionExclusionsByProductAttributesResultItem
 */
export interface SearchProductsWithPromotionExclusionsByProductAttributesResultItem {
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'product_code': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'product_name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'brand_name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'supplier_code': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'supplier_color': string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'last_update_requestor'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'last_updated_time'?: string;
    /**
     * The promotion restriction by currency.
     * @type {{ [key: string]: { [key: string]: PromotionRestriction; }; }}
     * @memberof SearchProductsWithPromotionExclusionsByProductAttributesResultItem
     */
    'promotion_restrictions_by_promotion_code': { [key: string]: { [key: string]: PromotionRestriction; }; };
}
/**
 * Send a notification to a user.
 * @export
 * @interface SendNotificationCommand
 */
export interface SendNotificationCommand {
    /**
     * The user identifier that will receive the message.
     * @type {string}
     * @memberof SendNotificationCommand
     */
    'user_id': string;
    /**
     * The status to send.
     * @type {string}
     * @memberof SendNotificationCommand
     */
    'status': string;
    /**
     * The message to send.
     * @type {string}
     * @memberof SendNotificationCommand
     */
    'message': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface SendNotificationCommandResult
 */
export interface SendNotificationCommandResult {
    /**
     * 
     * @type {boolean}
     * @memberof SendNotificationCommandResult
     */
    'result': boolean;
}
/**
 * Set promotion product exclusions by converting user selected promotion restrictions into a  .jsonl that will be process by the step function createPromotionRestrictions.
 * @export
 * @interface SetPromotionProductExclusionsCommand
 */
export interface SetPromotionProductExclusionsCommand {
    /**
     * user selected exclusion per product code and promotion code send from UI
     * @type {Array<PromotionRestrictionUserSelectionExclusionRawInput>}
     * @memberof SetPromotionProductExclusionsCommand
     */
    'product_exclusions_to_set': Array<PromotionRestrictionUserSelectionExclusionRawInput>;
}
/**
 * Result of converting user selected promotion restrictions file to jsonl.
 * @export
 * @interface SetPromotionProductExclusionsResult
 */
export interface SetPromotionProductExclusionsResult {
    /**
     * 
     * @type {SetPromotionProductExclusionsStatusEnum}
     * @memberof SetPromotionProductExclusionsResult
     */
    'convert_results'?: SetPromotionProductExclusionsStatusEnum;
    /**
     * Failed error message. None if it is successful
     * @type {string}
     * @memberof SetPromotionProductExclusionsResult
     */
    'message'?: string;
}
/**
 * The result status of converting user selected promotion restrictions file to jsonl.
 * @export
 * @enum {string}
 */

export const SetPromotionProductExclusionsStatusEnum = {
    Fail: 'FAIL',
    Success: 'SUCCESS'
} as const;

export type SetPromotionProductExclusionsStatusEnum = typeof SetPromotionProductExclusionsStatusEnum[keyof typeof SetPromotionProductExclusionsStatusEnum];


/**
 * Starts a state machine by name, requires a payload and a unique execution name
 * @export
 * @interface StartStateMachineCommand
 */
export interface StartStateMachineCommand {
    /**
     * 
     * @type {any}
     * @memberof StartStateMachineCommand
     */
    'execution_payload'?: any;
    /**
     * 
     * @type {string}
     * @memberof StartStateMachineCommand
     */
    'state_machine_name': string;
    /**
     * The execution ID, it needs to be unique
     * @type {string}
     * @memberof StartStateMachineCommand
     */
    'execution_name': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StartStateMachineResult
 */
export interface StartStateMachineResult {
    /**
     * 
     * @type {StartStateMachineResultStatus}
     * @memberof StartStateMachineResult
     */
    'status': StartStateMachineResultStatus;
    /**
     * 
     * @type {string}
     * @memberof StartStateMachineResult
     */
    'response'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartStateMachineResult
     */
    'error'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StartStateMachineResultStatus = {
    Ok: 'OK',
    Error: 'ERROR'
} as const;

export type StartStateMachineResultStatus = typeof StartStateMachineResultStatus[keyof typeof StartStateMachineResultStatus];


/**
 * Starts a state machine by name, requires a payload and a unique execution name
 * @export
 * @interface StartSyncStateMachineCommand
 */
export interface StartSyncStateMachineCommand {
    /**
     * 
     * @type {any}
     * @memberof StartSyncStateMachineCommand
     */
    'execution_payload'?: any;
    /**
     * 
     * @type {string}
     * @memberof StartSyncStateMachineCommand
     */
    'state_machine_name': string;
    /**
     * The execution ID, it needs to be unique
     * @type {string}
     * @memberof StartSyncStateMachineCommand
     */
    'execution_name': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StartSyncStateMachineResult
 */
export interface StartSyncStateMachineResult {
    /**
     * 
     * @type {StartStateMachineResultStatus}
     * @memberof StartSyncStateMachineResult
     */
    'status': StartStateMachineResultStatus;
    /**
     * 
     * @type {GetExecutionDetailsResponse}
     * @memberof StartSyncStateMachineResult
     */
    'response'?: GetExecutionDetailsResponse;
    /**
     * 
     * @type {string}
     * @memberof StartSyncStateMachineResult
     */
    'error'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StateMachineListExecutionsResponseItem
 */
export interface StateMachineListExecutionsResponseItem {
    /**
     * 
     * @type {string}
     * @memberof StateMachineListExecutionsResponseItem
     */
    'execution_id': string;
    /**
     * 
     * @type {string}
     * @memberof StateMachineListExecutionsResponseItem
     */
    'state_machine_arn': string;
    /**
     * 
     * @type {string}
     * @memberof StateMachineListExecutionsResponseItem
     */
    'start_time': string;
    /**
     * 
     * @type {string}
     * @memberof StateMachineListExecutionsResponseItem
     */
    'stop_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof StateMachineListExecutionsResponseItem
     */
    'status': string;
}
/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step01Command
 */
export interface Step01Command {
    /**
     * 
     * @type {string}
     * @memberof Step01Command
     */
    'product_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step01Command
     */
    'timestamp'?: string;
}
/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step01HandleUploadedFileCommand
 */
export interface Step01HandleUploadedFileCommand {
    /**
     * A location where the jsonl can be read from. It can be a local file or an external resource such as HTTP or S3 URI
     * @type {string}
     * @memberof Step01HandleUploadedFileCommand
     */
    'file_url': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface Step01HandleUploadedFileCommandResult
 */
export interface Step01HandleUploadedFileCommandResult {
    /**
     * number of line get processed successfully
     * @type {number}
     * @memberof Step01HandleUploadedFileCommandResult
     */
    'number_of_lines_ingested': number;
    /**
     * number of line failed at processing
     * @type {number}
     * @memberof Step01HandleUploadedFileCommandResult
     */
    'number_of_lines_failed': number;
    /**
     * Url of product codes having a restriction processed during the upload
     * @type {string}
     * @memberof Step01HandleUploadedFileCommandResult
     */
    'product_codes_file_path'?: string;
    /**
     * 
     * @type {string}
     * @memberof Step01HandleUploadedFileCommandResult
     */
    'error_messages_path'?: string;
}
/**
 * Result for Step 01 of Dummy State Machine
 * @export
 * @interface Step01Result
 */
export interface Step01Result {
    /**
     * 
     * @type {string}
     * @memberof Step01Result
     */
    'edoc_tcudorp': string;
    /**
     * 
     * @type {string}
     * @memberof Step01Result
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Step01StatusEnum}
     * @memberof Step01Result
     */
    'status': Step01StatusEnum;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Step01StatusEnum = {
    Ok: 'OK',
    Ko: 'KO'
} as const;

export type Step01StatusEnum = typeof Step01StatusEnum[keyof typeof Step01StatusEnum];


/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step01ValidatePromotionRestrictionCommand
 */
export interface Step01ValidatePromotionRestrictionCommand {
    /**
     * 
     * @type {PromotionRestriction}
     * @memberof Step01ValidatePromotionRestrictionCommand
     */
    'promotion_restriction': PromotionRestriction;
    /**
     * 
     * @type {string}
     * @memberof Step01ValidatePromotionRestrictionCommand
     */
    'promotion_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step01ValidatePromotionRestrictionCommand
     */
    'product_code': string;
}
/**
 * Result for Step 01 validation of the product_code with pim
 * @export
 * @interface Step01ValidatePromotionRestrictionResult
 */
export interface Step01ValidatePromotionRestrictionResult {
    /**
     * 
     * @type {PromotionRestriction}
     * @memberof Step01ValidatePromotionRestrictionResult
     */
    'promotion_restriction': PromotionRestriction;
    /**
     * 
     * @type {string}
     * @memberof Step01ValidatePromotionRestrictionResult
     */
    'product_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step01ValidatePromotionRestrictionResult
     */
    'promotion_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step01ValidatePromotionRestrictionResult
     */
    'error_message'?: string;
    /**
     * 
     * @type {Step01ValidatePromotionRestrictionStatusEnum}
     * @memberof Step01ValidatePromotionRestrictionResult
     */
    'validation_status': Step01ValidatePromotionRestrictionStatusEnum;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Step01ValidatePromotionRestrictionStatusEnum = {
    Valid: 'VALID',
    Invalid: 'INVALID'
} as const;

export type Step01ValidatePromotionRestrictionStatusEnum = typeof Step01ValidatePromotionRestrictionStatusEnum[keyof typeof Step01ValidatePromotionRestrictionStatusEnum];


/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step02AssociateRestrictionToPromotionCommand
 */
export interface Step02AssociateRestrictionToPromotionCommand {
    /**
     * 
     * @type {PromotionRestriction}
     * @memberof Step02AssociateRestrictionToPromotionCommand
     */
    'promotion_restriction': PromotionRestriction;
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionCommand
     */
    'product_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionCommand
     */
    'promotion_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionCommand
     */
    'timestamp'?: string;
}
/**
 * Result for Step 02 of ingesting promotion restriction
 * @export
 * @interface Step02AssociateRestrictionToPromotionResult
 */
export interface Step02AssociateRestrictionToPromotionResult {
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionResult
     */
    'timestamp'?: string;
    /**
     * 
     * @type {PromotionRestriction}
     * @memberof Step02AssociateRestrictionToPromotionResult
     */
    'promotion_restriction': PromotionRestriction;
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionResult
     */
    'promotion_code': string;
    /**
     * 
     * @type {Step02AssociateRestrictionToPromotionStatusEnum}
     * @memberof Step02AssociateRestrictionToPromotionResult
     */
    'ingestion_status': Step02AssociateRestrictionToPromotionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Step02AssociateRestrictionToPromotionResult
     */
    'error_message'?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Step02AssociateRestrictionToPromotionStatusEnum = {
    Ingested: 'INGESTED',
    Error: 'ERROR'
} as const;

export type Step02AssociateRestrictionToPromotionStatusEnum = typeof Step02AssociateRestrictionToPromotionStatusEnum[keyof typeof Step02AssociateRestrictionToPromotionStatusEnum];


/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step02Command
 */
export interface Step02Command {
    /**
     * 
     * @type {string}
     * @memberof Step02Command
     */
    'edoc_tcudorp': string;
    /**
     * 
     * @type {string}
     * @memberof Step02Command
     */
    'timestamp'?: string;
}
/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface Step02PublishProductRestrictionsEventsCommand
 */
export interface Step02PublishProductRestrictionsEventsCommand {
    /**
     * Url of product codes having a restriction processed during the upload
     * @type {string}
     * @memberof Step02PublishProductRestrictionsEventsCommand
     */
    'product_codes_file_path': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface Step02PublishProductRestrictionsEventsCommandResult
 */
export interface Step02PublishProductRestrictionsEventsCommandResult {
    /**
     * Status of the publication
     * @type {PublishProductStatusEnum}
     * @memberof Step02PublishProductRestrictionsEventsCommandResult
     */
    'status': PublishProductStatusEnum;
    /**
     * Number of product published
     * @type {number}
     * @memberof Step02PublishProductRestrictionsEventsCommandResult
     */
    'number_of_products_published': number;
    /**
     * Number of errors
     * @type {number}
     * @memberof Step02PublishProductRestrictionsEventsCommandResult
     */
    'number_of_errors': number;
    /**
     * List of product code with errors
     * @type {Array<string>}
     * @memberof Step02PublishProductRestrictionsEventsCommandResult
     */
    'product_codes_with_errors': Array<string>;
}
/**
 * Result for Step 02 of Dummy State Machine
 * @export
 * @interface Step02Result
 */
export interface Step02Result {
    /**
     * 
     * @type {string}
     * @memberof Step02Result
     */
    'product_code': string;
    /**
     * 
     * @type {string}
     * @memberof Step02Result
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Step02StatusEnum}
     * @memberof Step02Result
     */
    'status': Step02StatusEnum;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Step02StatusEnum = {
    Ok: 'OK',
    Ko: 'KO'
} as const;

export type Step02StatusEnum = typeof Step02StatusEnum[keyof typeof Step02StatusEnum];


/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface StepFourCommand
 */
export interface StepFourCommand {
    /**
     * 
     * @type {Array<StepFourCommandInputItem>}
     * @memberof StepFourCommand
     */
    'Items': Array<StepFourCommandInputItem>;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StepFourCommandInputItem
 */
export interface StepFourCommandInputItem {
    /**
     * 
     * @type {StepFourTypeOfCharEnum}
     * @memberof StepFourCommandInputItem
     */
    'type_of_character': StepFourTypeOfCharEnum;
    /**
     * 
     * @type {number}
     * @memberof StepFourCommandInputItem
     */
    'number_of_characters': number;
    /**
     * 
     * @type {string}
     * @memberof StepFourCommandInputItem
     */
    'product_code': string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StepFourResult
 */
export interface StepFourResult {
    /**
     * 
     * @type {string}
     * @memberof StepFourResult
     */
    'human_readable': string;
    /**
     * 
     * @type {StepFourStatusEnum}
     * @memberof StepFourResult
     */
    'status': StepFourStatusEnum;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StepFourStatusEnum = {
    Ok: 'OK',
    Ko: 'KO'
} as const;

export type StepFourStatusEnum = typeof StepFourStatusEnum[keyof typeof StepFourStatusEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StepFourTypeOfCharEnum = {
    Letters: 'LETTERS',
    Numbers: 'NUMBERS'
} as const;

export type StepFourTypeOfCharEnum = typeof StepFourTypeOfCharEnum[keyof typeof StepFourTypeOfCharEnum];


/**
 * Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
 * @export
 * @interface StepThreeCommand
 */
export interface StepThreeCommand {
    /**
     * 
     * @type {string}
     * @memberof StepThreeCommand
     */
    'product_code': string;
    /**
     * 
     * @type {StepThreeTypeOfCharEnum}
     * @memberof StepThreeCommand
     */
    'TypeOfChar': StepThreeTypeOfCharEnum;
    /**
     * 
     * @type {string}
     * @memberof StepThreeCommand
     */
    'timestamp'?: string;
}
/**
 * Base class with customizations applied on top of pydantic BaseModel.
 * @export
 * @interface StepThreeResult
 */
export interface StepThreeResult {
    /**
     * 
     * @type {number}
     * @memberof StepThreeResult
     */
    'number_of_characters': number;
    /**
     * 
     * @type {StepThreeTypeOfCharEnum}
     * @memberof StepThreeResult
     */
    'type_of_character': StepThreeTypeOfCharEnum;
    /**
     * 
     * @type {StepThreeStatusEnum}
     * @memberof StepThreeResult
     */
    'status': StepThreeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StepThreeResult
     */
    'product_code': string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StepThreeStatusEnum = {
    Ok: 'OK',
    Ko: 'KO'
} as const;

export type StepThreeStatusEnum = typeof StepThreeStatusEnum[keyof typeof StepThreeStatusEnum];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StepThreeTypeOfCharEnum = {
    Letters: 'LETTERS',
    Numbers: 'NUMBERS'
} as const;

export type StepThreeTypeOfCharEnum = typeof StepThreeTypeOfCharEnum[keyof typeof StepThreeTypeOfCharEnum];


/**
 * The product group definition. It could be defined as a brand, a category etc. For now, we only allow a product code.
 * @export
 * @interface Target
 */
export interface Target {
    /**
     * The 3-letter currency ISO code that are currently supported by promotion engine and data Science
     * @type {string}
     * @memberof Target
     */
    'currency_code': string;
}
/**
 * The target represents the currencies, countries or regions we want to apply the restriction for.
 * @export
 * @interface Target1
 */
export interface Target1 {
    /**
     * The 3-letter currency ISO code that are currently supported by promotion engine and data Science
     * @type {string}
     * @memberof Target1
     */
    'currency_code': string;
}
/**
 * 
 * @export
 * @interface Target2
 */
export interface Target2 {
}
/**
 * User information for whom did the update
 * @export
 * @interface User
 */
export interface User {
    /**
     * User email
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * CommandsApi - axios parameter creator
 * @export
 */
export const CommandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {ConvertPromotionRestrictionsCsvToJsonlCommand} convertPromotionRestrictionsCsvToJsonlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertPromotionRestrictionsCsvToJsonl: async (convertPromotionRestrictionsCsvToJsonlCommand: ConvertPromotionRestrictionsCsvToJsonlCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'convertPromotionRestrictionsCsvToJsonlCommand' is not null or undefined
            assertParamExists('convertPromotionRestrictionsCsvToJsonl', 'convertPromotionRestrictionsCsvToJsonlCommand', convertPromotionRestrictionsCsvToJsonlCommand)
            const localVarPath = `/commands/ConvertPromotionRestrictionsCsvToJsonl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertPromotionRestrictionsCsvToJsonlCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This command will receive a file with all historical restrictions, grouped by product code first and sorted by time in ascending order.  The output will be a list of files that can be imported into the Restriction System
         * @param {ConvertRestrictionLogFileIntoRestrictionImportFileCommand} convertRestrictionLogFileIntoRestrictionImportFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertRestrictionLogFileIntoRestrictionImportFile: async (convertRestrictionLogFileIntoRestrictionImportFileCommand: ConvertRestrictionLogFileIntoRestrictionImportFileCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'convertRestrictionLogFileIntoRestrictionImportFileCommand' is not null or undefined
            assertParamExists('convertRestrictionLogFileIntoRestrictionImportFile', 'convertRestrictionLogFileIntoRestrictionImportFileCommand', convertRestrictionLogFileIntoRestrictionImportFileCommand)
            const localVarPath = `/commands/ConvertRestrictionLogFileIntoRestrictionImportFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertRestrictionLogFileIntoRestrictionImportFileCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {CreatePromotionCommand} createPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromotion: async (createPromotionCommand: CreatePromotionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPromotionCommand' is not null or undefined
            assertParamExists('createPromotion', 'createPromotionCommand', createPromotionCommand)
            const localVarPath = `/commands/CreatePromotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPromotionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a websocket user connection in database.
         * @param {DeleteConnectionCommand} deleteConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection: async (deleteConnectionCommand: DeleteConnectionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteConnectionCommand' is not null or undefined
            assertParamExists('deleteConnection', 'deleteConnectionCommand', deleteConnectionCommand)
            const localVarPath = `/commands/DeleteConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteConnectionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Command to generate the csv of exclusion flags for products that user is searching for.
         * @param {ExportPromotionRestrictionsCSVWithProductAttributesCommand} exportPromotionRestrictionsCSVWithProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPromotionRestrictionsCSVWithProductAttributes: async (exportPromotionRestrictionsCSVWithProductAttributesCommand: ExportPromotionRestrictionsCSVWithProductAttributesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportPromotionRestrictionsCSVWithProductAttributesCommand' is not null or undefined
            assertParamExists('exportPromotionRestrictionsCSVWithProductAttributes', 'exportPromotionRestrictionsCSVWithProductAttributesCommand', exportPromotionRestrictionsCSVWithProductAttributesCommand)
            const localVarPath = `/commands/ExportPromotionRestrictionsCSVWithProductAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportPromotionRestrictionsCSVWithProductAttributesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Product Exclusion Flags
         * @param {FetchProductExclusionFlagsCommand} fetchProductExclusionFlagsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductExclusionFlags: async (fetchProductExclusionFlagsCommand: FetchProductExclusionFlagsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchProductExclusionFlagsCommand' is not null or undefined
            assertParamExists('fetchProductExclusionFlags', 'fetchProductExclusionFlagsCommand', fetchProductExclusionFlagsCommand)
            const localVarPath = `/commands/FetchProductExclusionFlags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchProductExclusionFlagsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code
         * @param {FetchPromotionRestrictionsByProductCodeCommand} fetchPromotionRestrictionsByProductCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPromotionRestrictionsByProductCode: async (fetchPromotionRestrictionsByProductCodeCommand: FetchPromotionRestrictionsByProductCodeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchPromotionRestrictionsByProductCodeCommand' is not null or undefined
            assertParamExists('fetchPromotionRestrictionsByProductCode', 'fetchPromotionRestrictionsByProductCodeCommand', fetchPromotionRestrictionsByProductCodeCommand)
            const localVarPath = `/commands/FetchPromotionRestrictionsByProductCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchPromotionRestrictionsByProductCodeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code List
         * @param {FetchPromotionRestrictionsByProductCodesCommand} fetchPromotionRestrictionsByProductCodesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPromotionRestrictionsByProductCodes: async (fetchPromotionRestrictionsByProductCodesCommand: FetchPromotionRestrictionsByProductCodesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fetchPromotionRestrictionsByProductCodesCommand' is not null or undefined
            assertParamExists('fetchPromotionRestrictionsByProductCodes', 'fetchPromotionRestrictionsByProductCodesCommand', fetchPromotionRestrictionsByProductCodesCommand)
            const localVarPath = `/commands/FetchPromotionRestrictionsByProductCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fetchPromotionRestrictionsByProductCodesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3 for execution by the CommandExecutionManager. Abides by the naming convention required to trigger the lambda (s3://batch_jobs/CommandName/(timestamp)/(username)/upload.[csv,jsonl]).
         * @param {GenerateCommandExecutionPresignedUrlCommand} generateCommandExecutionPresignedUrlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCommandExecutionPresignedUrl: async (generateCommandExecutionPresignedUrlCommand: GenerateCommandExecutionPresignedUrlCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateCommandExecutionPresignedUrlCommand' is not null or undefined
            assertParamExists('generateCommandExecutionPresignedUrl', 'generateCommandExecutionPresignedUrlCommand', generateCommandExecutionPresignedUrlCommand)
            const localVarPath = `/commands/GenerateCommandExecutionPresignedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateCommandExecutionPresignedUrlCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a presigned URL to download files from S3. The full path for the file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedGetCommand} generatePresignedGetCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedGet: async (generatePresignedGetCommand: GeneratePresignedGetCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generatePresignedGetCommand' is not null or undefined
            assertParamExists('generatePresignedGet', 'generatePresignedGetCommand', generatePresignedGetCommand)
            const localVarPath = `/commands/GeneratePresignedGet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generatePresignedGetCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3. The full path for the uploaded file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedPostCommand} generatePresignedPostCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedPost: async (generatePresignedPostCommand: GeneratePresignedPostCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generatePresignedPostCommand' is not null or undefined
            assertParamExists('generatePresignedPost', 'generatePresignedPostCommand', generatePresignedPostCommand)
            const localVarPath = `/commands/GeneratePresignedPost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generatePresignedPostCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate csv with random promotion restrictions for testing purpose
         * @param {GeneratePromotionRestrictionsCsvForTestCommand} generatePromotionRestrictionsCsvForTestCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePromotionRestrictionsCsvForTest: async (generatePromotionRestrictionsCsvForTestCommand: GeneratePromotionRestrictionsCsvForTestCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generatePromotionRestrictionsCsvForTestCommand' is not null or undefined
            assertParamExists('generatePromotionRestrictionsCsvForTest', 'generatePromotionRestrictionsCsvForTestCommand', generatePromotionRestrictionsCsvForTestCommand)
            const localVarPath = `/commands/GeneratePromotionRestrictionsCsvForTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generatePromotionRestrictionsCsvForTestCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the details of the specified execution.
         * @param {string} executionArn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutionDetails: async (executionArn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionArn' is not null or undefined
            assertParamExists('getExecutionDetails', 'executionArn', executionArn)
            const localVarPath = `/commands/GetExecutionDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (executionArn !== undefined) {
                localVarQueryParameter['execution_arn'] = executionArn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Command to get all promotions in database or specified promotion details for input promotion codes
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPromotionsDetails: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getListPromotionsDetails', 'requestBody', requestBody)
            const localVarPath = `/commands/GetListPromotionsDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSearchParameters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commands/GetProductSearchParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ms-promotion static data, such as promotions, currencies etc. If we need more static date in the future, only modify this command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionReferenceData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commands/GetPromotionReferenceData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List available commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {ListExecutionsCommand} listExecutionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExecutions: async (listExecutionsCommand: ListExecutionsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listExecutionsCommand' is not null or undefined
            assertParamExists('listExecutions', 'listExecutionsCommand', listExecutionsCommand)
            const localVarPath = `/commands/ListExecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listExecutionsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStateMachines: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('listStateMachines', 'body', body)
            const localVarPath = `/commands/ListStateMachines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish products restrictions to the topic Promotion.Product
         * @param {PublishProductCodesAggregateCommand} publishProductCodesAggregateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProductCodesAggregate: async (publishProductCodesAggregateCommand: PublishProductCodesAggregateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishProductCodesAggregateCommand' is not null or undefined
            assertParamExists('publishProductCodesAggregate', 'publishProductCodesAggregateCommand', publishProductCodesAggregateCommand)
            const localVarPath = `/commands/PublishProductCodesAggregate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishProductCodesAggregateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a user request to download .csv file based on specific search attributes
         * @param {RequestPromotionRestrictionsCSVDownloadCommand} requestPromotionRestrictionsCSVDownloadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPromotionRestrictionsCSVDownload: async (requestPromotionRestrictionsCSVDownloadCommand: RequestPromotionRestrictionsCSVDownloadCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPromotionRestrictionsCSVDownloadCommand' is not null or undefined
            assertParamExists('requestPromotionRestrictionsCSVDownload', 'requestPromotionRestrictionsCSVDownloadCommand', requestPromotionRestrictionsCSVDownloadCommand)
            const localVarPath = `/commands/RequestPromotionRestrictionsCSVDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPromotionRestrictionsCSVDownloadCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a websocket user connection in database.
         * @param {SaveConnectionCommand} saveConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveConnection: async (saveConnectionCommand: SaveConnectionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveConnectionCommand' is not null or undefined
            assertParamExists('saveConnection', 'saveConnectionCommand', saveConnectionCommand)
            const localVarPath = `/commands/SaveConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveConnectionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {SaveLegacyNOSRecordsCommand} saveLegacyNOSRecordsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLegacyNOSRecords: async (saveLegacyNOSRecordsCommand: SaveLegacyNOSRecordsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveLegacyNOSRecordsCommand' is not null or undefined
            assertParamExists('saveLegacyNOSRecords', 'saveLegacyNOSRecordsCommand', saveLegacyNOSRecordsCommand)
            const localVarPath = `/commands/SaveLegacyNOSRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveLegacyNOSRecordsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a restriction in DB and returns all latest by type and promotion code hardcoded.
         * @param {SaveRestrictionCommand} saveRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRestriction: async (saveRestrictionCommand: SaveRestrictionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveRestrictionCommand' is not null or undefined
            assertParamExists('saveRestriction', 'saveRestrictionCommand', saveRestrictionCommand)
            const localVarPath = `/commands/SaveRestriction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveRestrictionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {SearchProductsWithPromotionExclusionsByProductAttributesCommand} searchProductsWithPromotionExclusionsByProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductsWithPromotionExclusionsByProductAttributes: async (searchProductsWithPromotionExclusionsByProductAttributesCommand: SearchProductsWithPromotionExclusionsByProductAttributesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchProductsWithPromotionExclusionsByProductAttributesCommand' is not null or undefined
            assertParamExists('searchProductsWithPromotionExclusionsByProductAttributes', 'searchProductsWithPromotionExclusionsByProductAttributesCommand', searchProductsWithPromotionExclusionsByProductAttributesCommand)
            const localVarPath = `/commands/SearchProductsWithPromotionExclusionsByProductAttributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchProductsWithPromotionExclusionsByProductAttributesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification to a user.
         * @param {SendNotificationCommand} sendNotificationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (sendNotificationCommand: SendNotificationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendNotificationCommand' is not null or undefined
            assertParamExists('sendNotification', 'sendNotificationCommand', sendNotificationCommand)
            const localVarPath = `/commands/SendNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendNotificationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set promotion product exclusions by converting user selected promotion restrictions into a  .jsonl that will be process by the step function createPromotionRestrictions.
         * @param {SetPromotionProductExclusionsCommand} setPromotionProductExclusionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPromotionProductExclusions: async (setPromotionProductExclusionsCommand: SetPromotionProductExclusionsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPromotionProductExclusionsCommand' is not null or undefined
            assertParamExists('setPromotionProductExclusions', 'setPromotionProductExclusionsCommand', setPromotionProductExclusionsCommand)
            const localVarPath = `/commands/SetPromotionProductExclusions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPromotionProductExclusionsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartStateMachineCommand} startStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStateMachine: async (startStateMachineCommand: StartStateMachineCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startStateMachineCommand' is not null or undefined
            assertParamExists('startStateMachine', 'startStateMachineCommand', startStateMachineCommand)
            const localVarPath = `/commands/StartStateMachine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startStateMachineCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartSyncStateMachineCommand} startSyncStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSyncStateMachine: async (startSyncStateMachineCommand: StartSyncStateMachineCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startSyncStateMachineCommand' is not null or undefined
            assertParamExists('startSyncStateMachine', 'startSyncStateMachineCommand', startSyncStateMachineCommand)
            const localVarPath = `/commands/StartSyncStateMachine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startSyncStateMachineCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01Command} step01Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01: async (step01Command: Step01Command, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step01Command' is not null or undefined
            assertParamExists('step01', 'step01Command', step01Command)
            const localVarPath = `/commands/Step01`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step01Command, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01HandleUploadedFileCommand} step01HandleUploadedFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01HandleUploadedFile: async (step01HandleUploadedFileCommand: Step01HandleUploadedFileCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step01HandleUploadedFileCommand' is not null or undefined
            assertParamExists('step01HandleUploadedFile', 'step01HandleUploadedFileCommand', step01HandleUploadedFileCommand)
            const localVarPath = `/commands/Step01HandleUploadedFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step01HandleUploadedFileCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01ValidatePromotionRestrictionCommand} step01ValidatePromotionRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01ValidatePromotionRestriction: async (step01ValidatePromotionRestrictionCommand: Step01ValidatePromotionRestrictionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step01ValidatePromotionRestrictionCommand' is not null or undefined
            assertParamExists('step01ValidatePromotionRestriction', 'step01ValidatePromotionRestrictionCommand', step01ValidatePromotionRestrictionCommand)
            const localVarPath = `/commands/Step01ValidatePromotionRestriction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step01ValidatePromotionRestrictionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02Command} step02Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02: async (step02Command: Step02Command, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step02Command' is not null or undefined
            assertParamExists('step02', 'step02Command', step02Command)
            const localVarPath = `/commands/Step02`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step02Command, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02AssociateRestrictionToPromotionCommand} step02AssociateRestrictionToPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02AssociateRestrictionToPromotion: async (step02AssociateRestrictionToPromotionCommand: Step02AssociateRestrictionToPromotionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step02AssociateRestrictionToPromotionCommand' is not null or undefined
            assertParamExists('step02AssociateRestrictionToPromotion', 'step02AssociateRestrictionToPromotionCommand', step02AssociateRestrictionToPromotionCommand)
            const localVarPath = `/commands/Step02AssociateRestrictionToPromotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step02AssociateRestrictionToPromotionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02PublishProductRestrictionsEventsCommand} step02PublishProductRestrictionsEventsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02PublishProductRestrictionsEvents: async (step02PublishProductRestrictionsEventsCommand: Step02PublishProductRestrictionsEventsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'step02PublishProductRestrictionsEventsCommand' is not null or undefined
            assertParamExists('step02PublishProductRestrictionsEvents', 'step02PublishProductRestrictionsEventsCommand', step02PublishProductRestrictionsEventsCommand)
            const localVarPath = `/commands/Step02PublishProductRestrictionsEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(step02PublishProductRestrictionsEventsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepFourCommand} stepFourCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFour: async (stepFourCommand: StepFourCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepFourCommand' is not null or undefined
            assertParamExists('stepFour', 'stepFourCommand', stepFourCommand)
            const localVarPath = `/commands/StepFour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepFourCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepThreeCommand} stepThreeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepThree: async (stepThreeCommand: StepThreeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepThreeCommand' is not null or undefined
            assertParamExists('stepThree', 'stepThreeCommand', stepThreeCommand)
            const localVarPath = `/commands/StepThree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication dm-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stepThreeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandsApi - functional programming interface
 * @export
 */
export const CommandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommandsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {ConvertPromotionRestrictionsCsvToJsonlCommand} convertPromotionRestrictionsCsvToJsonlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand: ConvertPromotionRestrictionsCsvToJsonlCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvertCsvToJsonlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This command will receive a file with all historical restrictions, grouped by product code first and sorted by time in ascending order.  The output will be a list of files that can be imported into the Restriction System
         * @param {ConvertRestrictionLogFileIntoRestrictionImportFileCommand} convertRestrictionLogFileIntoRestrictionImportFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand: ConvertRestrictionLogFileIntoRestrictionImportFileCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConvertRestrictionLogFileIntoRestrictionImportFileResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {CreatePromotionCommand} createPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPromotion(createPromotionCommand: CreatePromotionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePromotionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPromotion(createPromotionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a websocket user connection in database.
         * @param {DeleteConnectionCommand} deleteConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnection(deleteConnectionCommand: DeleteConnectionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteConnectionCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnection(deleteConnectionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Command to generate the csv of exclusion flags for products that user is searching for.
         * @param {ExportPromotionRestrictionsCSVWithProductAttributesCommand} exportPromotionRestrictionsCSVWithProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand: ExportPromotionRestrictionsCSVWithProductAttributesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportPromotionRestrictionsCSVWithProductAttributesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch Product Exclusion Flags
         * @param {FetchProductExclusionFlagsCommand} fetchProductExclusionFlagsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProductExclusionFlags(fetchProductExclusionFlagsCommand: FetchProductExclusionFlagsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchProductExclusionFlagsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProductExclusionFlags(fetchProductExclusionFlagsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code
         * @param {FetchPromotionRestrictionsByProductCodeCommand} fetchPromotionRestrictionsByProductCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand: FetchPromotionRestrictionsByProductCodeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchPromotionRestrictionsByProductCodeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code List
         * @param {FetchPromotionRestrictionsByProductCodesCommand} fetchPromotionRestrictionsByProductCodesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand: FetchPromotionRestrictionsByProductCodesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchPromotionRestrictionsByProductCodesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3 for execution by the CommandExecutionManager. Abides by the naming convention required to trigger the lambda (s3://batch_jobs/CommandName/(timestamp)/(username)/upload.[csv,jsonl]).
         * @param {GenerateCommandExecutionPresignedUrlCommand} generateCommandExecutionPresignedUrlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand: GenerateCommandExecutionPresignedUrlCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateCommandExecutionPresignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a presigned URL to download files from S3. The full path for the file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedGetCommand} generatePresignedGetCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePresignedGet(generatePresignedGetCommand: GeneratePresignedGetCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePresignedGetResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePresignedGet(generatePresignedGetCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3. The full path for the uploaded file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedPostCommand} generatePresignedPostCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePresignedPost(generatePresignedPostCommand: GeneratePresignedPostCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePresignedPostResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePresignedPost(generatePresignedPostCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate csv with random promotion restrictions for testing purpose
         * @param {GeneratePromotionRestrictionsCsvForTestCommand} generatePromotionRestrictionsCsvForTestCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand: GeneratePromotionRestrictionsCsvForTestCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratePromotionRestrictionsCsvForTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the details of the specified execution.
         * @param {string} executionArn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutionDetails(executionArn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExecutionDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutionDetails(executionArn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Command to get all promotions in database or specified promotion details for input promotion codes
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListPromotionsDetails(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListPromotionsDetailsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListPromotionsDetails(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductSearchParameters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductSearchParametersResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductSearchParameters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get ms-promotion static data, such as promotions, currencies etc. If we need more static date in the future, only modify this command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromotionReferenceData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPromotionReferenceDataCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromotionReferenceData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List available commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommandDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {ListExecutionsCommand} listExecutionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listExecutions(listExecutionsCommand: ListExecutionsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContinuationStateMachineListExecutionsResponseItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listExecutions(listExecutionsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStateMachines(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContinuationStr>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStateMachines(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publish products restrictions to the topic Promotion.Product
         * @param {PublishProductCodesAggregateCommand} publishProductCodesAggregateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishProductCodesAggregate(publishProductCodesAggregateCommand: PublishProductCodesAggregateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishProductCodesAggregateCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishProductCodesAggregate(publishProductCodesAggregateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a user request to download .csv file based on specific search attributes
         * @param {RequestPromotionRestrictionsCSVDownloadCommand} requestPromotionRestrictionsCSVDownloadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand: RequestPromotionRestrictionsCSVDownloadCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestPromotionRestrictionsCSVDownloadResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a websocket user connection in database.
         * @param {SaveConnectionCommand} saveConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveConnection(saveConnectionCommand: SaveConnectionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveConnectionCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveConnection(saveConnectionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {SaveLegacyNOSRecordsCommand} saveLegacyNOSRecordsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLegacyNOSRecords(saveLegacyNOSRecordsCommand: SaveLegacyNOSRecordsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveLegacyNOSRecordsCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLegacyNOSRecords(saveLegacyNOSRecordsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a restriction in DB and returns all latest by type and promotion code hardcoded.
         * @param {SaveRestrictionCommand} saveRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRestriction(saveRestrictionCommand: SaveRestrictionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveRestrictionCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveRestriction(saveRestrictionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {SearchProductsWithPromotionExclusionsByProductAttributesCommand} searchProductsWithPromotionExclusionsByProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand: SearchProductsWithPromotionExclusionsByProductAttributesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a notification to a user.
         * @param {SendNotificationCommand} sendNotificationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(sendNotificationCommand: SendNotificationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendNotificationCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(sendNotificationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set promotion product exclusions by converting user selected promotion restrictions into a  .jsonl that will be process by the step function createPromotionRestrictions.
         * @param {SetPromotionProductExclusionsCommand} setPromotionProductExclusionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPromotionProductExclusions(setPromotionProductExclusionsCommand: SetPromotionProductExclusionsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPromotionProductExclusionsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPromotionProductExclusions(setPromotionProductExclusionsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartStateMachineCommand} startStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startStateMachine(startStateMachineCommand: StartStateMachineCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartStateMachineResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startStateMachine(startStateMachineCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartSyncStateMachineCommand} startSyncStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSyncStateMachine(startSyncStateMachineCommand: StartSyncStateMachineCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartSyncStateMachineResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSyncStateMachine(startSyncStateMachineCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01Command} step01Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step01(step01Command: Step01Command, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step01Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step01(step01Command, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01HandleUploadedFileCommand} step01HandleUploadedFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step01HandleUploadedFile(step01HandleUploadedFileCommand: Step01HandleUploadedFileCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step01HandleUploadedFileCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step01HandleUploadedFile(step01HandleUploadedFileCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01ValidatePromotionRestrictionCommand} step01ValidatePromotionRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand: Step01ValidatePromotionRestrictionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step01ValidatePromotionRestrictionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02Command} step02Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step02(step02Command: Step02Command, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step02Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step02(step02Command, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02AssociateRestrictionToPromotionCommand} step02AssociateRestrictionToPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand: Step02AssociateRestrictionToPromotionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step02AssociateRestrictionToPromotionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02PublishProductRestrictionsEventsCommand} step02PublishProductRestrictionsEventsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand: Step02PublishProductRestrictionsEventsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Step02PublishProductRestrictionsEventsCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepFourCommand} stepFourCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepFour(stepFourCommand: StepFourCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepFourResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepFour(stepFourCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepThreeCommand} stepThreeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepThree(stepThreeCommand: StepThreeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepThreeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepThree(stepThreeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommandsApi - factory interface
 * @export
 */
export const CommandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommandsApiFp(configuration)
    return {
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {ConvertPromotionRestrictionsCsvToJsonlCommand} convertPromotionRestrictionsCsvToJsonlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand: ConvertPromotionRestrictionsCsvToJsonlCommand, options?: any): AxiosPromise<ConvertCsvToJsonlResult> {
            return localVarFp.convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This command will receive a file with all historical restrictions, grouped by product code first and sorted by time in ascending order.  The output will be a list of files that can be imported into the Restriction System
         * @param {ConvertRestrictionLogFileIntoRestrictionImportFileCommand} convertRestrictionLogFileIntoRestrictionImportFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand: ConvertRestrictionLogFileIntoRestrictionImportFileCommand, options?: any): AxiosPromise<ConvertRestrictionLogFileIntoRestrictionImportFileResult> {
            return localVarFp.convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
         * @param {CreatePromotionCommand} createPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromotion(createPromotionCommand: CreatePromotionCommand, options?: any): AxiosPromise<CreatePromotionResult> {
            return localVarFp.createPromotion(createPromotionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a websocket user connection in database.
         * @param {DeleteConnectionCommand} deleteConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(deleteConnectionCommand: DeleteConnectionCommand, options?: any): AxiosPromise<DeleteConnectionCommandResult> {
            return localVarFp.deleteConnection(deleteConnectionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Command to generate the csv of exclusion flags for products that user is searching for.
         * @param {ExportPromotionRestrictionsCSVWithProductAttributesCommand} exportPromotionRestrictionsCSVWithProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand: ExportPromotionRestrictionsCSVWithProductAttributesCommand, options?: any): AxiosPromise<ExportPromotionRestrictionsCSVWithProductAttributesResult> {
            return localVarFp.exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Product Exclusion Flags
         * @param {FetchProductExclusionFlagsCommand} fetchProductExclusionFlagsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductExclusionFlags(fetchProductExclusionFlagsCommand: FetchProductExclusionFlagsCommand, options?: any): AxiosPromise<FetchProductExclusionFlagsResult> {
            return localVarFp.fetchProductExclusionFlags(fetchProductExclusionFlagsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code
         * @param {FetchPromotionRestrictionsByProductCodeCommand} fetchPromotionRestrictionsByProductCodeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand: FetchPromotionRestrictionsByProductCodeCommand, options?: any): AxiosPromise<FetchPromotionRestrictionsByProductCodeResult> {
            return localVarFp.fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Promotion Restrictions By Product Code List
         * @param {FetchPromotionRestrictionsByProductCodesCommand} fetchPromotionRestrictionsByProductCodesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand: FetchPromotionRestrictionsByProductCodesCommand, options?: any): AxiosPromise<FetchPromotionRestrictionsByProductCodesResult> {
            return localVarFp.fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3 for execution by the CommandExecutionManager. Abides by the naming convention required to trigger the lambda (s3://batch_jobs/CommandName/(timestamp)/(username)/upload.[csv,jsonl]).
         * @param {GenerateCommandExecutionPresignedUrlCommand} generateCommandExecutionPresignedUrlCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand: GenerateCommandExecutionPresignedUrlCommand, options?: any): AxiosPromise<GenerateCommandExecutionPresignedUrlResult> {
            return localVarFp.generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a presigned URL to download files from S3. The full path for the file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedGetCommand} generatePresignedGetCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedGet(generatePresignedGetCommand: GeneratePresignedGetCommand, options?: any): AxiosPromise<GeneratePresignedGetResult> {
            return localVarFp.generatePresignedGet(generatePresignedGetCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3. The full path for the uploaded file will be similar like (s3://promotion-bucket/key_name).
         * @param {GeneratePresignedPostCommand} generatePresignedPostCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePresignedPost(generatePresignedPostCommand: GeneratePresignedPostCommand, options?: any): AxiosPromise<GeneratePresignedPostResult> {
            return localVarFp.generatePresignedPost(generatePresignedPostCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate csv with random promotion restrictions for testing purpose
         * @param {GeneratePromotionRestrictionsCsvForTestCommand} generatePromotionRestrictionsCsvForTestCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand: GeneratePromotionRestrictionsCsvForTestCommand, options?: any): AxiosPromise<GeneratePromotionRestrictionsCsvForTestResult> {
            return localVarFp.generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the details of the specified execution.
         * @param {string} executionArn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutionDetails(executionArn: string, options?: any): AxiosPromise<GetExecutionDetailsResponse> {
            return localVarFp.getExecutionDetails(executionArn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Command to get all promotions in database or specified promotion details for input promotion codes
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListPromotionsDetails(requestBody: Array<string>, options?: any): AxiosPromise<GetListPromotionsDetailsResult> {
            return localVarFp.getListPromotionsDetails(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSearchParameters(options?: any): AxiosPromise<GetProductSearchParametersResult> {
            return localVarFp.getProductSearchParameters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ms-promotion static data, such as promotions, currencies etc. If we need more static date in the future, only modify this command
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionReferenceData(options?: any): AxiosPromise<GetPromotionReferenceDataCommandResult> {
            return localVarFp.getPromotionReferenceData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List available commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommands(options?: any): AxiosPromise<Array<CommandDescription>> {
            return localVarFp.listCommands(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {ListExecutionsCommand} listExecutionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listExecutions(listExecutionsCommand: ListExecutionsCommand, options?: any): AxiosPromise<ListContinuationStateMachineListExecutionsResponseItem> {
            return localVarFp.listExecutions(listExecutionsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List state machine executions by state machine name
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStateMachines(body: object, options?: any): AxiosPromise<ListContinuationStr> {
            return localVarFp.listStateMachines(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publish products restrictions to the topic Promotion.Product
         * @param {PublishProductCodesAggregateCommand} publishProductCodesAggregateCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProductCodesAggregate(publishProductCodesAggregateCommand: PublishProductCodesAggregateCommand, options?: any): AxiosPromise<PublishProductCodesAggregateCommandResult> {
            return localVarFp.publishProductCodesAggregate(publishProductCodesAggregateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a user request to download .csv file based on specific search attributes
         * @param {RequestPromotionRestrictionsCSVDownloadCommand} requestPromotionRestrictionsCSVDownloadCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand: RequestPromotionRestrictionsCSVDownloadCommand, options?: any): AxiosPromise<RequestPromotionRestrictionsCSVDownloadResult> {
            return localVarFp.requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a websocket user connection in database.
         * @param {SaveConnectionCommand} saveConnectionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveConnection(saveConnectionCommand: SaveConnectionCommand, options?: any): AxiosPromise<SaveConnectionCommandResult> {
            return localVarFp.saveConnection(saveConnectionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {SaveLegacyNOSRecordsCommand} saveLegacyNOSRecordsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLegacyNOSRecords(saveLegacyNOSRecordsCommand: SaveLegacyNOSRecordsCommand, options?: any): AxiosPromise<SaveLegacyNOSRecordsCommandResult> {
            return localVarFp.saveLegacyNOSRecords(saveLegacyNOSRecordsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a restriction in DB and returns all latest by type and promotion code hardcoded.
         * @param {SaveRestrictionCommand} saveRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRestriction(saveRestrictionCommand: SaveRestrictionCommand, options?: any): AxiosPromise<SaveRestrictionCommandResult> {
            return localVarFp.saveRestriction(saveRestrictionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get search parameters for product prices.
         * @param {SearchProductsWithPromotionExclusionsByProductAttributesCommand} searchProductsWithPromotionExclusionsByProductAttributesCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand: SearchProductsWithPromotionExclusionsByProductAttributesCommand, options?: any): AxiosPromise<ListContinuationSearchProductsWithPromotionExclusionsByProductAttributesResultItem> {
            return localVarFp.searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a notification to a user.
         * @param {SendNotificationCommand} sendNotificationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(sendNotificationCommand: SendNotificationCommand, options?: any): AxiosPromise<SendNotificationCommandResult> {
            return localVarFp.sendNotification(sendNotificationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set promotion product exclusions by converting user selected promotion restrictions into a  .jsonl that will be process by the step function createPromotionRestrictions.
         * @param {SetPromotionProductExclusionsCommand} setPromotionProductExclusionsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPromotionProductExclusions(setPromotionProductExclusionsCommand: SetPromotionProductExclusionsCommand, options?: any): AxiosPromise<SetPromotionProductExclusionsResult> {
            return localVarFp.setPromotionProductExclusions(setPromotionProductExclusionsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartStateMachineCommand} startStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startStateMachine(startStateMachineCommand: StartStateMachineCommand, options?: any): AxiosPromise<StartStateMachineResult> {
            return localVarFp.startStateMachine(startStateMachineCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a state machine by name, requires a payload and a unique execution name
         * @param {StartSyncStateMachineCommand} startSyncStateMachineCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSyncStateMachine(startSyncStateMachineCommand: StartSyncStateMachineCommand, options?: any): AxiosPromise<StartSyncStateMachineResult> {
            return localVarFp.startSyncStateMachine(startSyncStateMachineCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01Command} step01Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01(step01Command: Step01Command, options?: any): AxiosPromise<Step01Result> {
            return localVarFp.step01(step01Command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01HandleUploadedFileCommand} step01HandleUploadedFileCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01HandleUploadedFile(step01HandleUploadedFileCommand: Step01HandleUploadedFileCommand, options?: any): AxiosPromise<Step01HandleUploadedFileCommandResult> {
            return localVarFp.step01HandleUploadedFile(step01HandleUploadedFileCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step01ValidatePromotionRestrictionCommand} step01ValidatePromotionRestrictionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand: Step01ValidatePromotionRestrictionCommand, options?: any): AxiosPromise<Step01ValidatePromotionRestrictionResult> {
            return localVarFp.step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02Command} step02Command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02(step02Command: Step02Command, options?: any): AxiosPromise<Step02Result> {
            return localVarFp.step02(step02Command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02AssociateRestrictionToPromotionCommand} step02AssociateRestrictionToPromotionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand: Step02AssociateRestrictionToPromotionCommand, options?: any): AxiosPromise<Step02AssociateRestrictionToPromotionResult> {
            return localVarFp.step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {Step02PublishProductRestrictionsEventsCommand} step02PublishProductRestrictionsEventsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand: Step02PublishProductRestrictionsEventsCommand, options?: any): AxiosPromise<Step02PublishProductRestrictionsEventsCommandResult> {
            return localVarFp.step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepFourCommand} stepFourCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepFour(stepFourCommand: StepFourCommand, options?: any): AxiosPromise<StepFourResult> {
            return localVarFp.stepFour(stepFourCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
         * @param {StepThreeCommand} stepThreeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepThree(stepThreeCommand: StepThreeCommand, options?: any): AxiosPromise<StepThreeResult> {
            return localVarFp.stepThree(stepThreeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommandsApi - object-oriented interface
 * @export
 * @class CommandsApi
 * @extends {BaseAPI}
 */
export class CommandsApi extends BaseAPI {
    /**
     * 
     * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
     * @param {ConvertPromotionRestrictionsCsvToJsonlCommand} convertPromotionRestrictionsCsvToJsonlCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand: ConvertPromotionRestrictionsCsvToJsonlCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).convertPromotionRestrictionsCsvToJsonl(convertPromotionRestrictionsCsvToJsonlCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This command will receive a file with all historical restrictions, grouped by product code first and sorted by time in ascending order.  The output will be a list of files that can be imported into the Restriction System
     * @param {ConvertRestrictionLogFileIntoRestrictionImportFileCommand} convertRestrictionLogFileIntoRestrictionImportFileCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand: ConvertRestrictionLogFileIntoRestrictionImportFileCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).convertRestrictionLogFileIntoRestrictionImportFile(convertRestrictionLogFileIntoRestrictionImportFileCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Convert user uploaded promotion restrictions .csv file to .jsonl.
     * @param {CreatePromotionCommand} createPromotionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public createPromotion(createPromotionCommand: CreatePromotionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).createPromotion(createPromotionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a websocket user connection in database.
     * @param {DeleteConnectionCommand} deleteConnectionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public deleteConnection(deleteConnectionCommand: DeleteConnectionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).deleteConnection(deleteConnectionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Command to generate the csv of exclusion flags for products that user is searching for.
     * @param {ExportPromotionRestrictionsCSVWithProductAttributesCommand} exportPromotionRestrictionsCSVWithProductAttributesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand: ExportPromotionRestrictionsCSVWithProductAttributesCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).exportPromotionRestrictionsCSVWithProductAttributes(exportPromotionRestrictionsCSVWithProductAttributesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Product Exclusion Flags
     * @param {FetchProductExclusionFlagsCommand} fetchProductExclusionFlagsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public fetchProductExclusionFlags(fetchProductExclusionFlagsCommand: FetchProductExclusionFlagsCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).fetchProductExclusionFlags(fetchProductExclusionFlagsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Promotion Restrictions By Product Code
     * @param {FetchPromotionRestrictionsByProductCodeCommand} fetchPromotionRestrictionsByProductCodeCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand: FetchPromotionRestrictionsByProductCodeCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).fetchPromotionRestrictionsByProductCode(fetchPromotionRestrictionsByProductCodeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Promotion Restrictions By Product Code List
     * @param {FetchPromotionRestrictionsByProductCodesCommand} fetchPromotionRestrictionsByProductCodesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand: FetchPromotionRestrictionsByProductCodesCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).fetchPromotionRestrictionsByProductCodes(fetchPromotionRestrictionsByProductCodesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3 for execution by the CommandExecutionManager. Abides by the naming convention required to trigger the lambda (s3://batch_jobs/CommandName/(timestamp)/(username)/upload.[csv,jsonl]).
     * @param {GenerateCommandExecutionPresignedUrlCommand} generateCommandExecutionPresignedUrlCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand: GenerateCommandExecutionPresignedUrlCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).generateCommandExecutionPresignedUrl(generateCommandExecutionPresignedUrlCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a presigned URL to download files from S3. The full path for the file will be similar like (s3://promotion-bucket/key_name).
     * @param {GeneratePresignedGetCommand} generatePresignedGetCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public generatePresignedGet(generatePresignedGetCommand: GeneratePresignedGetCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).generatePresignedGet(generatePresignedGetCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a presigned URL and requisite multipart/form-data form data for uploading files to S3. The full path for the uploaded file will be similar like (s3://promotion-bucket/key_name).
     * @param {GeneratePresignedPostCommand} generatePresignedPostCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public generatePresignedPost(generatePresignedPostCommand: GeneratePresignedPostCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).generatePresignedPost(generatePresignedPostCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate csv with random promotion restrictions for testing purpose
     * @param {GeneratePromotionRestrictionsCsvForTestCommand} generatePromotionRestrictionsCsvForTestCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand: GeneratePromotionRestrictionsCsvForTestCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).generatePromotionRestrictionsCsvForTest(generatePromotionRestrictionsCsvForTestCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the details of the specified execution.
     * @param {string} executionArn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getExecutionDetails(executionArn: string, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).getExecutionDetails(executionArn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Command to get all promotions in database or specified promotion details for input promotion codes
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getListPromotionsDetails(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).getListPromotionsDetails(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search parameters for product prices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getProductSearchParameters(options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).getProductSearchParameters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ms-promotion static data, such as promotions, currencies etc. If we need more static date in the future, only modify this command
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public getPromotionReferenceData(options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).getPromotionReferenceData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List available commands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public listCommands(options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).listCommands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List state machine executions by state machine name
     * @param {ListExecutionsCommand} listExecutionsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public listExecutions(listExecutionsCommand: ListExecutionsCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).listExecutions(listExecutionsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List state machine executions by state machine name
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public listStateMachines(body: object, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).listStateMachines(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publish products restrictions to the topic Promotion.Product
     * @param {PublishProductCodesAggregateCommand} publishProductCodesAggregateCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public publishProductCodesAggregate(publishProductCodesAggregateCommand: PublishProductCodesAggregateCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).publishProductCodesAggregate(publishProductCodesAggregateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a user request to download .csv file based on specific search attributes
     * @param {RequestPromotionRestrictionsCSVDownloadCommand} requestPromotionRestrictionsCSVDownloadCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand: RequestPromotionRestrictionsCSVDownloadCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).requestPromotionRestrictionsCSVDownload(requestPromotionRestrictionsCSVDownloadCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a websocket user connection in database.
     * @param {SaveConnectionCommand} saveConnectionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public saveConnection(saveConnectionCommand: SaveConnectionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).saveConnection(saveConnectionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {SaveLegacyNOSRecordsCommand} saveLegacyNOSRecordsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public saveLegacyNOSRecords(saveLegacyNOSRecordsCommand: SaveLegacyNOSRecordsCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).saveLegacyNOSRecords(saveLegacyNOSRecordsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a restriction in DB and returns all latest by type and promotion code hardcoded.
     * @param {SaveRestrictionCommand} saveRestrictionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public saveRestriction(saveRestrictionCommand: SaveRestrictionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).saveRestriction(saveRestrictionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get search parameters for product prices.
     * @param {SearchProductsWithPromotionExclusionsByProductAttributesCommand} searchProductsWithPromotionExclusionsByProductAttributesCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand: SearchProductsWithPromotionExclusionsByProductAttributesCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).searchProductsWithPromotionExclusionsByProductAttributes(searchProductsWithPromotionExclusionsByProductAttributesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a notification to a user.
     * @param {SendNotificationCommand} sendNotificationCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public sendNotification(sendNotificationCommand: SendNotificationCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).sendNotification(sendNotificationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set promotion product exclusions by converting user selected promotion restrictions into a  .jsonl that will be process by the step function createPromotionRestrictions.
     * @param {SetPromotionProductExclusionsCommand} setPromotionProductExclusionsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public setPromotionProductExclusions(setPromotionProductExclusionsCommand: SetPromotionProductExclusionsCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).setPromotionProductExclusions(setPromotionProductExclusionsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts a state machine by name, requires a payload and a unique execution name
     * @param {StartStateMachineCommand} startStateMachineCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public startStateMachine(startStateMachineCommand: StartStateMachineCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).startStateMachine(startStateMachineCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts a state machine by name, requires a payload and a unique execution name
     * @param {StartSyncStateMachineCommand} startSyncStateMachineCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public startSyncStateMachine(startSyncStateMachineCommand: StartSyncStateMachineCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).startSyncStateMachine(startSyncStateMachineCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step01Command} step01Command 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step01(step01Command: Step01Command, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step01(step01Command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step01HandleUploadedFileCommand} step01HandleUploadedFileCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step01HandleUploadedFile(step01HandleUploadedFileCommand: Step01HandleUploadedFileCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step01HandleUploadedFile(step01HandleUploadedFileCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step01ValidatePromotionRestrictionCommand} step01ValidatePromotionRestrictionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand: Step01ValidatePromotionRestrictionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step01ValidatePromotionRestriction(step01ValidatePromotionRestrictionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step02Command} step02Command 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step02(step02Command: Step02Command, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step02(step02Command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step02AssociateRestrictionToPromotionCommand} step02AssociateRestrictionToPromotionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand: Step02AssociateRestrictionToPromotionCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step02AssociateRestrictionToPromotion(step02AssociateRestrictionToPromotionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {Step02PublishProductRestrictionsEventsCommand} step02PublishProductRestrictionsEventsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand: Step02PublishProductRestrictionsEventsCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).step02PublishProductRestrictionsEvents(step02PublishProductRestrictionsEventsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {StepFourCommand} stepFourCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public stepFour(stepFourCommand: StepFourCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).stepFour(stepFourCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Base class for commands. You must define a class property result_type in subclasses for this to work, as well as adding a block comment to the command.
     * @param {StepThreeCommand} stepThreeCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandsApi
     */
    public stepThree(stepThreeCommand: StepThreeCommand, options?: AxiosRequestConfig) {
        return CommandsApiFp(this.configuration).stepThree(stepThreeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthChecksApi - axios parameter creator
 * @export
 */
export const HealthChecksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Runs all health checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodHealthcheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Liveness probe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodLivenessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Readyness probe. Runs health checks tagged with readyness.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodReadynessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readyness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthChecksApi - functional programming interface
 * @export
 */
export const HealthChecksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthChecksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Runs all health checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async methodHealthcheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.methodHealthcheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Liveness probe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async methodLivenessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.methodLivenessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Readyness probe. Runs health checks tagged with readyness.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async methodReadynessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthCheckResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.methodReadynessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthChecksApi - factory interface
 * @export
 */
export const HealthChecksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthChecksApiFp(configuration)
    return {
        /**
         * 
         * @summary Runs all health checks.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodHealthcheckGet(options?: any): AxiosPromise<HealthCheckResult> {
            return localVarFp.methodHealthcheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Liveness probe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodLivenessGet(options?: any): AxiosPromise<void> {
            return localVarFp.methodLivenessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Readyness probe. Runs health checks tagged with readyness.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        methodReadynessGet(options?: any): AxiosPromise<HealthCheckResult> {
            return localVarFp.methodReadynessGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthChecksApi - object-oriented interface
 * @export
 * @class HealthChecksApi
 * @extends {BaseAPI}
 */
export class HealthChecksApi extends BaseAPI {
    /**
     * 
     * @summary Runs all health checks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthChecksApi
     */
    public methodHealthcheckGet(options?: AxiosRequestConfig) {
        return HealthChecksApiFp(this.configuration).methodHealthcheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Liveness probe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthChecksApi
     */
    public methodLivenessGet(options?: AxiosRequestConfig) {
        return HealthChecksApiFp(this.configuration).methodLivenessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Readyness probe. Runs health checks tagged with readyness.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthChecksApi
     */
    public methodReadynessGet(options?: AxiosRequestConfig) {
        return HealthChecksApiFp(this.configuration).methodReadynessGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiInfo(options?: any): AxiosPromise<InfoResponse> {
            return localVarFp.getApiInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Get Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getApiInfo(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).getApiInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



import GucciImage from "assets/images/gucci.jpg";

import { Box } from "@mui/material";

const style = {
  backgroundImage: `url(${GucciImage})`,
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "top",
};

function HomePage() {
  return <Box sx={{ ...style, m: -2 }}></Box>;
}

export default HomePage;

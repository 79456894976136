import { ProductSearchAttributes } from "api/ms-promotion";
import { useApi } from "hooks/api";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";

import { Check, FileDownload } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

interface RestrictionsDownloadButtonProps {
  productSearchAttributes: ProductSearchAttributes;
}

function RestrictionsDownloadButton(props: RestrictionsDownloadButtonProps) {
  const intl = useIntl();
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  const exportPromotionRestrictionCSVResponse = useApi(
    (api) =>
      api.requestPromotionRestrictionsCSVDownload({
        product_search_attributes: props.productSearchAttributes,
      }),
    undefined,
    { manual: true }
  );

  useEffect(() => {
    if (exportPromotionRestrictionCSVResponse.data?.request_file_url) {
      setTimeout(() => setDownloadButtonDisabled(false), 5000); // Re-enabling the button after 5 seconds to prevent hitting our API like a maniac
    }
    if (exportPromotionRestrictionCSVResponse.error) {
      toast.error(
        intl.formatMessage({
          id: "notification.error.csv_download_failed",
          defaultMessage:
            "Request CSV download has failed. Retry or contact Stash team.",
          description: "Error message for CSV download request failure.",
        })
      );
      console.error(exportPromotionRestrictionCSVResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportPromotionRestrictionCSVResponse]);

  const handleDownloadFileButton = () => {
    setDownloadButtonDisabled(true);
    exportPromotionRestrictionCSVResponse.fetch();
  };

  return (
    <LoadingButton
      loading={exportPromotionRestrictionCSVResponse.loading}
      disabled={downloadButtonDisabled}
      variant={"outlined"}
      startIcon={downloadButtonDisabled ? <Check /> : <FileDownload />}
      onClick={handleDownloadFileButton}
      sx={{ mr: 2 }}
    >
      {downloadButtonDisabled ? (
        <FormattedMessage
          id="restrictions.download_sent.button"
          defaultMessage="Request sent"
          description="Request sent CSV text button"
        />
      ) : (
        <FormattedMessage
          id="restrictions.download_request.button"
          defaultMessage="Request CSV download"
          description="Download request CSV text button"
        />
      )}
    </LoadingButton>
  );
}

export default RestrictionsDownloadButton;

import { AxiosRequestConfig } from "axios";
import { useApiInfo } from "context/api/ApiInfo";
import { api } from "hooks/api";
import { useScript } from "hooks/useScript";
import { createContext, useContext, useEffect, useState } from "react";

import Alert from "@mui/material/Alert";

export interface TokenUserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  locale?: string;
  picture?: string;
}

export interface AuthenticateResponse {
  token: string;
  user: TokenUserInfo;
}

export interface Authentication {
  isAuthenticated: boolean;
  userInfo?: TokenUserInfo;

  token(): Promise<string>;
  logout(): Promise<void>;
}

export const UserAuthenticationContext = createContext<Authentication>({
  isAuthenticated: false,
  token: () => {
    throw new Error("Not authenticated");
  },
  logout: () => {
    throw new Error("Not authenticated");
  },
});

export const useAuthentication = () => useContext(UserAuthenticationContext);

type UserAuthenticationProps = {
  children: React.ReactNode;
};

/**
 * Ensures that the user is authenticated with SSENSE.
 */
function UserAuthentication(props: UserAuthenticationProps) {
  const { children } = props;

  const [authentication, setAuthentication] = useState<
    Authentication | undefined
  >(undefined);

  const [authError, setAuthError] = useState<any>(undefined);

  const apiInfo = useApiInfo();

  useScript(`${apiInfo.dm_auth_server_url}/client/resources/auth.js`);

  useEffect(() => {
    const interval = setInterval(() => {
      const ssense = (window as any).ssense;
      if (ssense) {
        clearInterval(interval);
        ssense.onLoad((err: any) => {
          if (err) {
            console.error(err);
            setAuthError(err);
          } else {
            ssense.authenticate(
              "dm-promotion",
              (err: any, data: AuthenticateResponse) => {
                if (err) {
                  console.error(err);
                  setAuthError(err);
                } else {
                  (api as any).axios.interceptors.request.use(
                    (config: AxiosRequestConfig) => {
                      config.headers!["Authorization"] = `Bearer ${data.token}`;
                      return config;
                    },
                    (error: any) => {
                      Promise.reject(error);
                    }
                  );
                  (api as any).axios.interceptors.response.use(
                    (response: any) => response,
                    (error: any) => {
                      if (error.response.status === 401) {
                        setAuthentication(undefined);
                        try {
                          ssense.logout();
                        } catch {}
                        window.location.reload();
                      }
                      return error;
                    }
                  );
                  setAuthentication({
                    isAuthenticated: true,
                    userInfo: data.user,
                    token: async () => data.token,
                    logout: async () => {
                      setAuthentication(undefined);
                      try {
                        await ssense.logout();
                      } catch {}
                      window.location.reload();
                    },
                  });
                }
              }
            );
          }
        });
      }
    }, 250);
  }, [apiInfo]);

  if (authError) {
    <Alert severity="error">
      Error while negotiating with dm-auth: {`${authError}`}. Please make sure
      you are connected to the VPN/internal network, and reload the page.
    </Alert>;
  }

  if (!authentication) {
    return null;
  }

  return (
    <UserAuthenticationContext.Provider value={authentication}>
      {children}
    </UserAuthenticationContext.Provider>
  );
}

export default UserAuthentication;

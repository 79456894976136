
import * as React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { createSimulation } from "../../../utils/api/apiTools";
import { useNotification } from '../../../context/notification/Notification';

const CreateSimulationPageContainer = styled('div')(() => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  maxWidth: '75%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '20px',
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const PageTitle = styled('h2')({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
});

const PageSection = styled('div')(() => ({
  backgroundColor: '#FFF',
  padding: '16px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#E0E0E0',
  borderRadius: '4px',
}));

const SectionTitle = styled('div')({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  marginBottom: '16px',
});

const SectionRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
}));

const SectionGroup = styled(Box)(() => ({
  display: 'flex',
  marginTop: 'auto',
  marginBottom: 'auto',
  gap: '10px',
}));

const BackButton = styled(IconButton)({
  color: '#000',
  background: 'none',
});

const StyledButton = styled(Button)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  width: '100px',
  height: '36px',
  '&:hover': {
    background: 'none',
  }
});

const DiscardButton = styled(StyledButton)({
  color: '#1976D2',
});

const RunButton = styled(StyledButton)({
  backgroundColor: '#1976D2',
  color: '#FFF',
  '&:disabled': {
    backgroundColor: '#E0E0E0',
  },
});

const StyledRadio = styled(Radio)({
  ':not(&.Mui-disabled)': {
    '&.Mui-checked': {
      color: '#000'
    },
  },
});

const StyledCheckbox = styled(Checkbox)({
  ':not(&.Mui-disabled)': {
    '&.Mui-checked': {
      color: '#000'
    },
  },
});

const CheckboxFormGroup = styled(FormGroup)({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '60px',
    rowGap: '20px',
    paddingLeft: '16px',
    paddingRight: '16px',
});

const SelectAllButton = styled(Button)({
  marginBottom: '16px',
  textTransform: 'none',
  lineHeight: 1.5,
  fontSize: '14px',
  padding: 0,
  '&:hover': {
    background: 'none',
  }
});

type CreateSimulationRequest = {
  sale_event_id: string;
  simulation_objective_id: string;
  audience_ids: string[];
  country_codes: string[];
}

export default function CreateSimulationPage() {
  const OBJECTIVES = ['GM$'];
  const AUDIENCES = ['Public', 'Private', 'VIPs'];
  const COUNTRIES = ['US'];

  const navigate = useNavigate();
  const intl = useIntl();
  const { setNotification } = useNotification();

  const events = ['event1', 'event2', 'event3']; // TODO: use real event data
  const [event, setEvent] = React.useState('');
  const [selectedObjective, setSelectedObjective] = React.useState(OBJECTIVES[0]);
  const [selectedAudiences, setSelectedAudiences] = React.useState(Object.assign({}, ...AUDIENCES.map((audience) => ({ [audience]: false}))));
  const [selectedCountries, setSelectedCountries] = React.useState(Object.assign({}, ...COUNTRIES.map((country) => ({ [country]: false}))));
  const isDraftSimulationComplete = event && selectedObjective && Object.values(selectedAudiences).some((audience) => !!audience) && Object.values(selectedCountries).some((country) => !!country);

  const runSimulation = async () => {
    const body: CreateSimulationRequest = {
      sale_event_id: event,
      simulation_objective_id: selectedObjective,
      audience_ids: selectedAudiences,
      country_codes: selectedCountries
    }
    const response = await createSimulation(body);

    setNotification({
      open: true,
      message: response.message,
      severity: response.success ? 'success' : 'error'
    })

    if (response.success) {
      navigate('/simulations')
    }
  };

  const handleAudienceCheckboxSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAudiences({
      ...selectedAudiences,
      [event.target.value]: event.target.checked,
    });
  };

  const handleCountryCheckboxSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCountries({
      ...selectedCountries,
      [event.target.value]: event.target.checked,
    });
  };

  const selectAllAudiences = () => {
    setSelectedAudiences(Object.assign({}, ...AUDIENCES.map((audience) => ({ [audience]: true}))));
  };

  const selectAllCountries = () => {
    setSelectedCountries(Object.assign({}, ...COUNTRIES.map((country) => ({ [country]: true}))));
  };

  return (
    <CreateSimulationPageContainer>
      <Header>
        <SectionGroup>
          <BackButton disableRipple onClick={ () => navigate(-1) }><ArrowBackIcon sx={ { fontSize: '20px' } }/></BackButton>
          <PageTitle>{ intl.formatMessage({ id: 'simulation.create.title'}) }</PageTitle>
        </SectionGroup>
        <SectionGroup>
          <DiscardButton disableRipple onClick={ () => navigate(-1) }>{ intl.formatMessage({ id: 'sale_events.create_event.discard'}) }</DiscardButton>
          <RunButton disableRipple onClick={ runSimulation } disabled={ !isDraftSimulationComplete}>{ intl.formatMessage({ id: 'simulation.create.run'}) }</RunButton>
        </SectionGroup>
      </Header>

      <PageSection>
        <SectionTitle>{ intl.formatMessage({ id: 'sale_events.column_title.event_name'}) }</SectionTitle>
        <SectionRow>
          <FormControl sx={ {width: '100%'} }>
            <InputLabel>{ intl.formatMessage({ id: 'simulation.create.select'}) }</InputLabel>
            <Select
              label={ intl.formatMessage({ id: 'simulation.create.select'})}
              value={ event }
              onChange={ (event) => setEvent(event.target.value) }
            >
              { events.map((event, index) => (
                <MenuItem key={ index } value={ event }>{ event }</MenuItem>
              )) }
            </Select>
          </FormControl>
        </SectionRow>
      </PageSection>

      <PageSection>
        <SectionTitle>{ intl.formatMessage({ id: 'simulation.create.objective'}) }</SectionTitle>
        <FormControl sx={ {paddingLeft: '16px', paddingRight: '16px'} }>
            <RadioGroup value={selectedObjective} onChange={ (event) => setSelectedObjective(event.target.value) }>
                { OBJECTIVES.map((objective, index) => (
                    <FormControlLabel key={index} value={objective} control={<StyledRadio disabled disableRipple/>} label={objective} />
                )) }
            </RadioGroup>
        </FormControl>
      </PageSection>

      <PageSection>
        <Header>
          <SectionTitle>{ intl.formatMessage({ id: 'simulation.create.audiences'}) }</SectionTitle>
          <SelectAllButton onClick={selectAllAudiences} disableRipple>{ intl.formatMessage({ id: 'simulation.create.select_all'}) }</SelectAllButton>
        </Header>
        <CheckboxFormGroup>
            { AUDIENCES.map((audience, index) => (
                <FormControlLabel key={index} value={audience} control={<StyledCheckbox checked={selectedAudiences[audience]} onChange={handleAudienceCheckboxSelection} disableRipple/>} label={audience} />
            )) }
        </CheckboxFormGroup>
      </PageSection>

      <PageSection>
        <Header>
          <SectionTitle>{ intl.formatMessage({ id: 'simulation.create.countries'}) }</SectionTitle>
          <SelectAllButton onClick={selectAllCountries} disableRipple>{ intl.formatMessage({ id: 'simulation.create.select_all'}) }</SelectAllButton>
        </Header>
        <CheckboxFormGroup>
            { COUNTRIES.map((country, index) => (
                <FormControlLabel key={index} value={country} control={<StyledCheckbox checked={selectedCountries[country]} onChange={handleCountryCheckboxSelection} disableRipple/>} label={country} />
            )) }
        </CheckboxFormGroup>
      </PageSection>
    </CreateSimulationPageContainer>
  );
}

import "react-toastify/dist/ReactToastify.css";

import ErrorToast from "components/layout/toasts/ErrorToast";
import ProcessingToast from "components/layout/toasts/ProcessingToast";
import ProgressToast from "components/layout/toasts/ProgressToast";
import SuccessToast from "components/layout/toasts/SuccessToast";
import { useApiInfo } from "context/api/ApiInfo";
import { useAuthentication } from "context/authentication/UserAuthentication";
import { useUserPreferences } from "context/preferences/UserPreferences";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { toast, ToastContainer } from "react-toastify";
import useWebSocket from "react-use-websocket";
import enMessages from "translations/en.json";
import getHash from "utils/hash/getHash";

import type { ToastItem } from "react-toastify";

interface Notification {
  message: keyof typeof enMessages;
  status: string;
  task_id: string;
  details?: any;
}

const getSocketParams = (
  key: string,
  clearUserId: string = "anonymous@ssense.com"
) => {
  const userIdHashed = getHash(key, clearUserId);
  return {
    "user-id": userIdHashed,
  };
};

function Notifications() {
  const apiInfo = useApiInfo();
  const authentication = useAuthentication();
  const userPreferences = useUserPreferences();
  const intl = useIntl();
  const displayedNotifications = useRef([] as string[]);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const socketUrl = process.env.REACT_APP_WEBSOCKET_ENDPOINT || "";

  const socketParams = getSocketParams(
    apiInfo.user_connection_user_id_secret_key,
    authentication.userInfo?.email
  );

  const { lastJsonMessage } = useWebSocket(socketUrl, {
    queryParams: socketParams,
    shouldReconnect: (closeEvent) => true,
  });

  // Display the notification toaster on the user screen
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notify = (notification: Notification) => {
    switch (notification.status) {
      case "PROCESSING":
        toast.info(<ProcessingToast notification={notification} />, {
          toastId: notification.task_id,
        });

        break;

      case "PROGRESS":
        if (toast.isActive(notification.task_id)) {
          toast.update(notification.task_id, {
            type: toast.TYPE.INFO,
            render: <ProgressToast notification={notification} />,
          });
        } else {
          toast.info(<ProgressToast notification={notification} />, {
            toastId: notification.task_id,
          });
        }
        break;

      case "SUCCEEDED":
        if (toast.isActive(notification.task_id)) {
          toast.update(notification.task_id, {
            type: toast.TYPE.SUCCESS,
            render: <SuccessToast notification={notification} />,
          });
        } else {
          toast.success(<SuccessToast notification={notification} />, {
            toastId: notification.task_id,
          });
        }
        break;

      case "ERROR":
        if (toast.isActive(notification.task_id)) {
          toast.update(notification.task_id, {
            type: toast.TYPE.ERROR,
            render: <ErrorToast notification={notification} />,
          });
        } else {
          toast.error(<ErrorToast notification={notification} />, {
            toastId: notification.task_id,
          });
        }
        break;

      case "FAILED":
        if (toast.isActive(notification.task_id)) {
          toast.update(notification.task_id, {
            type: toast.TYPE.ERROR,
            render: intl.formatMessage(
              {
                id: notification.message,
                defaultMessage: "Failed",
                description: "Message for failed status received from back-end",
              },
              {
                error: notification.details?.intl_values?.error,
                file_name: notification.details?.intl_values?.file_name,
              }
            ),
          });
        } else {
          toast.error(
            intl.formatMessage(
              {
                id: notification.message,
                defaultMessage: "Failed",
                description: "Message for failed status received from back-end",
              },
              {
                error: notification.details?.intl_values?.error,
                file_name: notification.details?.intl_values?.file_name,
              }
            ),
            { toastId: notification.task_id }
          );
        }
        break;
    }
  };

  // Reacting to websocket notification reception and saving them in our state
  useEffect(() => {
    if (lastJsonMessage !== null) {
      const index = notifications.findIndex(
        (notification) =>
          notification.task_id === (lastJsonMessage as any).task_id
      );

      if (index === -1) {
        setNotifications((prevState: Notification[]) => [
          ...prevState,
          lastJsonMessage as any,
        ]);
      } else {
        const newNotifications = [...notifications];
        newNotifications[index] = lastJsonMessage as any;
        setNotifications(newNotifications);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  // Reacting to notifications changes in our state and deciding to call notify() on them
  useEffect(() => {
    notifications.forEach((notification) => {
      const notificationIsDisplayed = displayedNotifications.current.findIndex(
        (taskId) => taskId === notification.task_id
      );

      if (notificationIsDisplayed !== -1) {
        notify(notification);
      } else if (
        displayedNotifications.current.length <
        userPreferences.notifications.limit
      ) {
        notify(notification);
        displayedNotifications.current.push(notification.task_id);
      }
    });
  }, [notifications, notify, userPreferences.notifications.limit]);

  // Reacting to close events on our displayed toasters and updating our notifications in our state
  // This will kicks in only when closing completed tasks such as "succeeded", "error" and "failed" tasks
  toast.onChange((payload: ToastItem) => {
    if (payload.status === "removed") {
      if (payload.type === "success" || payload.type === "error") {
        displayedNotifications.current = displayedNotifications.current.filter(
          (taskId: string) => taskId !== payload.id
        );

        setNotifications((prevState: Notification[]) =>
          prevState.filter(
            (notification) => notification.task_id !== payload.id
          )
        );
      }
    }
  });

  return (
    <ToastContainer
      newestOnTop
      hideProgressBar
      position={toast.POSITION.BOTTOM_LEFT}
      theme={userPreferences.colorMode.mode === "dark" ? "dark" : "colored"}
      closeOnClick={false}
      autoClose={false}
      draggable={false}
    />
  );
}

export default Notifications;

import "moment-timezone";

import { ProductSearchAttributes, Promotion } from "api/ms-promotion/api";
import ApiResponse from "components/api/ApiResponse";
import { useApiInfo } from "context/api/ApiInfo";
import { useApi } from "hooks/api";
import { ChangeEvent } from "react";
import Moment from "react-moment";
import ContinuationWaypoint from "utils/waypoint/ContinuationWaypoint";
import RestrictionsDownloadButton from "views/Restrictions/RestrictionsDownloadButton/RestrictionsDownloadButton";

import { Button, Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledPaper = styled(Paper)({
  fontSize: "13px",
  width: "100%",
  marginTop: "20px",
});

const StyledTableContainer = styled(TableContainer)({
  overflowX: "initial",
  marginBottom: "100px",
});
const StyledVerticalTopTableRow = styled(TableRow)({
  verticalAlign: "top",
});

const StyledHeaderTableRow = styled(TableRow)({
  "& .MuiTableCell-head": {
    fontWeight: 700,
    top: "64px",
  },
});

const ProductInfoHeaderCell = styled(TableCell)({
  width: "40%",
  textAlign: "left",
  fontWeight: "bold",
  borderBottom: "none",
  padding: "5px",
});

const ProductInfoContentCell = styled(TableCell)({
  borderBottom: "none",
  padding: "5px",
});

const StyledPaddingTableCell = styled(TableCell)({
  paddingTop: "20px",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "block",
  textAlign: "left",
});

interface EnhancedTableProps {
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
}
interface RestrictionListProps {
  getRestrictionsResponse: any;
  productSearchAttributesForDownloadButton: ProductSearchAttributes;
}

interface HeadCell {
  id: string;
  label: string;
}

const PLACE_HOLDER_IMG =
  "https://via.placeholder.com/300x300/000000/FFFFFF/?text=SSENSE";

const ProductTable = (props: any) => {
  const { row } = props;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <ProductInfoHeaderCell>Name:</ProductInfoHeaderCell>
          <ProductInfoContentCell>{row.product_name}</ProductInfoContentCell>
        </TableRow>
        <TableRow>
          <ProductInfoHeaderCell>Category:</ProductInfoHeaderCell>
          <ProductInfoContentCell>{row.category_name}</ProductInfoContentCell>
        </TableRow>
        <TableRow>
          <ProductInfoHeaderCell>Brand:</ProductInfoHeaderCell>
          <ProductInfoContentCell>{row.brand_name}</ProductInfoContentCell>
        </TableRow>
        <TableRow>
          <ProductInfoHeaderCell>Delivery:</ProductInfoHeaderCell>
          <ProductInfoContentCell>
            Delivery will come soon
          </ProductInfoContentCell>
        </TableRow>
        <TableRow>
          <ProductInfoHeaderCell>Supplier Code:</ProductInfoHeaderCell>
          <ProductInfoContentCell>{row.supplier_code}</ProductInfoContentCell>
        </TableRow>
        <TableRow>
          <ProductInfoHeaderCell>Supplier Color:</ProductInfoHeaderCell>
          <ProductInfoContentCell>{row.supplier_color}</ProductInfoContentCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

let exclusionsPromotionsCodeMapper: any = {};
const getAllExclusionsCode = (promotionList: Promotion[]): string[] => {
  return [
    ...promotionList.map((p) => {
      exclusionsPromotionsCodeMapper[p.exclusion_code.toUpperCase()] = p.code;
      return p.exclusion_code.toUpperCase();
    }),
  ];
};

const generateHeadCells = (allExclusionsCode: string[]) => {
  const headCells: HeadCell[] = [];
  headCells.push({ id: "productCode", label: "PRODUCT CODE" } as HeadCell);
  headCells.push({ id: "image", label: "IMAGE" } as HeadCell);
  headCells.push({ id: "productInfo", label: "PRODUCT INFO" } as HeadCell);
  if (allExclusionsCode.length > 0) {
    headCells.push({ id: "all", label: "ALL FLAGS" });
  }
  allExclusionsCode.map((promotionCode) =>
    headCells.push({ id: promotionCode, label: promotionCode } as HeadCell)
  );
  headCells.push({ id: "lastUpdates", label: "LAST UPDATED" } as HeadCell);
  return headCells;
};

function RestrictionsList(props: RestrictionListProps) {
  const getPromotionReferenceData: any = useApi((api: any) =>
    api.getPromotionReferenceData()
  );
  const apiInfo = useApiInfo();

  const getRestrictionsResponse: any = props.getRestrictionsResponse;

  const allCurrencies: string[] =
    getPromotionReferenceData.data?.list_promotion_currencies || [];

  const allExclusionsCode: string[] =
    (getPromotionReferenceData.data?.list_promotions &&
      getAllExclusionsCode(getPromotionReferenceData.data?.list_promotions)) ||
    [];

  const productsPromotions = getRestrictionsResponse.data || [];

  const handleSelectAllClick = () => {};
  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const headCells: readonly HeadCell[] = allExclusionsCode
      ? generateHeadCells(allExclusionsCode)
      : [];
    return (
      <TableHead>
        <StyledHeaderTableRow>
          {headCells.map((headCell, index) => (
            <TableCell key={headCell.id + "-" + index}>
              {allExclusionsCode.indexOf(headCell.id) >= 0 ||
              headCell.id === "all" ? (
                <FormControlLabel
                  control={<Checkbox id={headCell.id} />}
                  label={headCell.label}
                />
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          ))}
        </StyledHeaderTableRow>
      </TableHead>
    );
  };
  let rowNum: number = 0;
  return (
    <ApiResponse response={getRestrictionsResponse}>
      {getRestrictionsResponse.data?.length === 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          textAlign="center"
        >
          <Grid item>
            <Typography variant={"h4"}>Empty</Typography>
            <Typography variant={"h5"}>
              No results were found, please try again
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <StyledPaper>
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item justifyContent="flex-end">
              <RestrictionsDownloadButton
                productSearchAttributes={
                  props.productSearchAttributesForDownloadButton
                }
              />
            </Grid>
          </Grid>
          <StyledTableContainer>
            <Table
              sx={{ minWidth: 750, maxHeight: 450 }}
              aria-labelledby="tableTitle"
              size="small"
              stickyHeader
            >
              <EnhancedTableHead onSelectAllClick={handleSelectAllClick} />
              <TableBody>
                {productsPromotions.map((row: any, index: number) => {
                  rowNum++;
                  const productRestrictions = Object.keys(
                    row.promotion_restrictions_by_promotion_code!
                  ).map((key) => [
                    {
                      key: key,
                      currencies: Object.keys(
                        row.promotion_restrictions_by_promotion_code![key]
                      ),
                    },
                  ]);

                  return (
                    <StyledVerticalTopTableRow
                      hover
                      tabIndex={-1}
                      key={row.product_code + "-" + index}
                      id={"row_" + row.product_code}
                    >
                      <StyledPaddingTableCell
                        component="th"
                        id={row.product_code}
                        scope="row"
                      >
                        {row.product_code}
                      </StyledPaddingTableCell>
                      <StyledPaddingTableCell align="center">
                        <img
                          width="100px"
                          alt={row.product_code}
                          src={row.image_url || PLACE_HOLDER_IMG}
                        />
                      </StyledPaddingTableCell>
                      <TableCell>
                        <ProductTable row={row} />
                      </TableCell>
                      {allExclusionsCode.length > 0 && (
                        <TableCell>
                          <StyledFormControlLabel
                            control={
                              <Checkbox id={"all-flags-" + row.product_code} />
                            }
                            label="ALL"
                          />
                        </TableCell>
                      )}
                      {allExclusionsCode.map((key) => {
                        return (
                          <TableCell>
                            {allCurrencies.map((currency) => {
                              let isRestrictionChecked: boolean = false;
                              productRestrictions.forEach((pr) => {
                                if (
                                  pr[0]["key"] ===
                                    exclusionsPromotionsCodeMapper[key] &&
                                  pr[0]["currencies"].indexOf(currency) >= 0
                                ) {
                                  isRestrictionChecked = true;
                                  return;
                                }
                              });

                              return (
                                <StyledFormControlLabel
                                  control={
                                    <Checkbox checked={isRestrictionChecked} />
                                  }
                                  label={currency}
                                />
                              );
                            })}
                          </TableCell>
                        );
                      })}
                      <StyledPaddingTableCell align="left">
                        <div key={row.product_code + "-lastUpdated"}>
                          {apiInfo.feature_flags?.is_user_tracking_enabled ? (
                            row.last_updated_time ? (
                              <Moment
                                tz="America/Montreal"
                                format="ddd YYYY-MM-DD, HH:mm:ss z"
                              >
                                {row.last_updated_time}
                              </Moment>
                            ) : (
                              "N/A"
                            )
                          ) : (
                            "lastUpdated"
                          )}
                        </div>
                        {apiInfo.feature_flags?.is_user_tracking_enabled
                          ? "By " + (row.last_update_requestor || "N/A")
                          : "Coming soon"}
                      </StyledPaddingTableCell>
                    </StyledVerticalTopTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <ContinuationWaypoint
            response={getRestrictionsResponse}
            topOffset="400px"
            bottomOffset="-300px"
          />
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              position: "fixed",
              bottom: "0px",
              backgroundColor: "white",
            }}
          >
            <Grid item xs={10} sx={{ margin: "20px" }}>
              Total Rows {rowNum} of {getRestrictionsResponse?.totalCount || 0}
            </Grid>
            <Grid item sx={{ margin: "20px" }}>
              <Button variant="contained" disabled>
                Save
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      )}
    </ApiResponse>
  );
}
export default RestrictionsList;

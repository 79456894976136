import { useUserPreferences } from "context/preferences/UserPreferences";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import { InputLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";

function TimeZone() {
  const userPreferences = useUserPreferences();

  const handleTimezoneChange = (
    _event: React.MouseEvent<HTMLElement>,
    newFormat: string
  ) => {
    if (newFormat !== null) {
      userPreferences.timezone.setFormat?.(newFormat);
    }
  };

  return (
    <>
      <InputLabel sx={{ fontSize: 10 }}>
        <FormattedMessage
          id="restrictions.preferences.timezone_input"
          defaultMessage="Time zone"
          description="Time zone input"
        />
      </InputLabel>
      <ToggleButtonGroup
        value={userPreferences.timezone.format}
        exclusive
        size="small"
        onChange={handleTimezoneChange}
        aria-label="time zone"
        color="primary"
      >
        <ToggleButton sx={{ textTransform: "none" }} value="edt">
          <FormattedMessage
            id="restrictions.preferences.timezone_edt"
            defaultMessage="EDT"
            description="Time zone EDT option"
          />
        </ToggleButton>
        <ToggleButton sx={{ textTransform: "none" }} value="utc">
          <FormattedMessage
            id="restrictions.preferences.timezone_utc"
            defaultMessage="UTC"
            description="Time zone UTC option"
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
export default TimeZone;


import * as React from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { createSaleEvent } from "../../../utils/api/apiTools";
import { useNotification } from '../../../context/notification/Notification';

const CreateSaleEventContainer = styled('div')(() => ({
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  maxWidth: '75%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: '20px',
}));

const Header = styled('div')(() => ({
  height: '72px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const PageTitle = styled('h2')({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 500,
});

const PageSection = styled('div')(() => ({
  backgroundColor: '#FFF',
  padding: '16px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#E0E0E0',
  borderRadius: '4px',
}));

const SectionTitle = styled('div')({
  maxWidth: 'fit-content',
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  marginBottom: '16px',
});

const SectionRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
}));

const SectionGroup = styled(Box)(() => ({
  display: 'flex',
  marginTop: 'auto',
  marginBottom: 'auto',
  gap: '10px',
}));

const BackButton = styled(IconButton)({
  color: '#000',
  background: 'none',
});

const StyledButton = styled(Button)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  width: '100px',
  height: '36px',
  '&:hover': {
    background: 'none',
  }
});

const DiscardButton = styled(StyledButton)({
  color: '#1976D2',
});

const SaveButton = styled(StyledButton)({
  backgroundColor: '#1976D2',
  color: '#FFF',
  '&:disabled': {
    backgroundColor: '#E0E0E0',
  },
});

type CreateSaleEventRequest = {
  name: string;
  start_date: string;
  end_date: string;
  type: string;
}

export default function CreateSaleEventPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { setNotification } = useNotification();

  // TODO: use real data
  const eventTypes = ['eventType1', 'eventType2', 'eventType3'];
  const [eventTitle, setEventTitle] = React.useState('');
  const [eventType, setEventType] = React.useState('');
  const [eventStartDate, setEventStartDate] = React.useState<moment.Moment | null>(null);
  const [eventEndDate, setEventEndDate] = React.useState<moment.Moment | null>(null);

  const isDraftSaleEventComplete = eventTitle && eventType && eventStartDate && eventEndDate;

  const saveEvent = async () => {
    if (!eventTitle || !eventType || !eventStartDate || !eventEndDate) {
     setNotification({ open: true, message: 'Missing information', severity: 'error' });
     return;
    }

    const body: CreateSaleEventRequest = {
      name: eventTitle,
      start_date: eventStartDate.format('YYYY-MM-DD'),
      end_date: eventEndDate.format('YYYY-MM-DD'),
      type: eventType
    }
    const response = await createSaleEvent(body);

    setNotification({
      open: true,
      message: response.message,
      severity: response.success ? 'success' : 'error',
    });

    if (response.success) {
      navigate('/sale-events')
    }
  };

  return (
    <CreateSaleEventContainer>
      <Header>
        <SectionGroup>
          <BackButton disableRipple onClick={ () => navigate(-1) }><ArrowBackIcon sx={ { fontSize: '20px' } }/></BackButton>
          <PageTitle>{ intl.formatMessage({ id: 'sale_events.create_event.title'}) }</PageTitle>
        </SectionGroup>
        <SectionGroup>
          <DiscardButton disableRipple onClick={ () => navigate(-1) }>{ intl.formatMessage({ id: 'sale_events.create_event.discard'}) }</DiscardButton>
          <SaveButton disableRipple onClick={ saveEvent } disabled={ !isDraftSaleEventComplete}>{ intl.formatMessage({ id: 'sale_events.create_event.save'}) }</SaveButton>
        </SectionGroup>
      </Header>

      <PageSection>
        <TextField
          fullWidth
          label={ intl.formatMessage({ id: 'sale_events.create_event.event_title'}) }
          value={ eventTitle }
          onChange={(event) => setEventTitle(event.target.value)}
        />
      </PageSection>

      <PageSection>
        <SectionTitle>{ intl.formatMessage({ id: 'sale_events.create_event.event_overview'}) }</SectionTitle>

        <SectionRow>
          <FormControl sx={ {flex: 2} }>
            <InputLabel>{ intl.formatMessage({ id: 'sale_events.create_event.event_type'}) }</InputLabel>
            <Select
              label={ intl.formatMessage({ id: 'sale_events.create_event.event_type'})}
              value={ eventType }
              onChange={ (event) => setEventType(event.target.value) }
            >
              { eventTypes.map((eventType, index) => (
                <MenuItem key={ index } value={ eventType }>{ eventType }</MenuItem>
              )) }
            </Select>
          </FormControl>

          <FormControl sx={ {flex: 1} }>
            <DatePicker
              label={ intl.formatMessage({ id: 'sale_events.create_event.event_start_date'}) }
              value={ eventStartDate }
              onChange={(date) => setEventStartDate(date) }
            />
          </FormControl>
          <FormControl sx={ {flex: 1} }>
            <DatePicker
              label={ intl.formatMessage({ id: 'sale_events.create_event.event_end_date'}) }
              value={ eventEndDate }
              onChange={ (date) => setEventEndDate(date) }
              minDate={ eventStartDate }
            />
          </FormControl>
        </SectionRow>
      </PageSection>
    </CreateSaleEventContainer>
  );
}

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import ErrorModal from "views/Restrictions/RestrictionsUploadErrorModal/RestrictionsUploadErrorModal";

import { Button, Grid } from "@mui/material";

interface ErrorToastProps {
  notification: any;
}

function ErrorToast(props: ErrorToastProps) {
  const { notification } = props;

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleClickOpenErrorModal = () => setModalOpen(true);

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sx={{ width: "250px", overflow: "auto" }}>
          <FormattedMessage
            id={notification.message}
            defaultMessage="Error"
            description="Message for error status received from back-end"
            values={{
              csv_file_name: notification.details?.intl_values?.csv_file_name,
            }}
          />
        </Grid>
        <Grid item sx={{ width: "50px" }}>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={handleClickOpenErrorModal}
          >
            <FormattedMessage
              id="restrictions.error.view"
              defaultMessage="View"
              description="Notification error detail button"
            />
          </Button>
        </Grid>
      </Grid>
      <ErrorModal
        open={modalOpen}
        onClose={handleClose}
        notification={notification}
      />
    </>
  );
}
export default ErrorToast;

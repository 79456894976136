import React, { createContext, useContext, useState } from 'react';

export interface Notification {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
}

interface NotificationContextProps {
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

type NotificationProps = {
  children: React.ReactNode;
};
export const NotificationProvider = (props: NotificationProps ) => {
  const [notification, setNotification] = useState<Notification>({ open: false, message: '', severity: 'success' });
  const { children } = props;

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

import { FormattedMessage } from "react-intl";

import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface ProcessingToastProps {
  notification: any;
}

function ProcessingToast(props: ProcessingToastProps) {
  const { notification } = props;

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item sx={{ width: "280px", overflow: "auto" }}>
          <FormattedMessage
            id={notification.message}
            defaultMessage="Processing"
            description="Message for processing status received from back-end"
            values={{
              file_name: notification.details?.intl_values?.file_name,
            }}
          />
        </Grid>
        <Grid textAlign="center" item sx={{ width: "50px" }}>
          <CircularProgress color="inherit" size={30} />
        </Grid>
      </Grid>
    </>
  );
}
export default ProcessingToast;

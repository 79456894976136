import { CommandsApi } from "api/ms-promotion/api";
import { Configuration } from "api/ms-promotion/configuration";
import { AxiosResponse } from "axios";
import { DependencyList, useEffect, useState } from "react";

export const api = new CommandsApi(
  new Configuration({
    basePath: process.env.REACT_APP_API_PATH,
  })
);

export interface AutoRefreshOptions {
  keepRefreshing: boolean;
  interval: number;
}

export interface UseApiOptions {
  manual?: boolean;
  autoRefresh?: AutoRefreshOptions;
}

export interface UseApiHooksParams<T> {
  /** The resulting api call response, when there is one. */
  data?: T;
  /** An indicator whether this calls is currently happening or not. */
  loading: boolean;
  /** Any error that happened during the api call. */
  error?: any;
  /** Calling this will call the api once more. Useful for refresh or manual operations. */
  fetch: () => void;
}

/**
 * Hooks that expose calling the api.
 * By default, it will make the call, and return flags in the form of UseApiHooksParams.
 * Options:
 * - manual: true in the options will prevent the initial call and only make it on demand.
 * - autoRefresh: takes an AutoRefreshOptions object with option keepRefreshing to stop
 *   refreshing when needed and the interval in ms.
 * manual and autoRefresh can be combined to start refreshing only after a manual call
 */
export const useApi = <T,>(
  apiCall: (api: CommandsApi) => Promise<AxiosResponse<T>>,
  deps?: DependencyList,
  options?: UseApiOptions
): UseApiHooksParams<T> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [fetchMarker, setFetchMarker] = useState(0);
  const finalDeps = [fetchMarker];
  if (deps) {
    finalDeps.push(...(deps as any));
  }

  const fetch = () => {
    setFetchMarker((x) => x + 1);
  };

  useEffect(() => {
    let unMounted = false;
    const fetchApi = async () => {
      setLoading(true);
      try {
        setError(undefined);
        const response = await apiCall(api);
        if (!unMounted) {
          setData(response.data);
        }
      } catch (err) {
        console.warn(err);
        if (!unMounted) {
          setError(err as undefined);
        }
      }

      if (!unMounted) {
        setLoading(false);
      }
    };

    if (!options?.manual || fetchMarker > 0) {
      fetchApi();
    }

    return () => {
      unMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, finalDeps);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    if (options?.autoRefresh) {
      interval = setInterval(() => {
        if (options.autoRefresh?.keepRefreshing) {
          setFetchMarker((x) => x + 1);
        }
      }, options.autoRefresh.interval);
    }
    return () => {
      clearInterval(interval);
    };
  });

  return { data, loading, error, fetch };
};

export interface ListContinuation<T> {
  items: T[];
  continuation_token?: string;
  item_count?: number;
}

export interface UseContinuationApiOptions {
  manual?: boolean;
}

export interface UseContinuationApiHooksParams<T> {
  /** The cumulated list of items, as returned by all cumulated calls to the api using the same deps. */
  data: T[] | undefined;
  /** The total number of items in all cumulated lists of items. */
  totalCount: number;
  /** An indicator whether the initial loading of the first page is currently happening or not. */
  loading: boolean;
  /** An indicator whether the subsequent, paginated calls are currently happening or not. */
  continuationLoading: boolean;
  /** The current (as in to be used to retrieve the next results) continuation token, if any. */
  continuationToken?: string;
  /** Any error that happened during the api call. */
  error?: any;
  /** Calling this might fetch the next page, if there are more results. */
  fetchNext: () => void;
  /** Use this to do a fetch on manual */
  fetchOnManual: () => void;
}

/**
 * Hooks that expose calling the api on results that return a continuation token.
 * This can manage fetching subsequent pages by using the fetchNext() method.
 * By default, it will make the call, and return flags in the form of UseContinuationApiHooksParams.
 * Changing deps values will reset the continuation token, back to an initial rendering.
 *
 * This may be combined with a ContinuationWaypoint component, to trigger the fetchNext when
 * the waypoint becomes visible, to implement infinite scrolling.
 */
export const useContinuationApi = <T,>(
  apiCall: (
    api: CommandsApi,
    continuationToken?: string
  ) => Promise<AxiosResponse<ListContinuation<T>>>,
  deps?: DependencyList,
  options?: UseContinuationApiOptions
): UseContinuationApiHooksParams<T> => {
  const [data, setData] = useState<Array<T> | undefined>(undefined);
  const [continuationToken, setContinuationToken] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [continuationLoading, setContinuationLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [initial, setInitial] = useState(true);
  const [fetchMarker, setFetchMarker] = useState(0);
  const [prevFetchMarker, setPrevFetchMarker] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const finalDeps = [fetchMarker];
  if (deps) {
    finalDeps.push(...(deps as any));
  }

  const fetchNext = () => {
    setFetchMarker((x) => x + 1);
  };

  const fetchOnManual = () => {
    setContinuationToken(undefined);
    setFetchMarker((x) => x + 1);
    setData([]);
    setInitial(true);
  };

  useEffect(() => {
    let unMounted = false;
    const fetchApi = async () => {
      let currentContinuationToken = continuationToken;
      let currentInitial = initial;
      if (prevFetchMarker === fetchMarker) {
        setData([]);
        setContinuationToken(undefined);
        currentContinuationToken = undefined;
        setLoading(false);
        setContinuationLoading(false);
        setInitial(true);
        currentInitial = true;
      } else {
        setPrevFetchMarker(fetchMarker);
      }

      if (currentInitial) {
        setLoading(true);
      } else {
        if (currentContinuationToken) {
          setContinuationLoading(true);
        }
      }
      try {
        setError(undefined);
        if (currentInitial || currentContinuationToken) {
          const response = await apiCall(api, currentContinuationToken);
          if (!unMounted) {
            setContinuationToken(response.data.continuation_token || undefined);
            setTotalCount(response.data.item_count || 0);
            setData((data) => {
              if (data !== undefined) {
                return [...data, ...response.data.items];
              } else {
                return response.data.items;
              }
            });
          }
        }
      } catch (err) {
        console.warn(err);
        if (!unMounted) {
          setError(err as undefined);
        }
      }

      if (!unMounted) {
        if (currentInitial) {
          setLoading(false);
          setInitial(false);
        } else {
          setContinuationLoading(false);
        }
      }
    };

    if (!options?.manual || fetchMarker > 0) {
      fetchApi();
    }

    return () => {
      unMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, finalDeps);

  return {
    data,
    totalCount,
    loading,
    continuationLoading,
    continuationToken,
    error,
    fetchNext,
    fetchOnManual,
  };
};

export const fetchAllApi = async <T,>(
  apiCall: (
    api: CommandsApi,
    continuationToken?: string
  ) => Promise<AxiosResponse<ListContinuation<T>>>
): Promise<any> => {
  let currentContinuationToken: string | undefined = undefined;
  let fetchAllCompleted = false;
  let data: any[] = [];

  while (!fetchAllCompleted) {
    const response: any = await apiCall(api, currentContinuationToken);
    currentContinuationToken = response.data.continuation_token || undefined;
    data = [...data, ...response.data.items];
    if (currentContinuationToken === undefined) {
      fetchAllCompleted = true;
    }
  }

  return data;
};

export const fetchApi = async (
  apiCall: (api: CommandsApi) => void
): Promise<any> => {
  apiCall(api);
};

export const fetchApiData = async (
  apiCall: (api: CommandsApi) => void
): Promise<any> => {
  return apiCall(api);
};

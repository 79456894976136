import { FormattedMessage } from "react-intl";

import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";

export interface UploadedLineError {
  product_code?: string;
  target?: string;
  error_details: string;
}

export type RestrictionsUploadErrorModalProps = {
  open: boolean;
  onClose: () => void;
  notification: any;
};

function RestrictionsUploadErrorModal(
  props: RestrictionsUploadErrorModalProps
) {
  const { open, onClose, notification } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="restrictions.upload.error_modal_title"
          defaultMessage="Upload CSV Error"
          description="Upload error modal title"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="error">
              <FormattedMessage
                id="restrictions.upload.error_modal"
                defaultMessage="Products below failed to be processed. Review the errors"
                description="Upload error modal details text"
              />
              :
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TableContainer sx={{ maxHeight: 250 }} component={Paper}>
              <Table
                stickyHeader
                size="small"
                aria-label="upload csv error list"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "neutral.main" }}>
                      Product Code
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "neutral.main" }}>
                      Target
                    </TableCell>
                    <TableCell sx={{ backgroundColor: "neutral.main" }}>
                      Error
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notification.details?.errors?.map(
                    (error: UploadedLineError) => (
                      <TableRow
                        key={
                          error.product_code! +
                          error.target! +
                          error.error_details
                        }
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {error.product_code}
                        </TableCell>
                        <TableCell>{error.target}</TableCell>
                        <TableCell>{error.error_details}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            id="restrictions.upload.error_modal_close_button"
            defaultMessage="Close"
            description="Upload error modal close button text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RestrictionsUploadErrorModal;

import { useApi } from "hooks/api";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Clear, Close } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";

export interface RestrictionsDownloadModalProps {
  open: boolean;
  onClose: () => void;
}

function RestrictionsDownloadWithFilePath(
  props: RestrictionsDownloadModalProps
) {
  const { onClose, open } = props;

  const intl = useIntl();

  const [presignedGetUrl, setPresignedGetUrl] = useState<string>("");
  const [isPresignedUrlPending, setIsPresignedUrlPending] =
    useState<boolean>(false);

  const [filePath, setFilePath] = useState<string>("");
  const clearFilePathChange = () => {
    setFilePath("");
    setPresignedGetUrl("");
  };

  const handleClose = () => {
    onClose();
  };

  const generatePresignedGetResponse = useApi(
    (api) =>
      api.generatePresignedGet({
        key_name: filePath,
      }),
    undefined,
    { manual: true }
  );

  const handleRequestDownloadButton = async () => {
    if (presignedGetUrl === "") {
      setIsPresignedUrlPending(true);
      generatePresignedGetResponse.fetch();
    } else {
      window.open(presignedGetUrl, "_blank");
      setPresignedGetUrl("");
    }
  };

  useEffect(() => {
    setPresignedGetUrl(
      generatePresignedGetResponse.data?.presigned_get_url || ""
    );
    setIsPresignedUrlPending(false);
  }, [generatePresignedGetResponse.data]);

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage
          id="restrictions.download.with_file_path_title"
          defaultMessage="Download File from S3 Path"
          description="Download with file path title"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container rowSpacing={2} textAlign="left">
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage
                id="restrictions.download.with_file_path"
                defaultMessage="Enter the File Path (Key Name in Bucket)"
                description="Download with file path content"
              />
            </Typography>
          </Grid>
          <Grid item xs={15}>
            <FormControl fullWidth>
              <TextField
                id="file-path"
                label="File Path"
                onChange={(event: any) => {
                  setPresignedGetUrl("");
                  setFilePath(event.target.value);
                }}
                value={filePath}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={clearFilePathChange}>
                      <Clear />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            id="restrictions.download.with_file_path_cancel"
            defaultMessage="Cancel"
            description="Download with file path title cancel button"
          />
        </Button>
        <LoadingButton
          loading={isPresignedUrlPending}
          variant="contained"
          disabled={filePath === ""}
          onClick={() => handleRequestDownloadButton()}
          component="label"
        >
          {presignedGetUrl === ""
            ? intl.formatMessage({
                id: "restrictions.download.request",
                defaultMessage: "Request file",
                description: "Download with file path request button",
              })
            : intl.formatMessage({
                id: "restrictions.download.download",
                defaultMessage: "Download file",
                description: "Download with file path download button",
              })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default RestrictionsDownloadWithFilePath;

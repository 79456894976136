import { GeneratePresignedPostResult } from "api/ms-promotion";
import { useAuthentication } from "context/authentication/UserAuthentication";
import { useApi } from "hooks/api";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { uploadFileToS3 } from "services/s3/uploadFileToS3";

import { Close, Delete, FileUpload } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from "@mui/material";

export interface RestrictionsUploadModalProps {
  open: boolean;
  onClose: () => void;
}

function RestrictionsUploadModal(props: RestrictionsUploadModalProps) {
  const { onClose, open } = props;

  const authentication = useAuthentication();

  const [presignedPostUrl, setPresignedPostUrl] = useState<string>("");
  const [isPresignedUrlPending, setIsPresignedUrlPending] =
    useState<boolean>(false);

  const [fileToUpload, setFileToUpload] = useState<any>();
  const [isUploadPending, setIsUploadPending] = useState<boolean>(false);
  const [showErrorOnFileExtension, setShowErrorOnFileExtension] =
    useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const getKeyName = () => {
    return (
      "promotion_restrictions/upload/" +
      Date.now() +
      "/" +
      (authentication.userInfo?.email || "anonymous@ssense.com") +
      "-" +
      fileToUpload?.name
    );
  };

  const generatePresignedPostResponse = useApi(
    (api) =>
      api.generatePresignedPost({
        key_name: getKeyName(),
      }),
    undefined,
    { manual: true }
  );

  interface EventAsTarget<T = EventTarget> {
    target: T;
  }

  const clearFileInput = () => {
    setFileToUpload(undefined);
    setPresignedPostUrl("");
    setShowErrorOnFileExtension(false);
  };

  const handleSelectFileButton = (event: EventAsTarget<HTMLInputElement>) => {
    clearFileInput();
    if (!event.target.files?.[0].name.endsWith(".csv")) {
      setShowErrorOnFileExtension(true);
      return;
    }
    setFileToUpload(event.target.files![0]);
    generatePresignedPostResponse.fetch();
    setIsPresignedUrlPending(true);
    event.target.value = "";
  };

  useEffect(() => {
    setPresignedPostUrl(
      generatePresignedPostResponse.data?.presigned_post_url || ""
    );
    setIsPresignedUrlPending(false);
  }, [generatePresignedPostResponse.data]);

  const handleSubmitButton = async () => {
    setIsUploadPending(true);
    const uploadStatus = await uploadFileToS3(
      generatePresignedPostResponse.data as GeneratePresignedPostResult,
      fileToUpload
    );
    setIsUploadPending(false);

    if (uploadStatus) {
      clearFileInput();
      handleClose();
      return;
    }
    toast.error(() => (
      <FormattedMessage
        id="notification.error.modal_upload"
        defaultMessage="File {file_name} was not uploaded."
        description="Upload CSV file modal error"
        values={{
          file_name: fileToUpload.name,
        }}
      />
    ));
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open}>
      <DialogTitle>
        <FormattedMessage
          id="restrictions.upload.modal_title"
          defaultMessage="Upload CSV file"
          description="Upload CSV file modal title"
        />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} textAlign="center">
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage
                id="restrictions.upload.modal_content"
                defaultMessage="Browse a file on your computer"
                description="Upload CSV file modal content"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label">
              <FormattedMessage
                id="restrictions.upload.modal_browse"
                defaultMessage="Browse file"
                description="Upload CSV file modal browse button"
              />
              <input
                onChange={handleSelectFileButton}
                hidden
                accept=".csv"
                type="file"
              />
            </Button>
          </Grid>
          {showErrorOnFileExtension && (
            <Grid item xs={12}>
              <Alert severity="error">
                Wrong type of file, <strong>only CSV files are allowed.</strong>
              </Alert>
            </Grid>
          )}
          {fileToUpload && (
            <>
              <Grid item xs={11}>
                <Chip
                  sx={{ marginTop: "2px" }}
                  icon={<FileUpload />}
                  label={fileToUpload.name}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={clearFileInput}
                  aria-label="delete file"
                  component="label"
                >
                  <Delete />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            id="restrictions.upload.modal_cancel"
            defaultMessage="Cancel"
            description="Upload CSV file modal cancel button"
          />
        </Button>
        <LoadingButton
          loading={isPresignedUrlPending}
          variant="contained"
          disabled={presignedPostUrl === "" || isUploadPending}
          onClick={() => handleSubmitButton()}
          component="label"
        >
          <FormattedMessage
            id="restrictions.upload.modal_submit"
            defaultMessage="Submit"
            description="Upload CSV file modal submit button"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default RestrictionsUploadModal;

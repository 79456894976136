import { Grid, Typography } from "@mui/material";

type PageLayoutProps = {
  surtitle: string;
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
};

function PageLayout(props: PageLayoutProps) {
  const { surtitle, title, icon, children } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ display: "inline-flex" }}>
          {icon} {surtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageLayout;

import AppThemeProvider from "components/theme/AppThemeProvider";
import AppIntlProvider from "components/translation/AppIntlProvider";
import ApiInfo from "context/api/ApiInfo";
import UserAuthentication from "context/authentication/UserAuthentication";
import UserPreferences from "context/preferences/UserPreferences";
import { Route, Routes as RouterRoutes } from "react-router-dom";
import HomePage from "views/Home/HomePage";
import RestrictionsPage from "views/Restrictions/RestrictionsPage/RestrictionsPage";
import AppLayout from "./components/layout/AppLayout";
import SaleEventsPage from "./views/SaleEvents/SaleEventsPage/SaleEventsPage";
import CreateSimulationPage from "views/Simulations/CreateSimulationPage/CreateSimulationPage";
import CreateSaleEventPage from "./views/SaleEvents/SaleEventsPage/CreateSaleEventPage";
import SimulationPage from "./views/Simulations/SimulationPage/SimulationPage";
import { NotificationProvider } from "./context/notification/Notification";
import Notification from "./components/notifications/Notification";

const isMarkdownUIEnabled = process.env.REACT_APP_IS_MARKDOWN_UI_ENABLED === 'true';

const Routes = () => (
  <RouterRoutes>
    <Route path="/" element={<AppLayout />}>
      <Route index element={<HomePage />} />
      <Route path="/promotion-restrictions" element={<RestrictionsPage />}>
        <Route path="upload" element={<RestrictionsPage />} />
        <Route
          path="upload-with-file-path"
          element={<RestrictionsPage />}
        ></Route>
        <Route
          path="download-with-file-path"
          element={<RestrictionsPage />}
        ></Route>
      </Route>
      {isMarkdownUIEnabled && (
        <Route path="/sale-events" element={<SaleEventsPage />} />
      )}
      {isMarkdownUIEnabled && (
        <Route path="/simulations" element={<SimulationPage />} />
      )}
      {isMarkdownUIEnabled && (
        <Route path="/sale-events/create" element={<CreateSaleEventPage />} />
      )}
      {isMarkdownUIEnabled && (
        <Route path="/simulations/create" element={<CreateSimulationPage />} />
      )}
    </Route>
  </RouterRoutes>
);

function App() {
  return (
    <NotificationProvider>
      <ApiInfo>
        <UserPreferences>
          <AppThemeProvider>
            <AppIntlProvider>
              {process.env.REACT_APP_DISABLE_AUTH === "true" ? (
                <Routes />
              ) : (
                <UserAuthentication>
                  <Routes />
                </UserAuthentication>
              )}
            </AppIntlProvider>
          </AppThemeProvider>
        </UserPreferences>
      </ApiInfo>
      <Notification />
    </NotificationProvider>
  );
}

export default App;

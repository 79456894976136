const baseUrl = process.env.REACT_APP_MARKDOWN_API_URL;

export const getSaleEvents = async (page: number, pageSize: number) => {
  try {
    const response = await fetch(`${baseUrl}/sale-events?page=${page}&per_page=${pageSize}`, { method: 'GET' })
    return await response.json();
  } catch (e) {
    console.error(e);

    return {
      page: 1,
      per_page: 10,
      page_count: 1,
      results: []
    }
  }
}

export const createSaleEvent = async (payload: any) => {
  try {
    const response = await fetch(`${baseUrl}/sale-events`, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
    return await response.json();
  } catch (e: any) {
    console.error(e);

    return {
      success: false,
      message: e.message,
    }
  }
}

export const getSimulations = async (page: number, pageSize: number) => {
  try {
    const response = await fetch(`${baseUrl}/simulations?page=${page}&per_page=${pageSize}`, { method: 'GET' })
    return await response.json();
  } catch (e) {
    console.error(e);

    return {
      page: 1,
      per_page: 10,
      page_count: 1,
      results: []
    }
  }
}

export const createSimulation = async (payload: any) => {
  try {
    const response = await fetch(`${baseUrl}/simulations`, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
    return await response.json();
  } catch (e: any) {
    console.error(e);

    return {
      success: false,
      message: e.message,
    }
  }
}

import { DrawerHeader } from "components/layout/navigation/AppNavigation";
import Notifications from "components/notifications/Notifications";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import AppBar from "./header/AppBar";
import AppNavigation from "./navigation/AppNavigation";

export const drawerWidth = 240;

function AppLayout() {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="header">
        <AppBar open={open} setOpen={setOpen} />
      </Box>
      <AppNavigation open={open} setOpen={setOpen} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, height: "calc(100vh - 32px)" }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
      <Notifications />
    </Box>
  );
}

export default AppLayout;
